import {
  autocompleteClasses,
  Components,
  CSSObject,
  filledInputClasses,
  formHelperTextClasses,
  iconButtonClasses,
  inputAdornmentClasses,
  inputLabelClasses,
} from "@mui/material";
import { bp } from "../breakpoints";
import { tokens } from "../tokens";
import { typography } from "../typography";

const searchFieldStyles: (leadingSpacing?: number) => CSSObject = (
  leadingSpacing = tokens.spacing[250]
) => ({
  [`.${autocompleteClasses.root} &, &`]: {
    ...typography.searchInputValue,
    "& ::placeholder": {
      ...typography.searchInputSecondaryValue,
    },
    [`.${filledInputClasses.root}`]: {
      backgroundColor: tokens.component.input.surfaceResting,
      borderRadius: "1000px",
      border: "1px solid transparent",
      overflow: "hidden",
      padding: 0,
      [`.${autocompleteClasses.root} &`]: {
        paddingRight: "40px",
      },
    },
    [`input.${filledInputClasses.input}, input.${autocompleteClasses.input}`]: {
      paddingTop: "25px",
      paddingLeft: leadingSpacing,
      "&:-webkit-autofill": {
        borderRadius: "0px",
      },
    },
    [`&:hover .${filledInputClasses.root}:not(.${filledInputClasses.disabled})`]:
      {
        backgroundColor: tokens.component.searchInput.surfaceHover,
      },
    [`&:focus-within .${filledInputClasses.root}:not(.${filledInputClasses.error}), & .${filledInputClasses.focused}.${filledInputClasses.root}:not(.${filledInputClasses.error})`]:
      {
        border: "1px solid transparent",
        margin: 0,
        backgroundColor: tokens.component.searchInput.surfaceFocused,
      },

    [`& .${filledInputClasses.error}`]: {
      [`&.${filledInputClasses.root}`]: {
        borderColor: tokens.component.input.borderError,
        backgroundColor: tokens.component.input.surfaceError,
      },
      [`&.${inputLabelClasses.root}, &.${formHelperTextClasses.root}`]: {
        color: tokens.error.main,
      },
      [`&.${inputLabelClasses.shrink}`]: {
        fontWeight: tokens.typography.fontWeight.bold,
      },
    },
    [`.${inputLabelClasses.root}`]: {
      transform: `translate(${leadingSpacing}px, 16px) scale(1)`,
    },
    [`.${inputLabelClasses.shrink}`]: {
      transform: `translate(${leadingSpacing}px, 9px) scale(.875)`,
    },
  },
});

export const MuiTextField: Components["MuiTextField"] = {
  defaultProps: {
    size: "medium",
    variant: "filled",
    InputProps: {
      disableUnderline: true,
    },
  },
  styleOverrides: {
    root: {
      ...typography.inputValue,
      [`& .${inputLabelClasses.root}`]: {
        ...typography.inputValue,
        color: tokens.text.secondary,
        overflow: "visible",

        [`&.${inputLabelClasses.focused}`]: {
          color: tokens.text.secondary,
        },
      },

      [`& .${filledInputClasses.root}`]: {
        backgroundColor: tokens.component.input.surfaceResting,
        borderRadius: tokens.cornerRadius.radiusLg,
        border: `1px solid ${tokens.component.input.borderResting}`,
        overflow: "hidden",
        minHeight: "58px",
        [`& .${autocompleteClasses.endAdornment}`]: {
          right: tokens.spacing[250],
          [`& .${iconButtonClasses.root}`]: {
            padding: 0,
          },
        },
      },

      [`& .${filledInputClasses.input}`]: {
        textOverflow: "ellipsis",
      },

      [`&:hover .${filledInputClasses.root}:not(.${filledInputClasses.disabled})`]:
        {
          backgroundColor: tokens.component.input.surfaceHover,
        },

      [`&:focus-within .${filledInputClasses.root}:not(.${filledInputClasses.error}), & .${filledInputClasses.focused}.${filledInputClasses.root}:not(.${filledInputClasses.error})`]:
        {
          borderColor: tokens.component.input.borderFocused,
          borderWidth: "2px",

          // Compensate for border width
          margin: "-1px",
          caretColor: tokens.primary.main,
        },

      [`& .${filledInputClasses.disabled}`]: {
        color: tokens.text.disabled,
        backgroundColor: tokens.component.input.surfaceDisabled,
      },

      [`& .${formHelperTextClasses.root}`]: {
        ...typography.inputHelper,
        color: tokens.text.secondary,
        margin: 0,
        paddingTop: "3px",
        paddingLeft: tokens.spacing[150],
        paddingRight: tokens.spacing[150],
      },

      [`& .${filledInputClasses.error}`]: {
        [`&.${filledInputClasses.root}`]: {
          borderColor: tokens.component.input.borderError,
          backgroundColor: tokens.component.input.surfaceError,
        },
        [`&.${inputLabelClasses.root}, &.${formHelperTextClasses.root}`]: {
          color: tokens.error.main,
        },
        [`&.${inputLabelClasses.shrink}`]: {
          fontWeight: tokens.typography.fontWeight.bold,
        },
      },

      [`.${inputAdornmentClasses.root}`]: {
        display: "none",
        paddingRight: tokens.spacing[250],
        [bp.up("tablet")]: {
          paddingRight: tokens.spacing[400],
        },
        [`& .${iconButtonClasses.root}`]: {
          padding: 0,
        },
      },
      [`&:focus-within .${filledInputClasses.input}:not(:placeholder-shown) + .${inputAdornmentClasses.root}, &:hover .${filledInputClasses.input}:not(:placeholder-shown) + .${inputAdornmentClasses.root}`]:
        {
          display: "flex",
        },
    },
  },
  variants: [
    {
      props: { size: "small" },
      style: {
        [`& .${inputLabelClasses.shrink}`]: {
          transform: `translate(12px, 5px) scale(.875)`,
        },

        [`& .${filledInputClasses.root}`]: {
          minHeight: "48px",
        },
      },
    },
    {
      props: { size: "medium" },
      style: {
        [`& .${inputLabelClasses.shrink}`]: {
          transform: `translate(12px, 9px) scale(.875)`,
        },
      },
    },
    {
      props: { size: "search" },
      style: {
        [bp.down("tablet")]: {
          [`& .${inputLabelClasses.shrink}`]: {
            transform: `translate(12px, 9px) scale(.875)`,
          },
        },
        [bp.up("tablet")]: {
          ...searchFieldStyles(),
        },
      },
    },
    {
      props: { size: "searchLeading" },
      style: {
        [bp.down("tablet")]: {
          [`& .${inputLabelClasses.shrink}`]: {
            transform: `translate(12px, 9px) scale(.875)`,
          },
        },
        [bp.up("tablet")]: {
          ...searchFieldStyles(tokens.spacing[300]),
        },
      },
    },
  ],
};
