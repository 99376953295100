import { Box } from "@mui/material";
import { MuiOtpInput, MuiOtpInputProps } from "mui-one-time-password-input";

export const OTPInput = (props: MuiOtpInputProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: need to update MUI
    <Box
      component={MuiOtpInput}
      {...props}
      sx={({ typography }) => ({
        "&.MuiOtpInput-Box": {
          gap: 100,
        },
        "& .MuiInputBase-input": {
          ...typography.titleMedium,
        },
      })}
    />
  );
};
