import { SessionInfo as B2BSessionInfo } from "@b2bportal/auth-api";
import {
  AuthActions,
  FINALIZE_USER_FAILED,
  FINALIZE_USER_SUCCESS,
  FINALIZED_REQUIRED,
  REMOVE_SESSION,
  UPDATE_SESSION,
} from "./actions";

export type UserInfoState = {
  sessionInfo?: SessionInfo;
  missingFields?: string[];
};
export const authReducer = (state: UserInfoState, action: AuthActions) => {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        sessionInfo: { ...state?.sessionInfo, ...action.sessionInfo },
      };
    }
    case FINALIZED_REQUIRED: {
      return { ...state, authStateToken: action.authStateToken };
    }
    case FINALIZE_USER_SUCCESS: {
      return { ...state, authStateToken: action.authStateToken };
    }
    case FINALIZE_USER_FAILED: {
      return { ...state, authStateToken: "" };
    }
    case REMOVE_SESSION: {
      return { ...state, sessionInfo: undefined };
    }
    default:
      return state;
  }
};

export interface SessionInfo extends B2BSessionInfo {
  // @deprecated
  hopperSessionToken?: string;
  refreshToken?: string;
  userInfoResponse?: UserInfoResponse;
  csrfToken?: string;
  hopperUserId?: string;
  hopperUserIdToken?: string;
  delegatedTo?: string;
}

export interface UserInfoResponse {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
}

export interface StartSessionResponse {
  hopperUserId: string;
  userInfoResponse: UserInfoResponse;
  hopperSessionToken: string;
  accessToken: string;
  refreshToken: string;
  hopperUserIdToken?: string;
}

export interface StartSessionRequest {
  code: string;
  initiator?: string;
}
