import { I18nNamespace, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { emailRegex, isAtHotelBrand, phoneRegex } from "@hopper-b2b/utilities";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "../../lib/PhoneInput";

export interface FinalizeFormProps {
  isEmailMissing: boolean;
  isFirstNameMissing: boolean;
  isLastNameMissing: boolean;
  isPhoneMissing: boolean;
  onContinue: ({
    email,
    firstName,
    lastName,
    phoneNumber,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => void;
}

export const FinalizeForm = ({
  isEmailMissing,
  isFirstNameMissing,
  isLastNameMissing,
  isPhoneMissing,
  onContinue,
}: FinalizeFormProps) => {
  const { t } = useI18nContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      lastName: "",
      firstName: "",
      phoneNumber: "",
    },
  });
  return (
    <Stack>
      <Typography variant="headlineMedium" px={300}>
        {isAtHotelBrand() ? t("newAtHotelUserTitle") : t("newUserTitle")}
      </Typography>
      <Stack p={300} gap={250}>
        <Stack direction="row" gap={100} alignItems="center">
          <Typography variant="titleMedium">{t("newUserSubtitle")}</Typography>
        </Stack>
        <Stack gap={100}>
          {isFirstNameMissing && (
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: t("contactInfoSection.form.firstName.missing"),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("firstName")}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
          {isLastNameMissing && (
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: t("contactInfoSection.form.lastName.missing"),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("lastName")}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
          {isEmailMissing && (
            <Controller
              name="email"
              control={control}
              rules={{
                required: t("contactInfoSection.form.emailAddress.missing"),
                pattern: {
                  value: emailRegex,
                  message: t("contactInfoSection.form.emailAddress.invalid"),
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("email")}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
          {isPhoneMissing && (
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: t("contactInfoSection.form.phoneNumber.missing"),
                pattern: {
                  value: phoneRegex,
                  message: t("contactInfoSection.form.phoneNumber.invalid"),
                },
              }}
              render={({
                field: { ref, onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <PhoneInput
                  label={t("phoneNumber")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorHelper={error?.message}
                  inputRef={ref}
                  error={!!error}
                />
              )}
            />
          )}
        </Stack>
        <Button onClick={handleSubmit(onContinue)}>{t("createAccount")}</Button>
        <Typography variant="inputHelper">
          <Trans
            i18nKey="createAccountAgreeToTermsAndPolicy"
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={t(`${[I18nNamespace.brand]}:urls.termsAndConditions`)}
              />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href={t(`${[I18nNamespace.brand]}:urls.privacyPolicy`)} />,
            ]}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};
