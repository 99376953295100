import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { trackEvent, getDeviceId } from "@hopper-b2b/api";
import { useExperiment, useExperiments } from "@hopper-b2b/experiments";
import {
  getCurrency,
  getReactAppVersion,
  useAuthProvider,
} from "@hopper-b2b/utilities";
import { IApiConfig, TrackingPropertiesType } from "@hopper-b2b/types";
import {
  useIsServerSideRendering,
  useUserSource,
  defaultCurrency as maybeDefaultCurrency,
  isAtHotelBrand,
  getUserDeviceData,
  getDeviceData,
  USER_SOURCE_KEY,
  getUtmParameters,
  getMobileAppVersion,
  getMobileAppBuildNumber,
  getMobileAppOs,
  getMobileAppPlatform,
  isHopperAppWebView,
  currentBrand as getCurrentBrand,
  getBrandAttribution,
  internalGetLang,
} from "@hopper-b2b/utilities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserState } from "@b2bportal/auth-api";

export type UseTrackEventProps = Omit<TrackEventRequest, "properties"> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
};

const TENANT = "hopper-app";

export const useTrackEvent = () => {
  const locale = internalGetLang();
  const userSource = useUserSource();
  const userInfoContext = useAuthProvider();
  const isServerSideRendering = useIsServerSideRendering();
  const { initialized, trackingProperties: experimentsTrackingProperties } =
    useExperiments();

  const [userDeviceData, setUserDeviceData] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const version = useMemo(getReactAppVersion, []);

  useEffect(() => {
    setUserDeviceData(getUserDeviceData());
    setReferrer(document.referrer);
  }, []);

  const shouldDefaultCurrency =
    useExperiment("WebHotelPreferredCurrencyDefaulting") && isAtHotelBrand();
  const defaultCurrency = maybeDefaultCurrency(
    shouldDefaultCurrency,
    userInfoContext?.state?.sessionInfo?.userInfo?.defaultCurrency
  );
  const currentBrand = getCurrentBrand();
  const currency = useMemo(
    () => getCurrency(defaultCurrency) || "USD",
    [defaultCurrency]
  );

  const deviceProperties = useMemo(
    () => ({
      ...userDeviceData,
      device_type: getDeviceData().type,
    }),
    [userDeviceData]
  );

  const pageProperties = useMemo(
    () => ({
      referrer_url: referrer,
      referrer_url_host: referrer ? new URL(referrer).hostname : "",
      page_referrer: referrer,
      page_url: isServerSideRendering ? null : window?.location.pathname ?? "",
    }),
    [referrer, isServerSideRendering]
  );

  const userSourceProperties = useMemo(
    () =>
      userSource
        ? { [USER_SOURCE_KEY]: userSource, utm_source: userSource }
        : {},
    [userSource]
  );

  const utmProperties = () => {
    const utmParameters = getUtmParameters();
    return {
      utm_source: utmParameters.utmSource,
      utm_medium: utmParameters.utmMedium,
      utm_campaign: utmParameters.utmCampaign,
    };
  };

  const mobileWebAppTrackingProperties: () => TrackingPropertiesType = () => {
    return isHopperAppWebView
      ? {
          $app_version: getMobileAppVersion(),
          $app_version_string: getMobileAppVersion(),
          $app_build_number: getMobileAppBuildNumber(),
          deviceID: getDeviceId(),
          $os: getMobileAppOs(),
          platform: getMobileAppPlatform(),
        }
      : {};
  };
  return useCallback(
    async (
      event: UseTrackEventProps,
      apiConfig?: IApiConfig,
      keepAlive?: boolean
    ) =>
      initialized &&
      trackEvent(
        {
          ...event,
          properties: {
            portal_brand: currentBrand,
            portal_brand_attribution: getBrandAttribution(),
            is_agent_session:
              !!userInfoContext?.state?.sessionInfo?.delegatedTo,
            delegated_to: userInfoContext?.state?.sessionInfo?.delegatedTo,
            guest_user:
              userInfoContext?.state?.sessionInfo?.userInfo?.userState ===
              UserState.Guest,
            signed_in:
              userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn,
            currency,
            tenant: TENANT,
            locale,
            preferred_languages: (navigator?.languages ?? []).join(","),
            app_version: version,
            $app_release: version,
            ...deviceProperties,
            ...pageProperties,
            ...userSourceProperties,
            ...utmProperties(),
            ...mobileWebAppTrackingProperties(),
            ...experimentsTrackingProperties,
            ...(event.properties ?? {}),
          },
        },
        apiConfig,
        keepAlive
      ),
    [
      experimentsTrackingProperties,
      initialized,
      currency,
      currentBrand,
      deviceProperties,
      locale,
      pageProperties,
      userSourceProperties,
      version,
      userInfoContext?.state?.sessionInfo,
    ]
  );
};
