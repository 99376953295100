import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiIconButton: Components["MuiIconButton"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: `${tokens.spacing[100]}px`,
      color: tokens.text.primary,
      "&:hover": {
        background: tokens.component.control.surfaceHover,
      },
      "&:active": {
        background: tokens.component.control.surfacePressed,
      },
      "&:focus-visible": {
        boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
      },
      "&:disabled": {
        color: tokens.text.disabled,
      },
    },
  },
  variants: [
    {
      props: { color: "onPrimary" },
      style: {
        color: tokens.primary.contrast,
        "&:hover": {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryHover,
        },
        "&:active": {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryPressed,
        },
        "&:focus-visible": {
          color: tokens.primary.contrast,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { color: "onPhoto" },
      style: {
        color: tokens.primary.contrast,
        "&:hover": {
          color: tokens.primary.contrast,
          background: tokens.surface.stateOverlay.hover,
        },
        "&:active": {
          color: tokens.primary.contrast,
          background: tokens.surface.stateOverlay.pressed,
        },
        "&:focus-visible": {
          color: tokens.primary.contrast,
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    {
      props: { surface: "raised" },
      style: {
        color: tokens.text.primary,
        backgroundColor: tokens.component.raisedInput.surfaceResting,
        "&:hover": {
          backgroundColor: tokens.component.raisedInput.surfaceHover,
        },
        "&:active": {
          backgroundColor: tokens.component.raisedInput.surfacePressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
  ],
};

export const MuiIconButtonOnPrimary: Components["MuiIconButton"] = {
  ...MuiIconButton,
  defaultProps: {
    ...MuiIconButton.defaultProps,
    color: "onPrimary",
  },
};

export const MuiIconButtonOnPhoto: Components["MuiIconButton"] = {
  ...MuiIconButton,
  defaultProps: {
    ...MuiIconButton.defaultProps,
    color: "onPhoto",
  },
};
