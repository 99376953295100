import { combineReducers } from "@reduxjs/toolkit";
import { SupportChatState, supportChatReducer } from "./supportChatReducer";

export interface SelfServeState {
  selfServe: {
    supportChat: SupportChatState;
  };
}

export const selfServeRootReducer = combineReducers({
  supportChat: supportChatReducer,
});
