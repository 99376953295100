import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiSelect: Components["MuiSelect"] = {
  styleOverrides: {
    icon: {
      width: "20px",
      height: "20px",
      right: tokens.spacing[250],
    },
  },
};
