"use client";

import { trackEvent } from "@hopper-b2b/api";
import { getParentState } from "@hopper-b2b/checkout";
import { HopperThemingProvider } from "@hopper-b2b/hopper-theming";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AuthModal,
  LandingForm as LandingFormComponent,
  LoadingContent,
  useImageSrc,
} from "@hopper-b2b/ui-core";
import { phoneRegex } from "@hopper-b2b/utilities";
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { interpret } from "xstate";
import AuthLoginBunny from "../../assets/icons/auth-login-bunny.png";
import {
  AuthEvents,
  AuthMachine,
  AuthMachineEventType,
  authMachine,
  useAuthState,
} from "../../machine";
import { getIsLoading } from "../../machine/selectors";
import {
  AuthSource,
  AuthTrackingEvents,
  AuthType,
  ChoseSignupEvent,
  CompletedPhoneDetailsEvent,
  ViewedSignupEvent,
} from "../../types/tracking";
import {
  AuthErrorContent,
  FinalizeForm,
  GoogleLogin,
  MergeAccountModal,
  SuccessModal,
  VerifyEmailModal,
  VerifyPhoneModal,
} from "./components";
import "./styles.scss";

export type AuthPromptProps = {
  open: boolean;
  onClose: () => void;
  loginContextHeader?: string;
  googleIdentityClientId?: string;
  isPhoneAuthEnabled?: boolean;
  isGoogleSsoAuthEnabled?: boolean;
};

export const AuthPrompt = (props: AuthPromptProps) => {
  const service = useRef(undefined);

  useEffect(() => {
    if (!service.current) {
      service.current = interpret(authMachine, {
        devTools: import.meta.env.DEV,
      }).start();
    }

    return () => {
      service.current?.stop();
      service.current = undefined;
    };
  }, []);

  return (
    service.current && (
      <AuthMachine.Provider value={service.current}>
        <AuthMachineMappedComponents {...props} />
      </AuthMachine.Provider>
    )
  );
};

const AuthMachineMappedComponents = ({
  open,
  onClose,
  ...rest
}: AuthPromptProps) => {
  const { t } = useI18nContext();
  const [state] = useAuthState();
  const parentState = getParentState(state.value);

  const loading = getIsLoading(state.value);

  const ModalContent = useMemo(() => {
    if (loading) {
      return <LoadingContent message={t("loading")} />;
    }

    switch (parentState) {
      case "landing":
        return <LandingForm {...rest} />;
      case "googleLogin":
        // Handled in loading state
        return null;
      case "phoneLogin":
        return <VerifyPhoneModal />;
      case "emailLogin":
        return <VerifyEmailModal />;
      case "finalize":
        return <FinalizeForm />;
      case "success":
        return <SuccessModal onClose={onClose} />;
      case "error":
        return <AuthErrorContent onClose={onClose} />;
      case "merge":
        return <MergeAccountModal />;
      default:
        onClose?.();
        return null;
    }
  }, [loading, onClose, parentState, rest, t]);

  const disableOnClose = loading || parentState === "success";

  return (
    <HopperThemingProvider enableHDSTheme>
      <AuthModal open={open} onClose={disableOnClose ? undefined : onClose}>
        {ModalContent}
      </AuthModal>
    </HopperThemingProvider>
  );
};

export const LandingForm = ({
  loginContextHeader,
  googleIdentityClientId,
  isPhoneAuthEnabled,
  isGoogleSsoAuthEnabled,
}: Pick<
  AuthPromptProps,
  | "googleIdentityClientId"
  | "isPhoneAuthEnabled"
  | "isGoogleSsoAuthEnabled"
  | "loginContextHeader"
>) => {
  const [, send] = useAuthState<AuthEvents, unknown>();
  const { pathname } = window.location;

  const onContinue = useCallback(
    ({ phoneNumber }) => {
      send({
        type: AuthMachineEventType.SET_PHONE_NUMBER,
        phoneNumber: `${phoneNumber}`,
      });
      trackEvent({
        eventName: AuthTrackingEvents.CompletedPhoneDetails,
        properties: {
          auth_type: AuthType.Phone,
        },
      } as CompletedPhoneDetailsEvent);

      send(AuthMachineEventType.SEND_CODE);

      trackEvent({
        eventName: AuthTrackingEvents.ChoseSignup,
        properties: {
          auth_type: AuthType.Phone,
        },
      } as ChoseSignupEvent);
    },
    [send]
  );

  useEffect(() => {
    let source;
    if (pathname.includes("flights")) {
      source = AuthSource.Flights;
    } else if (pathname.includes("hotels")) {
      source = AuthSource.Hotels;
    }

    trackEvent({
      eventName: AuthTrackingEvents.ViewedSignup,
      ...(source && {
        properties: {
          source,
        },
      }),
    } as ViewedSignupEvent);
  }, [pathname]);

  const showGoogleSso = useMemo(
    () => isGoogleSsoAuthEnabled && googleIdentityClientId,
    [isGoogleSsoAuthEnabled, googleIdentityClientId]
  );

  return (
    <LandingFormComponent
      loginContextHeader={loginContextHeader}
      onPhoneClick={onContinue}
      GoogleButton={
        showGoogleSso && (
          <GoogleLogin googleIdentityClientId={googleIdentityClientId} />
        )
      }
    />
  );
};
