import { UserState } from "@b2bportal/auth-api";
import {
  ISessionContextProps,
  SessionStateEnum,
  parsePhoneNumber,
  useAuthProvider,
} from "@hopper-b2b/utilities";
import { useMemo } from "react";

export const useAuthSessionContext = (): ISessionContextProps => {
  const { state } = useAuthProvider();

  return useMemo(() => {
    switch (state?.sessionInfo?.userInfo?.userState) {
      case UserState.Verified:
      case UserState.Guest: {
        const isGuest =
          state?.sessionInfo?.userInfo?.userState !== UserState.Verified;
        const phone = parsePhoneNumber(state?.sessionInfo?.userInfo?.phone);
        return {
          sessionType: isGuest
            ? SessionStateEnum.Guest
            : SessionStateEnum.Authenticated,
          isLoggedIn: !isGuest,
          email: state?.sessionInfo?.userInfo?.email,
          firstName: state?.sessionInfo?.userInfo?.firstName,
          lastName: state?.sessionInfo?.userInfo?.lastName,
          phoneNumber: phone.nationalNumber,
          countryCode: phone.countryCallingCode
            ? `+${phone.countryCallingCode}`
            : undefined,
        };
      }
      case UserState.Anonymous:
        return {
          sessionType: SessionStateEnum.Anonymous,
          isLoggedIn: false,
        };
      default:
        return null;
    }
  }, [
    state?.sessionInfo?.userInfo?.email,
    state?.sessionInfo?.userInfo?.firstName,
    state?.sessionInfo?.userInfo?.lastName,
    state?.sessionInfo?.userInfo?.phone,
    state?.sessionInfo?.userInfo?.userState,
  ]);
};
