import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiAccordionSummary: Components["MuiAccordionSummary"] = {
  styleOverrides: {
    root: {
      minHeight: 0,
      padding: `${tokens.spacing[250]}px ${tokens.spacing[200]}px`,
      "&.Mui-expanded": {
        minHeight: 0,
      },
    },
    content: {
      margin: 0,
      "&.Mui-expanded": {
        margin: 0,
      },
    },
  },
};
