import { Box, Stack, Typography } from "@mui/material";
import { RunningBunny } from "../../assets";
import { useImageSrc } from "../../lib/Image";

export interface LoadingContentProps {
  message: string;
}

export const LoadingContent = ({ message }: LoadingContentProps) => {
  const bunnySrc = useImageSrc(RunningBunny);
  return (
    <Stack p={300} gap={250} alignItems="center">
      <Box component="img" src={bunnySrc} width="240px" />
      <Stack direction="row">
        <Typography variant="titleMedium">{message}</Typography>
        {Array.from({ length: 3 }).map((_, index) => (
          <Typography
            component="span"
            key={index}
            variant="titleMedium"
            sx={({ transitions }) => ({
              "@keyframes ellipsis": {
                "0%": {
                  opacity: 0.2,
                },
                "20%": {
                  opacity: 1,
                },
                "100%": {
                  opacity: 0.2,
                },
              },
              animation: `ellipsis 1.4s infinite both`,
              transition: `opacity ${transitions.duration.short}ms ${transitions.easing.easeInOut}`,
              "&:nth-child(3)": {
                animationDelay: "0.4s",
              },
              "&:nth-child(4)": {
                animationDelay: "0.6s",
              },
            })}
          >
            .
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};
