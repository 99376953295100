import { Components } from "@mui/material";
import { typography } from "../typography";
import { tokens } from "../tokens";

export const MuiFormLabel: Components["MuiFormLabel"] = {
  styleOverrides: {
    root: {
      ...typography.titleMedium,
      color: tokens.text.primary,
    },
  },
};
