import { useExperiment } from "@hopper-b2b/experiments";
import { Currency } from "@hopper-b2b/types";
import {
  defaultCurrency,
  isAtHotelBrand,
  UserInfoContext,
} from "@hopper-b2b/utilities";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export type UserSettingsContext = {
  preferredCurrency: Currency;
};

export const UserSettingsContext = createContext<UserSettingsContext>({
  preferredCurrency: Currency.USD,
});

type UserSettingsProviderProps = {
  userInfoContext: UserInfoContext;
};

export const UserSettingsProvider: FC<
  UserSettingsProviderProps & PropsWithChildren
> = ({ userInfoContext, children }) => {
  const userCurrency =
    userInfoContext?.state?.sessionInfo?.userInfo?.defaultCurrency;
  const [userSettings, setUserSettings] = useState<UserSettingsContext>({
    preferredCurrency: (userCurrency as Currency) ?? Currency.USD,
  });

  const shouldDefaultCurrency =
    useExperiment("WebHotelPreferredCurrencyDefaulting") && isAtHotelBrand();

  useEffect(() => {
    const currency = defaultCurrency(shouldDefaultCurrency, userCurrency);

    setUserSettings({ preferredCurrency: currency });
  }, [userCurrency, shouldDefaultCurrency]);

  return (
    <UserSettingsContext.Provider value={userSettings}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettingsContext = () => {
  const ctx = useContext(UserSettingsContext);
  if (ctx === undefined)
    throw new Error(`must be used within a UserSettingsProvider`);

  return { ...ctx };
};
