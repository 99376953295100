import { useEffect, useState } from "react";
import { getRefreshToken, refreshSession, RefreshSessionResult } from "../api";

// Refresh token will be LOADING initially, since SSR can not read from
// local storage.  MISSING represents no value in local storage, and VALID
// represents a non-empty refresh token.
//
// We don't expose the actual refreshToken value, since the actual value
// isn't important for react dependencies and can complicate things.
export type InitialTokenState = "LOADING" | "MISSING" | "VALID";

export const useInitialRefreshTokenState = (): InitialTokenState => {
  const [initialTokenState, setInitialTokenState] =
    useState<InitialTokenState>("LOADING");

  // Read the value from localStorage in an effect, as this causes the value to be read
  // on the client when in SSR mode. Having this "LOADING" state also fixes an
  // issue in StrictMode development where components are mounted twice.
  useEffect(() => {
    const token = getRefreshToken();
    setInitialTokenState(token ? "VALID" : "MISSING");
  }, []);

  return initialTokenState;
};

// Update the refreshToken, but ensure only 1 request is made at a time.
export const updateRefreshToken = (() => {
  let ongoingRefreshTokenPromise = null;
  return (): Promise<RefreshSessionResult> => {
    if (ongoingRefreshTokenPromise === null) {
      ongoingRefreshTokenPromise = refreshSession({
        refreshToken: getRefreshToken(),
      }).then((r) => {
        ongoingRefreshTokenPromise = null;
        return r;
      });
    }
    return ongoingRefreshTokenPromise;
  };
})();
