import {
  internalGetLang,
  internalGetLangFromStorage,
  internalSetLang,
  internalUpdateLang,
} from "@hopper-b2b/utilities";

export const getLangFromStorage = (useNavigator = true) =>
  internalGetLangFromStorage(useNavigator);

export const getLang = (lang?: string, useNavigator = true) =>
  internalGetLang(lang, useNavigator);

export const setLang = (lang: string) => internalSetLang(lang);

export const updateLang = () => internalUpdateLang();
