import { FlightsFareSlice, MultiTicketType } from "@b2bportal/air-shopping-api";
import {
  FareDetails,
  FlightShopCardType,
  TripDetails,
  TrackingEventEntryPoint,
  AirportMap,
} from "@hopper-b2b/types";

import { Box } from "@mui/material";
import clsx from "clsx";
import { TFunction } from "i18next";

import { FareDetailsCard } from "../FareDetailsCard";
import { FlightDetailsSummary } from "../FlightDetailsSummary";
import { MiniFareDetailsCard } from "../MiniFareDetailsCard";
import "./styles.scss";
import { FlightVirtualInterlineDetailsSummary } from "../FlightVirtualInterlineDetailsSummary";
import { getPlusDaysOnSliceWithDates } from "@hopper-b2b/utilities";
import { VirtualInterlineFareDetailsCard } from "../VirtualInterlineFareDetailsCard";

/**
 * Flight Details Card
 * @function FlightDetailsCard
 * @param classes
 *
 */
export interface IFlightDetailsCardProps {
  className?: string;
  inExchange?: boolean;
  tripDetails: TripDetails;
  selectedFareId?: string;
  isOutgoing: boolean;
  onClick?: () => void;
  onFareClick?: (fareId: string) => void;
  onAlgomerchClick?: (label: string) => void;
  // TODO: removed for storybook
  // fareNotice?: IFareNotice[];
  fareNotice?: any;
  isMobile?: boolean;
  rewardsKey?: string;
  plusDays?: number;
  getEmptyRestrictionsText: (
    fareRating: number | undefined,
    translate: TFunction
  ) => string;
  header?: JSX.Element;
  isMixedCabinClass?: boolean;
  selectedFareClassFilters: Array<number>;
  cardType?: FlightShopCardType;
  selectedOutgoingFareSlice?: FlightsFareSlice;
  setMobileSelectedFareId?: (fareId: string) => void;
  setExpandedFlight: (expandedFlight: string) => void;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
  airports?: AirportMap;
}

export const FlightDetailsCard = ({
  className,
  inExchange,
  onClick,
  tripDetails,
  selectedFareId,
  isOutgoing,
  onFareClick,
  onAlgomerchClick,
  isMobile,
  fareNotice,
  rewardsKey,
  plusDays,
  getEmptyRestrictionsText = () => "",
  header,
  isMixedCabinClass,
  selectedFareClassFilters,
  cardType,
  selectedOutgoingFareSlice,
  setMobileSelectedFareId,
  getFlightShopFareSlice,
  airports,
}: IFlightDetailsCardProps) => {
  const fare = tripDetails.fareDetails.find(
    (fare) => fare.id === selectedFareId
  );
  const selectedFare = fare ? fare : tripDetails.fareDetails[0];

  const onFareDetailsClicked = (fare: FareDetails) => {
    if (onFareClick) {
      onFareClick(fare.id);
    }
  };

  const selectedFareSlice =
    selectedFare.slices[
      selectedFare.slices.findIndex((slice) =>
        isOutgoing ? slice.outgoing : !slice.outgoing
      )
    ];
  const isVirtualInterlineItinerary =
    selectedFare.multiTicketType === MultiTicketType.virtualInterline;
  const details = tripDetails.slices[isOutgoing ? 0 : 1];

  return (
    <Box
      className={clsx("flight-details-card", className, {
        mini: cardType === FlightShopCardType.MINI,
        desktop: !isMobile,
        mobile: isMobile,
      })}
      onClick={onClick}
    >
      <Box className="flight-details">
        {isVirtualInterlineItinerary ? (
          <FlightVirtualInterlineDetailsSummary
            className="virtual-interline-flight-summary"
            isOutgoing={isOutgoing}
            entryPoint={
              isOutgoing
                ? TrackingEventEntryPoint.Outbound
                : TrackingEventEntryPoint.Return
            }
            segments={details.segmentDetails}
            departureTime={details.departureTime}
            fareSlice={tripDetails.fareDetails[0].slices[isOutgoing ? 0 : 1]}
            plusDays={getPlusDaysOnSliceWithDates(
              details.departureTime,
              details.arrivalTime
            )}
          />
        ) : (
          <FlightDetailsSummary
            id="flight-details-summary"
            className="flight-summary"
            entryPoint={
              isOutgoing
                ? TrackingEventEntryPoint.Outbound
                : TrackingEventEntryPoint.Return
            }
            isOutgoing={isOutgoing}
            showTitle={false}
            header={header}
            totalDurationMinutes={
              tripDetails.slices[isOutgoing ? 0 : 1].totalDurationMinutes
            }
            departureTime={
              isOutgoing
                ? tripDetails.slices[0].departureTime
                : tripDetails.slices[tripDetails.slices.length - 1]
                    .departureTime
            }
            segments={
              isOutgoing
                ? tripDetails.slices[0].segmentDetails
                : tripDetails.slices[tripDetails.slices.length - 1]
                    .segmentDetails
            }
            fareClass={selectedFareSlice.fareShelf?.shortBrandName || ""}
            planeInfo={
              selectedFareSlice.amenitiesUtas?.amenities?.aircraft.info
                .displayText || ""
            }
            plusDays={plusDays}
            fareSlice={selectedFareSlice}
            isMixedCabinClass={isMixedCabinClass}
            renderAirlineIconSection={false}
          />
        )}
      </Box>
      <Box m={!isMobile && 3} className="fare-details-container">
        <Box className="fare-details">
          {cardType === FlightShopCardType.MINI && isMobile ? (
            <MiniFareDetailsCard
              className={clsx("flight-fare-details", className)}
              fareDetails={tripDetails.fareDetails}
              inExchange={inExchange}
              selectedFare={selectedFare.id}
              isOutgoing={isOutgoing}
              fareNotice={fareNotice}
              onAlgomerchClick={onAlgomerchClick}
              isMobile={isMobile}
              rewardsKey={rewardsKey}
              getEmptyRestrictionsText={getEmptyRestrictionsText}
              selectedFareClassFilters={selectedFareClassFilters}
              selectedOutgoingFareSlice={selectedOutgoingFareSlice}
              onFareSelect={onFareDetailsClicked}
              setClickedFareId={setMobileSelectedFareId}
              getFlightShopFareSlice={getFlightShopFareSlice}
              isVirtualInterlineItinerary={isVirtualInterlineItinerary}
              airports={airports}
              tripDetails={tripDetails}
            />
          ) : isVirtualInterlineItinerary ? (
            <VirtualInterlineFareDetailsCard
              tripDetails={tripDetails}
              isOutgoing={isOutgoing}
              onClick={(fare: any) => onFareDetailsClicked(fare)}
              getFlightShopFareSlice={getFlightShopFareSlice}
              onAlgomerchClick={onAlgomerchClick}
              airports={airports}
            />
          ) : (
            <FareDetailsCard
              className={clsx("flight-fare-details", className)}
              fareDetails={tripDetails.fareDetails}
              onClick={(fare: any) => onFareDetailsClicked(fare)}
              selectedFare={selectedFare.id}
              isOutgoing={isOutgoing}
              fareNotice={fareNotice}
              onAlgomerchClick={onAlgomerchClick}
              isMobile={isMobile}
              rewardsKey={rewardsKey}
              getEmptyRestrictionsText={getEmptyRestrictionsText}
              selectedFareClassFilters={selectedFareClassFilters}
              selectedOutgoingFareSlice={selectedOutgoingFareSlice}
              getFlightShopFareSlice={getFlightShopFareSlice}
              tripDetails={tripDetails}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FlightDetailsCard;
