import { Components } from "@mui/material";

export const MuiTypography: Components["MuiTypography"] = {
  defaultProps: {
    variant: "bodyMedium",
    variantMapping: {
      displayLarge: "h1",
      displayMedium: "h2",
      displaySmall: "h3",
      headlineLarge: "h4",
      headlineMedium: "h5",
      headlineSmall: "h6",
      titleLarge: "p",
      titleMedium: "p",
      titleSmall: "p",
      bodyLarge: "p",
      bodyMedium: "p",
      bodySmall: "p",
      labelLarge: "p",
      labelMedium: "p",
      labelSmall: "p",
      labelSmallEmphasis: "p",
      labelExtraSmall: "p",
      buttonLarge: "p",
      buttonMedium: "p",
      buttonSmall: "p",
      inputValue: "p",
      inputHelper: "p",
      inputLabel: "p",
      inputLabelError: "p",
      priceLarge: "p",
      priceMedium: "p",
    },
  },
};
