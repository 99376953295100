import React from "react";
import { HopperAssets } from "@hopper-assets";
import CalendarBunny from "../../assets/bunnies/calendar_bunny.svg";
import CalendarSuccessBunny from "../../assets/bunnies/calendar_success_bunny.svg";
import SupportBunny from "../../assets/bunnies/support_bunny.svg";
import SupportChatBunny from "../../assets/bunnies/support_chat_bunny.svg";
import SupportSuccessBunny from "../../assets/bunnies/support_success_bunny.svg";
import PriceFreezeIcon from "../../assets/icons/amenities/pricefreeze.svg";
import EarlyCheckInCalendarIcon from "../../assets/icons/cap1/calendar-icon.svg";
import ErrorState from "../../assets/icons/common/warning-icon-orange.svg";
import LateCheckoutIcon from "../../assets/icons/cap1/late-checkout-icon.svg";
import LuxuryBenefitCashIcon from "../../assets/icons/cap1/luxury-benefit-cash.svg";
import LuxuryBenefitMilesIcon from "../../assets/icons/cap1/luxury-benefit-miles.svg";
import LuxuryBenefitServiceIcon from "../../assets/icons/cap1/luxury-benefit-service.svg";
import MealIcon from "../../assets/icons/cap1/meal-icon.svg";
import MoneyBagIcon from "../../assets/icons/cap1/money-bag-icon.svg";
import PerksIcon from "../../assets/icons/cap1/perks-icon.svg";
import PriceDropProtectionShield from "../../assets/icons/cap1/price-drop-protection-shield.svg";
import PriceDropProtectionIcon from "../../assets/icons/cap1/price-drop-protection.svg";
import ModalPriceDrop from "../../assets/icons/cap1/price-drop.svg";
import PriceFreezeArrow from "../../assets/icons/cap1/price-freeze-arrow.svg";
import B2bPriceFreezeNoCircle from "../../assets/icons/cap1/price-freeze-no-circle.svg";
import B2bPriceFreeze from "../../assets/icons/cap1/price-freeze.svg";
import PriceMatchGuaranteeIcon from "../../assets/icons/cap1/price-match-icon.svg";
import PricePredictionIcon from "../../assets/icons/cap1/price-prediction.svg";
import UnableToProcess from "../../assets/icons/cap1/unable-to-process.svg";
import UpgradeIcon from "../../assets/icons/cap1/upgrade-icon.svg";
import WifiIcon from "../../assets/icons/cap1/wifi-icon.svg";
import AirConditioningIcon from "../../assets/icons/car/amenities/air-conditioning-icon.svg";
import TransmissionIcon from "../../assets/icons/car/transmission-icon.svg";
import ErrorAlertIcon from "../../assets/icons/common/alert-error.svg";
import WarningAlertIcon from "../../assets/icons/common/alert-warning.svg";
import ArrowRight from "../../assets/icons/common/arrow-right.svg";
import ArrowDiverge from "../../assets/icons/common/arrow-diverge.svg";
import Arrows from "../../assets/icons/common/arrows.svg";
import B2BAirplaneIcon from "../../assets/icons/common/b2b-airplane-icon.svg";
import B2BEditPencilIcon from "../../assets/icons/common/b2b-edit-pencil.svg";
import B2BMapPin from "../../assets/icons/common/b2b-map-pin.svg";
import B2BOriginIcon from "../../assets/icons/common/b2b-origin-icon.svg";
import B2BPriceWatch from "../../assets/icons/common/b2b-price-watch.svg";
import B2BUser from "../../assets/icons/common/b2b-user.svg";
import BellWarningIcon from "../../assets/icons/common/bell-warning-icon.svg";
import CalendarIcon from "../../assets/icons/common/calendar.svg";
import CheckInCalendar from "../../assets/icons/common/check-in-calendar.svg";
import CheckOutCalendar from "../../assets/icons/common/check-out-calendar.svg";
import CheckCircleFilledBlueIcon from "../../assets/icons/common/check-circle-filled-blue.svg";
import CheckCircleIcon from "../../assets/icons/common/check-circle.svg";
import CheckCircleFilledIcon from "../../assets/icons/common/check-filled.svg";
import CheckCircleOutlineIcon from "../../assets/icons/common/check-circle-outline.svg";
import CheckCircleTransparentIcon from "../../assets/icons/common/check-mark-transparent.svg";
import CheckShieldBlueIcon from "../../assets/icons/common/check-shield-blue.svg";
import CheckShieldIcon from "../../assets/icons/common/check-shield.svg";
import CircleAlert from "../../assets/icons/common/circle-alert.svg";
import ChevronDown from "../../assets/icons/common/chevron-down.svg";
import ClockIcon from "../../assets/icons/common/clock.svg";
import Close from "../../assets/icons/common/close-button.svg";
import Checkmark from "../../assets/icons/common/confirmation-check-mark.svg";
import DecreaseIcon from "../../assets/icons/common/decrease-arrow.svg";
import Dropdown from "../../assets/icons/common/dropdown.svg";
import ExternalLinkIcon from "../../assets/icons/common/external-link-icon.svg";
import FilledClock from "../../assets/icons/common/filled-clock.svg";
import FilterMenu from "../../assets/icons/common/filter-menu.svg";
import FilterMenuThick from "../../assets/icons/common/filter-menu-thick.svg";
import PoundCurrencySymbol from "../../assets/icons/common/gbp.svg";
import GenericIcon from "../../assets/icons/common/generic.svg";
import BellAlertIcon from "../../assets/icons/common/grey-bell-alert-icon.svg";
import CheckedIcon from "../../assets/icons/common/grey-checked-icon.svg";
import DottedLineIcon from "../../assets/icons/common/grey-dotted-line.svg";
import GuaranteeIcon from "../../assets/icons/common/grey-guarantee-icon.svg";
import PriceDropIcon from "../../assets/icons/common/grey-price-drop-icon.svg";
import IncreaseIcon from "../../assets/icons/common/increase-arrow.svg";
import InfoCircleFillIcon from "../../assets/icons/common/info-circle-fill.svg";
import InfoCircleIcon from "../../assets/icons/common/info-icon.svg";
import InfoCircleWhiteIcon from "../../assets/icons/common/info-white-icon.svg";
import MagnifyingGlassIcon from "../../assets/icons/common/magnifying-glass-icon.svg";
import MoneyFill from "../../assets/icons/common/money-fill.svg";
import MoneyOutlineIconGBP from "../../assets/icons/common/money-outline-gbp.svg";
import MoneyOutlineTransparentIcon from "../../assets/icons/common/money-outline-transparent.svg";
import MoneyOutlineIcon from "../../assets/icons/common/money-outline.svg";
import NotAllowedSignIcon from "../../assets/icons/common/not-allowed-sign.svg";
import PaymentIcon from "../../assets/icons/common/payment.svg";
import PencilEditIcon from "../../assets/icons/common/pencil-edit-icon.svg";
import PlusOutlineIcon from "../../assets/icons/common/plus-outline.svg";
import Refresh from "../../assets/icons/common/refresh.svg";
import Reload from "../../assets/icons/common/reload.svg";
import RewindIcon from "../../assets/icons/common/rewind-time.svg";
import ShowAllImagesIcon from "../../assets/icons/common/show-all-images.svg";
import StarEmpty from "../../assets/icons/common/star-empty.svg";
import StarFilled from "../../assets/icons/common/star-filled.svg";
import StarRoundedEmpty from "../../assets/icons/support/star-rounded-empty.svg";
import StarRoundedFilled from "../../assets/icons/support/star-rounded-filled.svg";
import SubtractIcon from "../../assets/icons/common/subtract.svg";
import SuiteCaseIcon from "../../assets/icons/common/suitecase-icon.svg";
import Delete from "../../assets/icons/common/trash-bin-icon.svg";
import UserIcon from "../../assets/icons/common/user.svg";
import B2BWatchCheckedIcon from "../../assets/icons/common/watch-b2b-checked.svg";
import B2BWatchIcon from "../../assets/icons/common/watch-b2b.svg";
import WatchIcon from "../../assets/icons/common/watch.svg";
import XCircleFilled from "../../assets/icons/common/x-circle-filled.svg";
import FrozenPriceIcon from "../../assets/icons/flight/frozen-price.svg";
import SeatSelectionIcon from "../../assets/icons/flight/seat-selection.svg";
import HopperLogo from "../../assets/icons/hopper/hopper-logo.svg";
import Bed from "../../assets/icons/hotel/bed.svg";
import MapPin from "../../assets/icons/hotel/map-pin.svg";
import MapPinRound from "../../assets/icons/hotel/map-pin-round.svg";
import CardIcon from "../../assets/icons/payments/card_generic.svg";
import DecreaseReviewed from "../../assets/icons/system/DecreaseReviewed.svg";
import IncreaseReviewed from "../../assets/icons/system/IncreaseReviewed.svg";
import LockIcon from "../../assets/icons/system/lock.svg";
import NavigationForwardIcon from "../../assets/icons/system/navigation-forward.svg";
import Chevron from "../../assets/icons/uber/chevron.svg";
import CheckBunmoji from "../../assets/icons/hopper/check_bunmoji.svg";
import SpeechBubbleBunmoji from "../../assets/icons/hopper/speech_bubble_bunmoji.svg";
import CarIcon from "../../assets/icons/car/car-icon.svg";
import BookTravel from "../../assets/icons/flight/book-travel.svg";
import WorldMap from "../../assets/icons/common/world-map.svg";
import CircleAirplane from "../../assets/icons/common/circle-airplane.svg";
import ComboFlight from "../../assets/icons/common/combo-flight.svg";
import CarryOnBaggage from "../../assets/icons/common/carry-on-baggage.svg";
import TripProtection from "../../assets/icons/common/trip-protection.svg";
import TripProtectionCrossSolid from "../../assets/icons/common/trip-protection-cross-solid.svg";
import ShieldPayment from "../../assets/icons/common/shield-payment.svg";
import WalletFlat from "../../assets/icons/hopper/wallet_flat.svg";
import CheckSolid from "../../assets/icons/system/check-solid.svg";
import DismissOutline from "../../assets/icons/system/dismiss-outline.svg";
import Check from "../../assets/icons/common/check.svg";
import InfoOutline from "../../assets/icons/common/info-outline.svg";
import InfoOutlineTriangle from "../../assets/icons/common/info-outline-triangle.svg";
import ClockGreyOutline from "../../assets/icons/common/clock-grey-outline.svg";
import PlaneLanding from "../../assets/icons/common/plane-landing.svg";
import PlaneTakeoff from "../../assets/icons/common/plane-takeoff.svg";
import Ellipse from "../../assets/icons/common/ellipse.svg";
import LayoverClock from "../../assets/icons/common/layover-clock.svg";
import BaggageCarryOn from "../../assets/icons/common/baggage-carry-on.svg";
import ShieldMissedConnection from "../../assets/icons/common/shield-missed-connection.svg";
import MultiPlanes from "../../assets/icons/common/multi-planes.svg";
import AccordionArrow from "../../assets/icons/common/accordion-arrow.svg";
import CrossOutSign from "../../assets/icons/common/cross-out-sign.svg";
import Tada from "../../assets/icons/common/tada.svg";
import RoundArrowDown from "../../assets/icons/system/round-arrow-down.svg";
import SendMessage from "../../assets/icons/support/send-message.svg";
import SupportChat from "../../assets/icons/support/support-chat.svg";
import SupportChevronRight from "../../assets/icons/support/support-chevron-right.svg";
import SupportPhone from "../../assets/icons/support/support-phone.svg";

export enum IconName {
  MoneyOutline = "money-outline",
  MoneyOutlineGBP = "money-outline-gbp",
  MoneyOutlineTransparentIcon = "money-outline-transparent",
  MoneyFill = "money-fill",
  SupportBunny = "support-bunny",
  SupportChatBunny = "support-chat-bunny",
  SupportSuccessBunny = "support-sucess-bunny",
  CheckBunmoji = "check-bunmoji",
  CheckCircle = "check-circle",
  Payment = "payment",
  CheckCircleOutline = "check-circle-outline",
  CheckCircleTransparent = "check-circle-transparent",
  NotAllowedSign = "not-allowed-sign",
  Person = "person",
  PriceFreeze = "price-freeze",
  NavigationForward = "navigation-forward",
  Lock = "lock",
  User = "user",
  Rewind = "rewind",
  Card = "card",
  Watch = "watch",
  Increase = "increase",
  Decrease = "decrease",
  MapPin = "map-pin",
  MapPinRound = "map-pin-round",
  B2BWatch = "b2b-watch",
  B2BWatchChecked = "b2b-watch-checked",
  IncreasedReviewed = "IncreaseReviewed",
  DecreaseReviewed = "DecreasedReviewed",
  PencilEdit = "pencil-edit",
  ErrorAlert = "error-alert",
  WarningAlert = "warning-alert",
  InfoCircle = "info-circle",
  InfoCircleFill = "info-circle-fill",
  InfoWhiteCircle = "info-white-circle",
  Calendar = "calendar-icon",
  CalendarBunny = "calendar-bunny",
  CalendarSuccessBunny = "calendar-success-bunny",
  CheckInCalendar = "check-in-calendar",
  CheckOutCalendar = "check-out-calendar",
  B2BEditPencil = "b2b-edit-pencil",
  Dropdown = "dropdown",
  B2BUser = "b2b-user",
  B2BMapPin = "b2b-map-pin",
  SuiteCase = "suite-case-icon",
  Transmission = "transmission-icon",
  AirConditioning = "air-conditioning-icon",
  ClockIcon = "clock-icon",
  CheckCircleFilled = "check-circle-filled",
  CheckShield = "check-shield",
  CheckShieldBlue = "check-shield-blue",
  Generic = "generic",
  MagnifyingGlass = "magnifying-glass",
  B2BAirplaneIcon = "b2b-airplane-icon",
  B2BOriginIcon = "b2b-origin-icon",
  ShowAllImagesIcon = "show-all-images-icon",
  B2BPriceWatch = "b2b-price-watch",
  ExternalLinkIcon = "external-link-icon",
  ErrorState = "error-state",
  UnableToProcess = "unable-to-process",
  B2bPriceFreeze = "b2b-price-freeze",
  B2bPriceFreezeNoCircle = "b2b-price-freeze-no-circle",
  PriceDropProtectionIcon = "price-drop-protection-icon",
  PriceDropProtectionShield = "price-drop-protection-shield",
  Guarantee = "grey-guarantee-icon",
  BellAlert = "grey-bell-alert-icon",
  PriceDrop = "grey-price-drop-icon",
  Checked = "grey-checked-icon",
  PricePredictionIcon = "price-prediction-icon",
  PriceMatchGuaranteeIcon = "price-match-icon",
  DottedLine = "grey-dotted-line",
  PriceFreezeArrow = "price-freeze-arrow",
  SeatSelectionIcon = "seat-selection-icon",
  FrozenPrice = "frozen-price-icon",
  BellWarning = "bell-warning-icon",
  MoneyBag = "money-bag-icon",
  LuxuryBenefitMilesIcon = "luxury-benefit-miles-icon",
  LuxuryBenefitCashIcon = "luxury-benefit-cash-icon",
  LuxuryBenefitServiceIcon = "luxury-benefit-service-icon",
  EarlyCheckInCalendarIcon = "early-checkin-calendar-icon",
  MealIcon = "meal-icon",
  UpgradeIcon = "upgrade-icon",
  LateCheckoutIcon = "late-checkout-icon",
  WifiIcon = "wifi-icon",
  PerksIcon = "perks-icon",
  CheckCircleFilledBlue = "check-circle-filled-blue",
  ModalPriceDrop = "price-drop",
  HopperLogo = "hopper-logo",
  FilterMenu = "filter-menu",
  FilterMenuThick = "filter-menu-thick",
  Checkmark = "checkmark",
  Delete = "delete",
  Close = "close",
  XCircleFilled = "x-circle-filled",
  PoundCurrencySymbol = "gbp",
  Subtract = "subtract",
  ArrowDiverge = "arrow-diverge",
  Arrows = "arrows",
  FilledClock = "filled-clock",
  CircleAlert = "circle-alert",
  Chevron = "chevron",
  Refresh = "refresh",
  Reload = "reload",
  ArrowRight = "arrow-right",
  StarEmpty = "star-empty",
  StarFilled = "star-filled",
  StarRoundedEmpty = "star-rounded-empty",
  StarRoundedFilled = "star-rounded-filled",
  Bed = "bed",
  SpeechBubbleBunmoji = "speech-bubble-bunmoji",
  Car = "car",
  BookTravel = "book-travel",
  WorldMap = "world-map",
  CircleAirplane = "circle-airplane",
  ChevronDown = "chevron-down",
  PlusOutline = "plus-outline",
  ComboFlight = "combo-flight",
  CarryOnBaggage = "carry-on-baggage",
  TripProtection = "trip-protection",
  TripProtectionCrossSolid = "trip-protection-cross-solid",
  ShieldPayment = "shield-payment",
  WalletFlat = "wallet-flat",
  CheckSolid = "check-solid",
  DismissOutline = "dismiss-outline",
  Check = "check",
  InfoOutline = "info-outline",
  InfoOutlineTriangle = "info-outline-triangle",
  ClockGreyOutline = "clock-grey-outline",
  PlaneTakeoff = "plane-takeoff",
  PlaneLanding = "plane-landing",
  Ellipse = "ellipse",
  LayoverClock = "layover-clock",
  BaggageCarryOn = "baggage-carry-on",
  ShieldMissedConnection = "shield-missed-connection",
  MultiPlanes = "multi-planes",
  AccordionArrow = "accordion-arrow",
  CrossOutSign = "cross-out-sign",
  Tada = "tada",
  RoundArrowDown = "round-arrow-down",
  SendMessage = "send-message",
  SupportChat = "support-chat",
  SupportChevronRight = "support-chevron-right",
  SupportPhone = "support-phone",
}

type IconMap = {
  [name in IconName]: string;
};

export const iconMap: IconMap = {
  [IconName.MoneyOutline]: MoneyOutlineIcon,
  [IconName.MoneyOutlineGBP]: MoneyOutlineIconGBP,
  [IconName.MoneyOutlineTransparentIcon]: MoneyOutlineTransparentIcon,
  [IconName.MoneyFill]: MoneyFill,
  [IconName.SupportBunny]: SupportBunny,
  [IconName.SupportChatBunny]: SupportChatBunny,
  [IconName.SupportSuccessBunny]: SupportSuccessBunny,
  [IconName.CalendarBunny]: CalendarBunny,
  [IconName.CalendarSuccessBunny]: CalendarSuccessBunny,
  [IconName.Car]: CarIcon,
  [IconName.CheckBunmoji]: CheckBunmoji,
  [IconName.CheckCircle]: CheckCircleIcon,
  [IconName.Payment]: PaymentIcon,
  [IconName.CheckCircleTransparent]: CheckCircleTransparentIcon,
  [IconName.CheckCircleOutline]: CheckCircleOutlineIcon,
  [IconName.CheckShield]: CheckShieldIcon,
  [IconName.CheckShieldBlue]: CheckShieldBlueIcon,
  [IconName.NotAllowedSign]: NotAllowedSignIcon,
  [IconName.PriceFreeze]: PriceFreezeIcon,
  [IconName.NavigationForward]: NavigationForwardIcon,
  [IconName.Lock]: LockIcon,
  [IconName.Person]: UserIcon,
  [IconName.User]: UserIcon,
  [IconName.Rewind]: RewindIcon,
  [IconName.Card]: CardIcon,
  [IconName.Watch]: WatchIcon,
  [IconName.Increase]: IncreaseIcon,
  [IconName.Decrease]: DecreaseIcon,
  [IconName.MapPin]: MapPin,
  [IconName.MapPinRound]: MapPinRound,
  [IconName.B2BWatch]: B2BWatchIcon,
  [IconName.B2BWatchChecked]: B2BWatchCheckedIcon,
  [IconName.IncreasedReviewed]: IncreaseReviewed,
  [IconName.DecreaseReviewed]: DecreaseReviewed,
  [IconName.PencilEdit]: PencilEditIcon,
  [IconName.PlusOutline]: PlusOutlineIcon,
  [IconName.ErrorAlert]: ErrorAlertIcon,
  [IconName.WarningAlert]: WarningAlertIcon,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.InfoWhiteCircle]: InfoCircleWhiteIcon,
  [IconName.InfoCircleFill]: InfoCircleFillIcon,
  [IconName.Calendar]: CalendarIcon,
  [IconName.CheckInCalendar]: CheckInCalendar,
  [IconName.CheckOutCalendar]: CheckOutCalendar,
  [IconName.B2BEditPencil]: B2BEditPencilIcon,
  [IconName.Dropdown]: Dropdown,
  [IconName.B2BUser]: B2BUser,
  [IconName.B2BMapPin]: B2BMapPin,
  [IconName.SuiteCase]: SuiteCaseIcon,
  [IconName.Transmission]: TransmissionIcon,
  [IconName.AirConditioning]: AirConditioningIcon,
  [IconName.ClockIcon]: ClockIcon,
  [IconName.CheckCircleFilled]: CheckCircleFilledIcon,
  [IconName.Generic]: GenericIcon,
  [IconName.MagnifyingGlass]: MagnifyingGlassIcon,
  [IconName.B2BAirplaneIcon]: B2BAirplaneIcon,
  [IconName.B2BOriginIcon]: B2BOriginIcon,
  [IconName.ShowAllImagesIcon]: ShowAllImagesIcon,
  [IconName.B2BPriceWatch]: B2BPriceWatch,
  [IconName.ExternalLinkIcon]: ExternalLinkIcon,
  [IconName.ErrorState]: HopperAssets.Ds.Thumb.Warning.IconOrange_2x,
  [IconName.UnableToProcess]: UnableToProcess,
  [IconName.B2bPriceFreeze]: B2bPriceFreeze,
  [IconName.B2bPriceFreezeNoCircle]: B2bPriceFreezeNoCircle,
  [IconName.PriceDropProtectionIcon]: PriceDropProtectionIcon,
  [IconName.PriceDropProtectionShield]: PriceDropProtectionShield,
  [IconName.Guarantee]: GuaranteeIcon,
  [IconName.BellAlert]: BellAlertIcon,
  [IconName.PriceDrop]: PriceDropIcon,
  [IconName.Checked]: CheckedIcon,
  [IconName.PricePredictionIcon]: PricePredictionIcon,
  [IconName.PriceMatchGuaranteeIcon]: PriceMatchGuaranteeIcon,
  [IconName.DottedLine]: DottedLineIcon,
  [IconName.PriceFreezeArrow]: PriceFreezeArrow,
  [IconName.SeatSelectionIcon]: SeatSelectionIcon,
  [IconName.FrozenPrice]: FrozenPriceIcon,
  [IconName.BellWarning]: BellWarningIcon,
  [IconName.MoneyBag]: MoneyBagIcon,
  [IconName.LuxuryBenefitMilesIcon]: LuxuryBenefitMilesIcon,
  [IconName.LuxuryBenefitCashIcon]: LuxuryBenefitCashIcon,
  [IconName.LuxuryBenefitServiceIcon]: LuxuryBenefitServiceIcon,
  [IconName.EarlyCheckInCalendarIcon]: EarlyCheckInCalendarIcon,
  [IconName.MealIcon]: MealIcon,
  [IconName.UpgradeIcon]: UpgradeIcon,
  [IconName.LateCheckoutIcon]: LateCheckoutIcon,
  [IconName.WifiIcon]: WifiIcon,
  [IconName.PerksIcon]: PerksIcon,
  [IconName.CheckCircleFilledBlue]: CheckCircleFilledBlueIcon,
  [IconName.ModalPriceDrop]: ModalPriceDrop,
  [IconName.HopperLogo]: HopperLogo,
  [IconName.FilterMenu]: FilterMenu,
  [IconName.FilterMenuThick]: FilterMenuThick,
  [IconName.Checkmark]: Checkmark,
  [IconName.Delete]: Delete,
  [IconName.Close]: Close,
  [IconName.XCircleFilled]: XCircleFilled,
  [IconName.PoundCurrencySymbol]: PoundCurrencySymbol,
  [IconName.Subtract]: SubtractIcon,
  [IconName.ArrowDiverge]: ArrowDiverge,
  [IconName.Arrows]: Arrows,
  [IconName.FilledClock]: FilledClock,
  [IconName.CircleAlert]: CircleAlert,
  [IconName.Chevron]: Chevron,
  [IconName.Refresh]: Refresh,
  [IconName.Reload]: Reload,
  [IconName.ArrowRight]: ArrowRight,
  [IconName.StarEmpty]: StarEmpty,
  [IconName.StarFilled]: StarFilled,
  [IconName.StarRoundedEmpty]: StarRoundedEmpty,
  [IconName.StarRoundedFilled]: StarRoundedFilled,
  [IconName.Bed]: Bed,
  [IconName.SpeechBubbleBunmoji]: SpeechBubbleBunmoji,
  [IconName.BookTravel]: BookTravel,
  [IconName.WorldMap]: WorldMap,
  [IconName.CircleAirplane]: CircleAirplane,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ComboFlight]: ComboFlight,
  [IconName.CarryOnBaggage]: CarryOnBaggage,
  [IconName.TripProtection]: TripProtection,
  [IconName.TripProtectionCrossSolid]: TripProtectionCrossSolid,
  [IconName.ShieldPayment]: ShieldPayment,
  [IconName.WalletFlat]: WalletFlat,
  [IconName.CheckSolid]: CheckSolid,
  [IconName.DismissOutline]: DismissOutline,
  [IconName.Check]: Check,
  [IconName.InfoOutline]: InfoOutline,
  [IconName.InfoOutlineTriangle]: InfoOutlineTriangle,
  [IconName.ClockGreyOutline]: ClockGreyOutline,
  [IconName.PlaneLanding]: PlaneLanding,
  [IconName.PlaneTakeoff]: PlaneTakeoff,
  [IconName.Ellipse]: Ellipse,
  [IconName.LayoverClock]: LayoverClock,
  [IconName.BaggageCarryOn]: BaggageCarryOn,
  [IconName.ShieldMissedConnection]: ShieldMissedConnection,
  [IconName.MultiPlanes]: MultiPlanes,
  [IconName.AccordionArrow]: AccordionArrow,
  [IconName.CrossOutSign]: CrossOutSign,
  [IconName.Tada]: Tada,
  [IconName.RoundArrowDown]: RoundArrowDown,
  [IconName.SendMessage]: SendMessage,
  [IconName.SupportChat]: SupportChat,
  [IconName.SupportChevronRight]: SupportChevronRight,
  [IconName.SupportPhone]: SupportPhone,
};

export interface IconProps {
  name: IconName;
  className?: string;
  ariaLabel?: string;
}

export const Icon = ({
  name,
  ariaLabel,
  ...props
}: IconProps): React.ReactElement => (
  <img
    src={iconMap[name]}
    alt={name}
    height={16}
    width={16}
    {...props}
    aria-label={ariaLabel ?? name}
  />
);
