import {
  CreateSessionFailureReason,
  VerificationMethod,
} from "@b2bportal/auth-api";
import { getChildState } from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, VerifyPhoneForm } from "@hopper-b2b/ui-core";
import {
  AuthEvents,
  AuthMachineEventType,
  getError,
  getFormattedPhoneNumber,
  getVerificationAlternatives,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import { AuthType } from "../../../../types/tracking";
import "./styles.scss";

export const VerifyPhoneModal = () => {
  const { t } = useI18nContext();
  const [state, send] = useAuthState<AuthEvents, unknown>();
  const childState = getChildState(state.value);

  const phoneNumber = useAuthSelector(getFormattedPhoneNumber);
  const verificationAlternatives = useAuthSelector(getVerificationAlternatives);
  const error = useAuthSelector(getError);
  const hasAlternateMethod = verificationAlternatives?.redactedEmail;
  const redactedPhone = verificationAlternatives?.redactedPhone;

  const errorMessage =
    error === CreateSessionFailureReason.InvalidCode
      ? t("auth.errors.invalidCode")
      : null;

  const onContinue = ({ code }) => {
    send({
      type: AuthMachineEventType.VERIFY_CODE,
      code,
      auth_type: AuthType.Phone,
    });
  };

  const resendCode = () => {
    send({
      type: AuthMachineEventType.RESEND,
      verificationMethod: VerificationMethod.VerifyByPhone,
    });
  };

  const onAdditionalOptionClick = () => {
    send(AuthMachineEventType.OTHER_OPTIONS);
  };

  return childState === "additionalOptions" ? (
    <AdditionalOption />
  ) : (
    <VerifyPhoneForm
      phoneNumber={phoneNumber || redactedPhone}
      resendDisabled={false}
      onVerify={onContinue}
      onResend={resendCode}
      errorText={errorMessage}
    />
  );
};

export const AdditionalOption = () => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();

  const sendCode = () =>
    send({
      type: AuthMachineEventType.RESEND,
      verificationMethod: VerificationMethod.VerifyByEmail,
    });

  const redactedEmail = useAuthSelector(
    getVerificationAlternatives
  )?.redactedEmail;

  return (
    <div className="phone-additional-option-content">
      <div className="title-container">
        <h1 className="title">{t("additionalModalTitle")}</h1>
        <p className="subtitle">{t("additionalModalSubtitle")}</p>
      </div>
      <div className="additional-method-card">
        <div className="labels">
          <p className="label">{t("email")}</p>
          <p className="redacted">{redactedEmail}</p>
        </div>
        <ActionButton onClick={sendCode} message={t("sendCode")} />
      </div>
    </div>
  );
};
