import { getLang } from "@hopper-b2b/i18n";
import { useCallback } from "react";
import { removeSession } from "../api";
import { REMOVE_SESSION, useAuthProvider } from "@hopper-b2b/utilities";
import { createAnonymousSession } from "./session";
import { startNewFeatureFlagsSession } from "@hopper-b2b/utilities";

export const useLogout = () => {
  const { dispatch } = useAuthProvider();

  const lang = getLang();

  return useCallback(
    () =>
      removeSession().then(() => {
        dispatch({
          type: REMOVE_SESSION,
        });
        startNewFeatureFlagsSession();
        createAnonymousSession(lang).then(() => window.location.reload());
      }),
    [dispatch]
  );
};
