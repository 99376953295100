import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiSkeleton: Components["MuiSkeleton"] = {
  styleOverrides: {
    root: {
      background: tokens.background.shimmer,
    },
  },
};
