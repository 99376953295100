import React from "react";
import { ReactComponent as PriceFreezeIcon } from "../../assets/icons/amenities/pricefreeze.svg";
import { ReactComponent as EarlyCheckInCalendarIcon } from "../../assets/icons/cap1/calendar-icon.svg";
import { ReactComponent as LateCheckoutIcon } from "../../assets/icons/cap1/late-checkout-icon.svg";
import { ReactComponent as LuxuryBenefitCashIcon } from "../../assets/icons/cap1/luxury-benefit-cash.svg";
import { ReactComponent as LuxuryBenefitMilesIcon } from "../../assets/icons/cap1/luxury-benefit-miles.svg";
import { ReactComponent as LuxuryBenefitServiceIcon } from "../../assets/icons/cap1/luxury-benefit-service.svg";
import { ReactComponent as MealIcon } from "../../assets/icons/cap1/meal-icon.svg";
import { ReactComponent as MoneyBagIcon } from "../../assets/icons/cap1/money-bag-icon.svg";
import { ReactComponent as PerksIcon } from "../../assets/icons/cap1/perks-icon.svg";
import { ReactComponent as PriceDropProtectionShield } from "../../assets/icons/cap1/price-drop-protection-shield.svg";
import { ReactComponent as PriceDropProtectionIcon } from "../../assets/icons/cap1/price-drop-protection.svg";
import { ReactComponent as ModalPriceDrop } from "../../assets/icons/cap1/price-drop.svg";
import { ReactComponent as PriceFreezeArrow } from "../../assets/icons/cap1/price-freeze-arrow.svg";
import { ReactComponent as B2bPriceFreezeNoCircle } from "../../assets/icons/cap1/price-freeze-no-circle.svg";
import { ReactComponent as B2bPriceFreeze } from "../../assets/icons/cap1/price-freeze.svg";
import { ReactComponent as PriceMatchGuaranteeIcon } from "../../assets/icons/cap1/price-match-icon.svg";
import { ReactComponent as PricePredictionIcon } from "../../assets/icons/cap1/price-prediction.svg";
import { ReactComponent as UnableToProcess } from "../../assets/icons/cap1/unable-to-process.svg";
import { ReactComponent as UpgradeIcon } from "../../assets/icons/cap1/upgrade-icon.svg";
import { ReactComponent as WifiIcon } from "../../assets/icons/cap1/wifi-icon.svg";
import { ReactComponent as AirConditioningIcon } from "../../assets/icons/car/amenities/air-conditioning-icon.svg";
import { ReactComponent as TransmissionIcon } from "../../assets/icons/car/transmission-icon.svg";
import { ReactComponent as ErrorAlertIcon } from "../../assets/icons/common/alert-error.svg";
import { ReactComponent as WarningAlertIcon } from "../../assets/icons/common/alert-warning.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/common/arrow-right.svg";
import { ReactComponent as ArrowDiverge } from "../../assets/icons/common/arrow-diverge.svg";
import { ReactComponent as Arrows } from "../../assets/icons/common/arrows.svg";
import { ReactComponent as B2BAirplaneIcon } from "../../assets/icons/common/b2b-airplane-icon.svg";
import { ReactComponent as B2BEditPencilIcon } from "../../assets/icons/common/b2b-edit-pencil.svg";
import { ReactComponent as B2BMapPin } from "../../assets/icons/common/b2b-map-pin.svg";
import { ReactComponent as B2BOriginIcon } from "../../assets/icons/common/b2b-origin-icon.svg";
import { ReactComponent as B2BPriceWatch } from "../../assets/icons/common/b2b-price-watch.svg";
import { ReactComponent as B2BUser } from "../../assets/icons/common/b2b-user.svg";
import { ReactComponent as BellWarningIcon } from "../../assets/icons/common/bell-warning-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/common/calendar.svg";
import { ReactComponent as CheckInCalendar } from "../../assets/icons/common/check-in-calendar.svg";
import { ReactComponent as CheckOutCalendar } from "../../assets/icons/common/check-out-calendar.svg";
import { ReactComponent as CheckCircleFilledBlueIcon } from "../../assets/icons/common/check-circle-filled-blue.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/common/check-circle.svg";
import { ReactComponent as CheckCircleFilledIcon } from "../../assets/icons/common/check-filled.svg";
import { ReactComponent as CheckCircleOutlineIcon } from "../../assets/icons/common/check-circle-outline.svg";
import { ReactComponent as CheckCircleTransparentIcon } from "../../assets/icons/common/check-mark-transparent.svg";
import { ReactComponent as CheckShieldBlueIcon } from "../../assets/icons/common/check-shield-blue.svg";
import { ReactComponent as CheckShieldIcon } from "../../assets/icons/common/check-shield.svg";
import { ReactComponent as CircleAlert } from "../../assets/icons/common/circle-alert.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/common/clock.svg";
import { ReactComponent as CarIcon } from "../../assets/icons/car/car-icon.svg";
import { ReactComponent as BookTravel } from "../../assets/icons/flight/book-travel.svg";
import { ReactComponent as WorldMap } from "../../assets/icons/common/world-map.svg";
import { ReactComponent as Close } from "../../assets/icons/common/close-button.svg";
import { ReactComponent as Checkmark } from "../../assets/icons/common/confirmation-check-mark.svg";
import { ReactComponent as DecreaseIcon } from "../../assets/icons/common/decrease-arrow.svg";
import { ReactComponent as Dropdown } from "../../assets/icons/common/dropdown.svg";
import { ReactComponent as ExternalLinkIcon } from "../../assets/icons/common/external-link-icon.svg";
import { ReactComponent as FilledClock } from "../../assets/icons/common/filled-clock.svg";
import { ReactComponent as FilterMenu } from "../../assets/icons/common/filter-menu.svg";
import { ReactComponent as FilterMenuThick } from "../../assets/icons/common/filter-menu-thick.svg";
import { ReactComponent as PoundCurrencySymbol } from "../../assets/icons/common/gbp.svg";
import { ReactComponent as GenericIcon } from "../../assets/icons/common/generic.svg";
import { ReactComponent as BellAlertIcon } from "../../assets/icons/common/grey-bell-alert-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/common/grey-checked-icon.svg";
import { ReactComponent as DottedLineIcon } from "../../assets/icons/common/grey-dotted-line.svg";
import { ReactComponent as GuaranteeIcon } from "../../assets/icons/common/grey-guarantee-icon.svg";
import { ReactComponent as PriceDropIcon } from "../../assets/icons/common/grey-price-drop-icon.svg";
import { ReactComponent as IncreaseIcon } from "../../assets/icons/common/increase-arrow.svg";
import { ReactComponent as InfoCircleFillIcon } from "../../assets/icons/common/info-circle-fill.svg";
import { ReactComponent as InfoCircleWhiteIcon } from "../../assets/icons/common/info-white-icon.svg";
import { ReactComponent as InfoCircleIcon } from "../../assets/icons/common/info-icon.svg";
import { ReactComponent as MagnifyingGlassIcon } from "../../assets/icons/common/magnifying-glass-icon.svg";
import { ReactComponent as MoneyFill } from "../../assets/icons/common/money-fill.svg";
import { ReactComponent as MoneyOutlineGBPIcon } from "../../assets/icons/common/money-outline-gbp.svg";
import { ReactComponent as MoneyOutlineTransparentIcon } from "../../assets/icons/common/money-outline-transparent.svg";
import { ReactComponent as MoneyOutlineIcon } from "../../assets/icons/common/money-outline.svg";
import { ReactComponent as NotAllowedSignIcon } from "../../assets/icons/common/not-allowed-sign.svg";
import { ReactComponent as PaymentIcon } from "../../assets/icons/common/payment.svg";
import { ReactComponent as PencilEditIcon } from "../../assets/icons/common/pencil-edit-icon.svg";
import { ReactComponent as PlusOutline } from "../../assets/icons/common/plus-outline.svg";
import { ReactComponent as Refresh } from "../../assets/icons/common/refresh.svg";
import { ReactComponent as Reload } from "../../assets/icons/common/reload.svg";
import { ReactComponent as RewindIcon } from "../../assets/icons/common/rewind-time.svg";
import { ReactComponent as ShowAllImagesIcon } from "../../assets/icons/common/show-all-images.svg";
import { ReactComponent as StarEmpty } from "../../assets/icons/common/star-empty.svg";
import { ReactComponent as StarFilled } from "../../assets/icons/common/star-filled.svg";
import { ReactComponent as StarRoundedEmpty } from "../../assets/icons/support/star-rounded-empty.svg";
import { ReactComponent as StarRoundedFilled } from "../../assets/icons/support/star-rounded-filled.svg";
import { ReactComponent as SubtractIcon } from "../../assets/icons/common/subtract.svg";
import { ReactComponent as SuiteCaseIcon } from "../../assets/icons/common/suitecase-icon.svg";
import { ReactComponent as Delete } from "../../assets/icons/common/trash-bin-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/common/user.svg";
import { ReactComponent as B2BWatchCheckedIcon } from "../../assets/icons/common/watch-b2b-checked.svg";
import { ReactComponent as B2BWatchIcon } from "../../assets/icons/common/watch-b2b.svg";
import { ReactComponent as WatchIcon } from "../../assets/icons/common/watch.svg";
import { ReactComponent as XCircleFilled } from "../../assets/icons/common/x-circle-filled.svg";
import { ReactComponent as FrozenPriceIcon } from "../../assets/icons/flight/frozen-price.svg";
import { ReactComponent as SeatSelectionIcon } from "../../assets/icons/flight/seat-selection.svg";
import { ReactComponent as HopperLogo } from "../../assets/icons/hopper/hopper-logo.svg";
import { ReactComponent as MapPin } from "../../assets/icons/hotel/map-pin.svg";
import { ReactComponent as MapPinRound } from "../../assets/icons/hotel/map-pin-round.svg";
import { ReactComponent as CardIcon } from "../../assets/icons/payments/card_generic.svg";
import { ReactComponent as DecreaseReviewed } from "../../assets/icons/system/DecreaseReviewed.svg";
import { ReactComponent as IncreaseReviewed } from "../../assets/icons/system/IncreaseReviewed.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/system/lock.svg";
import { ReactComponent as NavigationForwardIcon } from "../../assets/icons/system/navigation-forward.svg";
import { ReactComponent as Chevron } from "../../assets/icons/uber/chevron.svg";
import { ReactComponent as Bed } from "../../assets/icons/hotel/bed.svg";
import { ReactComponent as CheckBunmoji } from "../../assets/icons/hopper/check_bunmoji.svg";
import { ReactComponent as SpeechBubbleBunmoji } from "../../assets/icons/hopper/speech_bubble_bunmoji.svg";
import { ReactComponent as CircleAirplane } from "../../assets/icons/common/circle-airplane.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/common/chevron-down.svg";
import { ReactComponent as ComboFlight } from "../../assets/icons/common/combo-flight.svg";
import { ReactComponent as CarryOnBaggage } from "../../assets/icons/common/carry-on-baggage.svg";
import { ReactComponent as TripProtection } from "../../assets/icons/common/trip-protection.svg";
import { ReactComponent as TripProtectionCrossSolid } from "../../assets/icons/common/trip-protection-cross-solid.svg";
import { ReactComponent as ShieldPayment } from "../../assets/icons/common/shield-payment.svg";
import { ReactComponent as WalletFlat } from "../../assets/icons/hopper/wallet_flat.svg";
import { ReactComponent as CheckSolid } from "../../assets/icons/system/check-solid.svg";
import { ReactComponent as DismissOutline } from "../../assets/icons/system/dismiss-outline.svg";
import { ReactComponent as Check } from "../../assets/icons/common/check.svg";
import { ReactComponent as InfoOutline } from "../../assets/icons/common/info-outline.svg";
import { ReactComponent as InfoOutlineTriangle } from "../../assets/icons/common/info-outline-triangle.svg";
import { ReactComponent as ClockGreyOutline } from "../../assets/icons/common/clock-grey-outline.svg";
import { ReactComponent as PlaneLanding } from "../../assets/icons/common/plane-landing.svg";
import { ReactComponent as PlaneTakeoff } from "../../assets/icons/common/plane-takeoff.svg";
import { ReactComponent as Ellipse } from "../../assets/icons/common/ellipse.svg";
import { ReactComponent as LayoverClock } from "../../assets/icons/common/layover-clock.svg";
import { ReactComponent as BaggageCarryOn } from "../../assets/icons/common/baggage-carry-on.svg";
import { ReactComponent as ShieldMissedConnection } from "../../assets/icons/common/shield-missed-connection.svg";
import { ReactComponent as MultiPlanes } from "../../assets/icons/common/multi-planes.svg";
import { ReactComponent as AccordionArrow } from "../../assets/icons/common/accordion-arrow.svg";
import { ReactComponent as CrossOutSign } from "../../assets/icons/common/cross-out-sign.svg";
import { ReactComponent as Tada } from "../../assets/icons/common/tada.svg";
import { ReactComponent as RoundArrowDown } from "../../assets/icons/system/round-arrow-down.svg";
import { ReactComponent as SendMessage } from "../../assets/icons/support/send-message.svg";
import { ReactComponent as SupportChat } from "../../assets/icons/support/support-chat.svg";
import { ReactComponent as SupportChevronRight } from "../../assets/icons/support/support-chevron-right.svg";
import { ReactComponent as SupportPhone } from "../../assets/icons/support/support-phone.svg";

// Large SVGs (>40KB) should not be imported into bundle, and should be loaded from CDN instead
// TODO: Add these bunny images to CDN
import { ReactComponent as CalendarBunny } from "../../assets/bunnies/calendar_bunny.svg";
import { ReactComponent as CalendarSuccessBunny } from "../../assets/bunnies/calendar_success_bunny.svg";
import { ReactComponent as SupportBunny } from "../../assets/bunnies/support_bunny.svg";
import { ReactComponent as SupportChatBunny } from "../../assets/bunnies/support_chat_bunny.svg";
import { ReactComponent as SupportSuccessBunny } from "../../assets/bunnies/support_success_bunny.svg";

import { IconName } from "../Icon/component";
import "./styles.scss";
interface PropsWithAlt extends React.SVGProps<SVGSVGElement> {
  alt?: string;
}

export type SvgrComponent = React.FunctionComponent<PropsWithAlt>;

type IconComponentMap = {
  [name in IconName]: SvgrComponent;
};

export const iconComponentMap: Partial<IconComponentMap> = {
  [IconName.MoneyOutline]: MoneyOutlineIcon,
  [IconName.MoneyOutlineGBP]: MoneyOutlineGBPIcon,
  [IconName.MoneyOutlineTransparentIcon]: MoneyOutlineTransparentIcon,
  [IconName.MoneyFill]: MoneyFill,
  [IconName.CheckBunmoji]: CheckBunmoji,
  [IconName.CheckCircle]: CheckCircleIcon,
  [IconName.Payment]: PaymentIcon,
  [IconName.CheckCircleTransparent]: CheckCircleTransparentIcon,
  [IconName.CheckCircleOutline]: CheckCircleOutlineIcon,
  [IconName.CheckShield]: CheckShieldIcon,
  [IconName.CheckShieldBlue]: CheckShieldBlueIcon,
  [IconName.NotAllowedSign]: NotAllowedSignIcon,
  [IconName.PriceFreeze]: PriceFreezeIcon,
  [IconName.NavigationForward]: NavigationForwardIcon,
  [IconName.Lock]: LockIcon,
  [IconName.Person]: UserIcon,
  [IconName.User]: UserIcon,
  [IconName.Rewind]: RewindIcon,
  [IconName.Card]: CardIcon,
  [IconName.Watch]: WatchIcon,
  [IconName.Increase]: IncreaseIcon,
  [IconName.Decrease]: DecreaseIcon,
  [IconName.MapPin]: MapPin,
  [IconName.MapPinRound]: MapPinRound,
  [IconName.B2BWatch]: B2BWatchIcon,
  [IconName.B2BWatchChecked]: B2BWatchCheckedIcon,
  [IconName.IncreasedReviewed]: IncreaseReviewed,
  [IconName.DecreaseReviewed]: DecreaseReviewed,
  [IconName.PlusOutline]: PlusOutline,
  [IconName.PencilEdit]: PencilEditIcon,
  [IconName.ErrorAlert]: ErrorAlertIcon,
  [IconName.WarningAlert]: WarningAlertIcon,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.InfoCircleFill]: InfoCircleFillIcon,
  [IconName.InfoWhiteCircle]: InfoCircleWhiteIcon,
  [IconName.Calendar]: CalendarIcon,
  [IconName.CheckInCalendar]: CheckInCalendar,
  [IconName.CheckOutCalendar]: CheckOutCalendar,
  [IconName.B2BEditPencil]: B2BEditPencilIcon,
  [IconName.Dropdown]: Dropdown,
  [IconName.B2BUser]: B2BUser,
  [IconName.B2BMapPin]: B2BMapPin,
  [IconName.SuiteCase]: SuiteCaseIcon,
  [IconName.Transmission]: TransmissionIcon,
  [IconName.AirConditioning]: AirConditioningIcon,
  [IconName.ClockIcon]: ClockIcon,
  [IconName.Car]: CarIcon,
  [IconName.BookTravel]: BookTravel,
  [IconName.WorldMap]: WorldMap,
  [IconName.CircleAirplane]: CircleAirplane,
  [IconName.CheckCircleFilled]: CheckCircleFilledIcon,
  [IconName.Generic]: GenericIcon,
  [IconName.MagnifyingGlass]: MagnifyingGlassIcon,
  [IconName.B2BAirplaneIcon]: B2BAirplaneIcon,
  [IconName.B2BOriginIcon]: B2BOriginIcon,
  [IconName.ShowAllImagesIcon]: ShowAllImagesIcon,
  [IconName.StarFilled]: StarFilled,
  [IconName.StarEmpty]: StarEmpty,
  [IconName.StarRoundedFilled]: StarRoundedFilled,
  [IconName.StarRoundedEmpty]: StarRoundedEmpty,
  [IconName.B2BPriceWatch]: B2BPriceWatch,
  [IconName.ExternalLinkIcon]: ExternalLinkIcon,
  [IconName.UnableToProcess]: UnableToProcess,
  [IconName.B2bPriceFreeze]: B2bPriceFreeze,
  [IconName.B2bPriceFreezeNoCircle]: B2bPriceFreezeNoCircle,
  [IconName.PriceDropProtectionIcon]: PriceDropProtectionIcon,
  [IconName.PriceDropProtectionShield]: PriceDropProtectionShield,
  [IconName.Guarantee]: GuaranteeIcon,
  [IconName.BellAlert]: BellAlertIcon,
  [IconName.PriceDrop]: PriceDropIcon,
  [IconName.Checked]: CheckedIcon,
  [IconName.PricePredictionIcon]: PricePredictionIcon,
  [IconName.PriceMatchGuaranteeIcon]: PriceMatchGuaranteeIcon,
  [IconName.DottedLine]: DottedLineIcon,
  [IconName.PriceFreezeArrow]: PriceFreezeArrow,
  [IconName.SeatSelectionIcon]: SeatSelectionIcon,
  [IconName.FrozenPrice]: FrozenPriceIcon,
  [IconName.BellWarning]: BellWarningIcon,
  [IconName.MoneyBag]: MoneyBagIcon,
  [IconName.LuxuryBenefitMilesIcon]: LuxuryBenefitMilesIcon,
  [IconName.LuxuryBenefitCashIcon]: LuxuryBenefitCashIcon,
  [IconName.LuxuryBenefitServiceIcon]: LuxuryBenefitServiceIcon,
  [IconName.EarlyCheckInCalendarIcon]: EarlyCheckInCalendarIcon,
  [IconName.MealIcon]: MealIcon,
  [IconName.UpgradeIcon]: UpgradeIcon,
  [IconName.LateCheckoutIcon]: LateCheckoutIcon,
  [IconName.WifiIcon]: WifiIcon,
  [IconName.PerksIcon]: PerksIcon,
  [IconName.CheckCircleFilledBlue]: CheckCircleFilledBlueIcon,
  [IconName.ModalPriceDrop]: ModalPriceDrop,
  [IconName.HopperLogo]: HopperLogo,
  [IconName.FilterMenu]: FilterMenu,
  [IconName.FilterMenuThick]: FilterMenuThick,
  [IconName.Checkmark]: Checkmark,
  [IconName.Delete]: Delete,
  [IconName.Close]: Close,
  [IconName.XCircleFilled]: XCircleFilled,
  [IconName.PoundCurrencySymbol]: PoundCurrencySymbol,
  [IconName.Subtract]: SubtractIcon,
  [IconName.ArrowDiverge]: ArrowDiverge,
  [IconName.Arrows]: Arrows,
  [IconName.FilledClock]: FilledClock,
  [IconName.CircleAlert]: CircleAlert,
  [IconName.Chevron]: Chevron,
  [IconName.Refresh]: Refresh,
  [IconName.Reload]: Reload,
  [IconName.ArrowRight]: ArrowRight,
  [IconName.Bed]: Bed,
  [IconName.SpeechBubbleBunmoji]: SpeechBubbleBunmoji,
  [IconName.ChevronDown]: ChevronDown,
  [IconName.ComboFlight]: ComboFlight,
  [IconName.CarryOnBaggage]: CarryOnBaggage,
  [IconName.TripProtection]: TripProtection,
  [IconName.TripProtectionCrossSolid]: TripProtectionCrossSolid,
  [IconName.ShieldPayment]: ShieldPayment,
  [IconName.WalletFlat]: WalletFlat,
  [IconName.CheckSolid]: CheckSolid,
  [IconName.DismissOutline]: DismissOutline,
  // [IconName.CalendarBunny]: CalendarBunny,
  // [IconName.CalendarSuccessBunny]: CalendarSuccessBunny,
  // [IconName.SupportBunny]: SupportBunny,
  // [IconName.SupportChatBunny]: SupportChatBunny,
  // [IconName.SupportSuccessBunny]: SupportSuccessBunny,
  [IconName.Check]: Check,
  [IconName.InfoOutline]: InfoOutline,
  [IconName.InfoOutlineTriangle]: InfoOutlineTriangle,
  [IconName.ClockGreyOutline]: ClockGreyOutline,
  [IconName.PlaneLanding]: PlaneLanding,
  [IconName.PlaneTakeoff]: PlaneTakeoff,
  [IconName.Ellipse]: Ellipse,
  [IconName.LayoverClock]: LayoverClock,
  [IconName.BaggageCarryOn]: BaggageCarryOn,
  [IconName.ShieldMissedConnection]: ShieldMissedConnection,
  [IconName.MultiPlanes]: MultiPlanes,
  [IconName.AccordionArrow]: AccordionArrow,
  [IconName.CrossOutSign]: CrossOutSign,
  [IconName.Tada]: Tada,
  [IconName.RoundArrowDown]: RoundArrowDown,
  [IconName.SendMessage]: SendMessage,
  [IconName.SupportChat]: SupportChat,
  [IconName.SupportChevronRight]: SupportChevronRight,
  [IconName.SupportPhone]: SupportPhone,
};

export interface IconComponentProps {
  name: IconName;
  className?: string;
  ariaLabel?: string | null;
}

export const IconComponent = ({
  name,
  ariaLabel,
  ...props
}: IconComponentProps): React.ReactElement => {
  const IconComponent = iconComponentMap[name] as SvgrComponent;
  return (
    <IconComponent
      {...props}
      className={`${props.className} icon-component`}
      aria-label={ariaLabel ?? name}
    />
  );
};
