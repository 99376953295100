"use client";

import { Typography } from "@mui/material";
import styled from "styled-components";
import HopperLogo from "../assets/hopperLogo.svg";
import { getIcon, setLink } from "../utils";

interface Social {
  platform: string;
  url: string;
}

interface Link {
  title: string;
  url: string;
}
interface Column {
  header: string;
  isExternal: boolean;
  links: Link[];
}

interface FooterProps {
  left: {
    hasLogo: boolean;
    socialMedia: Social[];
  };
  columns: Column[];
}

const Footer = (props: FooterProps) => {
  return (
    <FooterContainer>
      <Logo src={HopperLogo} />
      <Left>
        <SocialWrapper>
          {props.left.socialMedia.map((social: Social, i: number) => {
            return (
              <Social
                target="_blank"
                rel="noopener noreferrer"
                key={`${social.platform}-${i}`}
                href={setLink(social.url)}
              >
                {getIcon(social.platform.toLowerCase())}
              </Social>
            );
          })}
        </SocialWrapper>
      </Left>
      <Columns>
        {props.columns.map((column: Column, i: number) => {
          return (
            <Column key={`FooterColumn-${i}`}>
              <ColumnHeader>{column.header}</ColumnHeader>

              {column.links.map((link: Link, j: number) => {
                return (
                  <ColumnLink href={link.url} key={`${column.header}-link${j}`}>
                    {link.title}
                  </ColumnLink>
                );
              })}
            </Column>
          );
        })}
      </Columns>
      <Typography variant="caption" color="transparent">
        {process.env.VITE_REACT_APP_VERSION ?? `local`}
      </Typography>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  width: 92%;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 48px;
`;

const Left = styled.div`
  margin-bottom: 24px;
`;

const SocialWrapper = styled.div`
  margin-top: 34px;
`;

const Social = styled.a`
  margin-right: 20px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Logo = styled.img`
  width: 50%;
  max-width: 180px;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnHeader = styled.h5`
  font-weight: 700;
  font-size: 16px;
  color: #505050;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const ColumnLink = styled.a`
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  color: #505050;
  margin-bottom: 8px;
`;
