import clsx from "clsx";
import "./styles.scss";
import { Box } from "@mui/material";

const CDN_BASE_URL = "https://cdn.hopper.com/airline_icons/";

export type RemoteAirlineIconSize = "small" | "medium" | "large";

export interface IRemoteAirlineIconProps {
  // An airline code must consist of two uppercase letters or digits
  airlineCode: string;
  size?: RemoteAirlineIconSize;
  className?: string;
  altText?: string;
}

/**
 * Load airline icon from remote hopper cdn, which is managed and up to date.
 * https://hopper-jira.atlassian.net/wiki/spaces/B2BTP/pages/6276513811/Hopper.com+CDN+usage
 * There is one size only on cdn. But since Airline icons are and backed by CDN, it shouldn't be a problem.
 */
export const RemoteAirlineIcon = (props: IRemoteAirlineIconProps) => {
  const {
    size = "large",
    className,
    airlineCode = "airline_placeholder_icon",
    altText,
  } = props;

  const iconUrl = `${CDN_BASE_URL}${airlineCode}.png`;

  return (
    <Box className={clsx("airline-icon", size, className)}>
      <img alt={altText ?? "airline icon"} src={iconUrl} />
    </Box>
  );
};
