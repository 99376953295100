import { default as dayjs, Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration, { Duration } from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { pluralize } from "./stringHelpers";
import { isDefined, isNotNull } from "./types";

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * Return's users timezone in `America/Chicago` format
 *
 */
export const getTimeZone = (): string => {
  return dayjs.tz.guess();
};

export const getTimeDifference = (timeA: string, timeB: string) => {
  const timeDiff = dayjs.duration(dayjs(timeA).diff(dayjs(timeB)));

  const dayValue = timeDiff.days();
  const hourValue = timeDiff.hours();
  const minuteValue = timeDiff.minutes();

  const tokens = [
    (dayValue > 0 && `${dayValue}d`) || null,
    (hourValue > 0 && `${hourValue}h`) || null,
    (minuteValue > 0 && `${minuteValue}m`) || null,
  ];
  return tokens.filter(isNotNull).join(" ");
};

export const URL_DATE_FORMAT = "YYYY-MM-DD";

export const toDate = (dateString?: string | Date | Dayjs): Dayjs =>
  dayjs(dateString);

export const formatDateForUrl = (
  dateString?: string | Dayjs | Date
): string | undefined =>
  dateString ? toDate(dateString).format(URL_DATE_FORMAT) : undefined;

const asDate = (time: string): dayjs.Dayjs =>
  dayjs(time).hour(0).minute(0).second(0).millisecond(0);

export const getCalendarDayDifference = (from: string, to: string): number =>
  asDate(to).diff(asDate(from), "days");

export const readableRelativeDays = (days: number): string => {
  if (days > 1) {
    return `${days} days later`;
  } else if (days === 1) {
    return "the next day";
  } else if (days < -1) {
    return `${Math.abs(days)} days before`;
  } else {
    return "the previous day";
  }
};

export const isBetweenDays = (
  date: Date,
  minAllowedDate?: Date,
  maxAllowedDate?: Date
) => {
  if (minAllowedDate && maxAllowedDate) {
    return dayjs(date).isBetween(minAllowedDate, maxAllowedDate, "day", "[]");
  }

  if (minAllowedDate) {
    return dayjs(date).isSameOrAfter(minAllowedDate, "day");
  }

  if (maxAllowedDate) {
    return dayjs(date).isSameOrBefore(maxAllowedDate, "day");
  }

  return true;
};

export const dayCountBelowMax = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  maxNumDays?: number
) => {
  if (!maxNumDays) {
    return true;
  }

  if (startDate && endDate) {
    return true;
  }

  // If only one of startDate or endDate is set, we don't really know if it's really a "start" or an "end".
  // We will allow dates in both past and future directions from such date.

  if (startDate) {
    return dayjs(date).isBetween(
      dayjs(startDate).subtract(maxNumDays - 1, "day"),
      dayjs(startDate).add(maxNumDays - 1, "day"),
      "day",
      "[]"
    );
  }

  if (endDate) {
    return dayjs(date).isBetween(
      dayjs(endDate).subtract(maxNumDays - 1, "day"),
      dayjs(endDate).add(maxNumDays - 1, "day"),
      "day",
      "[]"
    );
  }

  return true;
};

export const dayCountAboveMin = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  minNumDays?: number
) => {
  if (!minNumDays) {
    return true;
  }

  if (startDate && endDate) {
    return true;
  }

  // If only one of startDate or endDate is set, we don't really know if it's really a "start" or an "end".
  // We will allow dates in both past and future directions from such date.

  if (startDate) {
    return !dayjs(date).isBetween(
      dayjs(startDate).subtract(minNumDays - 1, "day"),
      dayjs(startDate).add(minNumDays - 1, "day"),
      "day",
      "[]"
    );
  }

  if (endDate) {
    return !dayjs(date).isBetween(
      dayjs(endDate).subtract(minNumDays - 1, "day"),
      dayjs(endDate).add(minNumDays - 1, "day"),
      "day",
      "[]"
    );
  }

  return true;
};

export const dayCountWithinRange = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  minNumDays?: number,
  maxNumDays?: number
) => {
  return (
    dayCountAboveMin(date, startDate, endDate, minNumDays) &&
    dayCountBelowMax(date, startDate, endDate, maxNumDays)
  );
};

export const isDateAllowed = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  minAllowedDate?: Date,
  maxAllowedDate?: Date,
  minNumDays?: number,
  maxNumDays?: number
) => {
  return (
    isBetweenDays(date, minAllowedDate, maxAllowedDate) &&
    dayCountWithinRange(date, startDate, endDate, minNumDays, maxNumDays)
  );
};

export const getDateTimeWithFormat = (dateTime: string, format: string) => {
  return dayjs(dateTime, format);
};

export const formatDateTime = (dateTime: string, format: string) => {
  return dayjs(dateTime).format(format);
};

export const formatInterval = (mins: number) => {
  if (mins > 60) {
    const totalHours = Math.floor(mins / 60);
    const totalMins = mins - totalHours * 60;
    return `${totalHours}h ${totalMins}m`;
  } else {
    return `${mins}m`;
  }
};

export const formatDurationDays = (dur: Duration): string => {
  const rawHours = dur.asHours();
  const days = Math.floor(rawHours / 24);
  const hours = Math.round(rawHours % 24);
  const tokens = [
    days > 0 ? `${days} ${pluralize(days, "day", "days")}` : undefined,
    hours > 0 ? `${hours} ${pluralize(hours, "hour", "hours")}` : undefined,
  ];
  return tokens.filter(isDefined).join(" ");
};

/**
 * Example: passing in "2023-03-01T07:00:00.000-07:00" (destination time) will
 * Return "2023-03-01T07:00:00.000" (user's time, for destination)
 */
export const removeTimezone = (time?: string) => {
  if (time === undefined) {
    return;
  }
  if (time.charAt(time.length - 1) === "Z") {
    return time.slice(0, -1);
  }
  return time.slice(0, -6);
};

/**
 * this function was taken from b2bportal repo
 * Example: passing in "2023-03-01T07:00:00.000-07:00" (destination time) will
 * Return "2023-03-01T07:00:00.000" (user's time, for destination)
 * use this function, when removetimezone breaks new Date
 */
export function removeTimezoneRegex(time?: string) {
  if (time === undefined) {
    return undefined;
  }
  const timezoneRegex = /[+-]\d{2}:\d{2}/g;
  //match ":00.000"
  const zeroTimeRegex = /:\d{2}\.\d{3}/g;
  let output = time.replace(timezoneRegex, "").replace(zeroTimeRegex, "");
  if (time.charAt(time.length - 1) === "Z") {
    output = time.slice(0, -1);
  }
  return output;
}

/**
 * Based on locale, check if DD/MM/YYYY is prefered over MM/DD/YYYY
 */
export const preferDayMonthYearFormat = () => {
  return dayjs().locale().includes("gb");
};

export const showDateWithRelativeTimezone = (
  zonedDateTime: string,
  formatting: string
) => {
  return dayjs(removeTimezone(zonedDateTime)).format(formatting);
};

export const parseMonthDayAndYear = (date: string) => {
  const numericString = date.replace(/[^\d]/g, "");
  const month = numericString.slice(0, 2);
  const day = numericString.slice(2, 4);
  const year = numericString.slice(4, 8);
  const strLength = numericString.length;

  return { month, day, year, strLength };
};

export const parseDayMonthAndYear = (date: string) => {
  const numericString = date.replace(/[^\d]/g, "");
  const day = numericString.slice(0, 2);
  const month = numericString.slice(2, 4);
  const year = numericString.slice(4, 8);
  const strLength = numericString.length;

  return { month, day, year, strLength };
};

export const parseYearMonthAndDay = (date: string) => {
  const numericString = date.replace(/[^\d]/g, "");
  const year = numericString.slice(0, 4);
  const month = numericString.slice(4, 6);
  const day = numericString.slice(6, 8);
  const strLength = numericString.length;

  return { month, day, year, strLength };
};

export const differenceInMinutes = (dateA: string, dateB: string) => {
  return dayjs(dateA).diff(dayjs(dateB), "minute", true);
};
