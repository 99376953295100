import {
  TrackImpressionRequest,
  TrackImpressionResponseResponse,
  TrackImpressionResponseResponseEnum,
  adsApi,
} from "@b2bportal/ads-api";
import { axiosInstance } from "@hopper-b2b/api";

export const trackImpression = async (
  request: TrackImpressionRequest,
  abortSignal: AbortSignal
): Promise<TrackImpressionResponseResponse> => {
  return new Promise((resolve, reject) => {
    try {
      adsApi(axiosInstance)
        .apiV0AdsTrackImpressionPost(request, {
          signal: abortSignal,
        })
        .then((res) => {
          const response = res.data;

          switch (response.Response) {
            case TrackImpressionResponseResponseEnum.Success:
              resolve(response);
              break;
            case TrackImpressionResponseResponseEnum.Failure:
              reject(
                new Error(
                  "Server returned an error: " + response.errors.join(", ")
                )
              );
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      reject(e);
    }
  });
};
