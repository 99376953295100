import { UserInfoField } from "@b2bportal/auth-api";
import { useTrackEvent } from "@hopper-b2b/tracking";
import { FinalizeForm as FinalizeFormComponent } from "@hopper-b2b/ui-core";
import { useEffect } from "react";
import {
  AuthEvents,
  AuthMachineEventType,
  getAuthType,
  getMissingFields,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import {
  AuthTrackingEvents,
  ViewedFinalizeEvent,
} from "../../../../types/tracking";
import "./styles.scss";

export const FinalizeForm = () => {
  const [, send] = useAuthState<AuthEvents, unknown>();
  const trackEvent = useTrackEvent();

  const authType = useAuthSelector(getAuthType);
  const missingFields = useAuthSelector(getMissingFields);
  const isFirstNameMissing = missingFields.includes(UserInfoField.FirstName); // Always required
  const isLastNameMissing = missingFields.includes(UserInfoField.LastName); // Always required
  const isEmailMissing = missingFields.includes(UserInfoField.Email);
  const isPhoneMissing = missingFields.includes(UserInfoField.PhoneNumber);

  const onContinue = ({
    email,
    firstName,
    lastName,
    phoneNumber,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => {
    send({
      type: AuthMachineEventType.FINALIZE_USER,
      missingFields: {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phoneNumber,
      },
    });
  };

  useEffect(() => {
    trackEvent({
      eventName: AuthTrackingEvents.ViewedFinalize,
      properties: {
        auth_type: authType,
      },
    } as ViewedFinalizeEvent);
  }, []);

  return (
    <FinalizeFormComponent
      isEmailMissing={isEmailMissing}
      isFirstNameMissing={isFirstNameMissing}
      isLastNameMissing={isLastNameMissing}
      isPhoneMissing={isPhoneMissing}
      onContinue={onContinue}
    />
  );
};
