import {
  Branding,
  ChatAvailability,
  ClientName,
  ContactSupportConfig,
  Currency,
  FlightShopCardType,
} from "@hopper-b2b/types";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.None,
  displayIntlSupportNumber: "+1-347-695-4555",
  displaySupportNumber: "+1-833-933-4671",
  displaySupportNumberPhonewords: "+1-833-933-HOP1",
  intlSupportNumber: "13476954555",
  supportNumber: "18339334671",
};

export const branding: Branding = {
  default: "en",
  en: {
    contactSupport,
    merchantPayments: "",
    clientName: ClientName.HOPPER,
    portalName: "Hopper Web",
    currency: {
      locale: "en-US",
      code: Currency.USD,
      symbol: "$",
    },
    urls: {
      termsAndConditions: "https://hopper.com/legal/terms-and-conditions",
      privacyPolicy: "https://hopper.com/legal/privacy-policy",
      priceFreezeTerms: "https://hopper.com/legal/Air-Price-Freeze",
      missedConnectionTerms:
        "https://hopper.com/legal/VI-missed-connection-guarantee",
    },
    preferredCountryCode: "US",
    preferredAreaCode: "+1",
    home: {
      title: "<b>Welcome</b> to Hopper.",
      subtitle: "Book the trip you've been waiting for",
    },
    supportedLanguages: [
      {
        key: "es",
        name: "Español",
        label: "Español",
      },
      { key: "en", name: "English", label: "English" },
      { key: "fr", name: "Français", label: "Français" },
    ],
    calendarMonthFormat: "MMMM",
    timeFormat: "hh:mm A",
    flightShopCardType: FlightShopCardType.MINI,
    useImperialUnits: true,
  },
  es: {
    contactSupport,
    merchantPayments: "",
    clientName: ClientName.HOPPER,
    portalName: "Web de Hopper",
    currency: {
      locale: "es",
      code: Currency.USD,
      symbol: "$",
    },
    urls: {
      termsAndConditions:
        "https://www.hopper.com/es/legal/terms-and-conditions/",
      privacyPolicy: "https://hopper.com/es/legal/privacy-policy",
      priceFreezeTerms: "https://hopper.com/es/legal/Air-Price-Freeze",
      missedConnectionTerms:
        "https://hopper.com/es/legal/Missed-Connection-Guarantee",
    },
    preferredCountryCode: "US",
    preferredAreaCode: "+1",
    home: {
      // TODO - verify translations
      title: "<b>Te damos la bienvenida</b> a Hopper",
      subtitle: "Reserva el viaje que estabas esperando.",
    },
    supportedLanguages: [
      {
        key: "es",
        name: "Español",
        label: "Español",
      },
      {
        key: "en",
        name: "English",
        label: "English",
      },
    ],
    calendarMonthFormat: "MMMM",
    timeFormat: "hh:mm A",
    flightShopCardType: FlightShopCardType.MINI,
  },
  fr: {
    contactSupport,
    merchantPayments: "",
    clientName: ClientName.HOPPER,
    portalName: "Hopper sur le Web",
    currency: {
      locale: "fr",
      code: Currency.USD,
      symbol: "$",
    },
    urls: {
      termsAndConditions:
        "https://www.hopper.com/fr/legal/terms-and-conditions/",
      privacyPolicy: "https://hopper.com/fr/legal/privacy-policy",
      priceFreezeTerms: "https://hopper.com/fr/legal/Air-Price-Freeze",
      missedConnectionTerms:
        "https://hopper.com/fr/legal/Missed-Connection-Guarantee",
    },
    preferredCountryCode: "US",
    preferredAreaCode: "+1",
    home: {
      // TODO - verify translations
      title: "<b>Bienvenue</b> chez Hopper",
      subtitle: "Réservez le voyage que vous attendiez.",
    },
    supportedLanguages: [
      {
        key: "es",
        name: "Español",
        label: "Español",
      },
      {
        key: "en",
        name: "English",
        label: "English",
      },
      { key: "fr", name: "Français", label: "Français" },
    ],
    calendarMonthFormat: "MMMM",
    timeFormat: "hh:mm A",
    flightShopCardType: FlightShopCardType.MINI,
  },
};
