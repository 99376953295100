import clsx from "clsx";
import dayjs from "dayjs";
import Airplane from "../../../../assets/icons/flight/airplane.svg";
import { useI18nContext } from "@hopper-b2b/i18n";

interface IFlightTimingProps {
  departureTime: string;
  arrivalTime: string;
  className?: string;
  timeFormat?: string;
  plusDays?: number;
}

const DEFAULT_TIME_FORMAT = "h:mm A";

const formatTime = (t: string, timeFormat?: string) =>
  dayjs(t).format(timeFormat || DEFAULT_TIME_FORMAT);

export const FlightTiming = ({
  departureTime,
  arrivalTime,
  className,
  timeFormat,
  plusDays,
}: IFlightTimingProps) => {
  const { t } = useI18nContext();
  const departureTimeFormatted = formatTime(departureTime, timeFormat);
  const arrivalTimeFormatted = formatTime(arrivalTime, timeFormat);

  return (
    <div className={clsx("other-timing", className)}>
      {departureTimeFormatted}
      <img className="airplane" src={Airplane} alt="airplane-icon" />
      {arrivalTimeFormatted}
      {plusDays !== 0 && (
        <span className="plus-days">
          {plusDays > 0
            ? t("plusDays", { plusDays })
            : t("minusDays", { minusDays: Math.abs(plusDays) })}
        </span>
      )}
    </div>
  );
};
