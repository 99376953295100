import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Button, FormHelperText, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { OTPInput } from "../../lib/OTPInput";

export interface VerifyPhoneFormProps {
  phoneNumber: string;
  resendDisabled: boolean;
  errorText?: string;
  onVerify: ({ code }: { code: string }) => void;
  onResend: () => void;
}

export const VerifyPhoneForm = ({
  phoneNumber,
  resendDisabled,
  errorText,
  onVerify,
  onResend,
}: VerifyPhoneFormProps) => {
  const { t } = useI18nContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      code: "",
    },
  });
  return (
    <Stack>
      <Typography variant="headlineMedium" px={300}>
        {t("verifyPhoneTitle")}
      </Typography>
      <Stack p={300} gap={250}>
        <Stack direction="row" gap={100} alignItems="center">
          <Typography variant="titleMedium">
            {t("verifyCodeSubtitle", {
              emailOrPhone: phoneNumber,
            })}
          </Typography>
        </Stack>
        <Box>
          <Controller
            name="code"
            control={control}
            rules={{ minLength: 6, maxLength: 6, required: true }}
            render={({
              field: { ref, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <>
                <OTPInput
                  TextFieldsProps={{
                    inputProps: {
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    },
                  }}
                  length={6}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                />
                {(error || errorText) && (
                  <FormHelperText
                    variant="outlined"
                    error
                    sx={{
                      position: "relative",
                      fontSize: ({ tokens }) => tokens.typography.fontSize[2],
                    }}
                  >
                    {error?.type === "required" && t("auth.errors.required")}
                    {error?.type === "minLength" && t("auth.errors.minLength")}
                    {errorText && errorText}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Box>
        <Button onClick={handleSubmit(onVerify)}>{t("verify")}</Button>
        <Stack direction="row" gap={100} alignItems="center">
          <Typography variant="bodyMedium">{t("resendDescription")}</Typography>
          <Button
            variant="outlined"
            onClick={onResend}
            disabled={resendDisabled}
          >
            {t("resendCode")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
