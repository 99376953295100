import { trackEvent } from "@hopper-b2b/api";
import { AuthErrorContent as AuthErrorContentComponent } from "@hopper-b2b/ui-core";
import { getStringifiedTransitions } from "@hopper-b2b/utilities";
import { useEffect } from "react";
import {
  AuthEvents,
  AuthMachineEventType,
  getAuthType,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import {
  AuthFailureEvent,
  AuthTrackingEvents,
} from "../../../../types/tracking";
import { AuthPromptProps } from "../../component";
import "./styles.scss";

export const AuthErrorContent = ({
  onClose,
}: Pick<AuthPromptProps, "onClose">) => {
  const [state, send] = useAuthState<AuthEvents, unknown>();
  const authType = useAuthSelector(getAuthType);
  const onTryAgain = () => {
    send(AuthMachineEventType.TRY_AGAIN);
  };
  const continueWithoutLogin = () => {
    onClose();
  };

  useEffect(() => {
    trackEvent({
      eventName: AuthTrackingEvents.Failure,
      properties: {
        auth_type: authType ? authType : undefined,
      },
    } as AuthFailureEvent);
    // Logs to dd logger
    console.error(
      JSON.stringify({
        currentState: state.value,
        transitions: getStringifiedTransitions(state.transitions),
      })
    );
  }, []);

  return (
    <AuthErrorContentComponent
      onTryAgain={onTryAgain}
      onContinueWithoutLogin={continueWithoutLogin}
    />
  );
};
