import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiChip: Components["MuiChip"] = {
  styleOverrides: {
    root: {
      display: "flex",
      height: "32px",
      padding: `${tokens.spacing[50]}px calc(${tokens.spacing[150]}px - 4px) ${tokens.spacing[50]}px ${tokens.spacing[150]}px`,
      alignItems: "center",
      gap: tokens.spacing[50],
      borderRadius: tokens.cornerRadius.radiusMd,
      border: `1px solid ${tokens.component.input.borderFocused}`,
      background: tokens.surface.primaryTintResting,
      color: tokens.text.primary,
      fontSize: tokens.typography.fontSize[3],
      fontWeight: tokens.typography.fontWeight.regular,
      lineHeight: tokens.typography.fontLineHeight[3],
      letterSpacing: "0.14px",
      "& .MuiChip-label": {
        padding: 0,
      },
      "&:hover": {
        background: tokens.surface.primaryTintHover,
      },
      "&:active": {
        background: tokens.surface.primaryTintPressed,
      },
      "&:focus": {
        background: tokens.surface.primaryTintResting,
        outline: `2px solid ${tokens.component.input.borderFocused}`,
        outlineOffset: "2px",
      },
      "& .MuiChip-deleteIcon": {
        margin: 0,
        fontSize: "16px",
      },
    },
  },
};
