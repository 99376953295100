"use client";

import { FlightsFareSlice } from "@b2bportal/air-shopping-api";
import {
  AirportMap,
  FareDetails,
  FareId,
  TripDetails,
} from "@hopper-b2b/types";
import {
  getFilteredFareDetails,
  getSortedFareDetails,
} from "@hopper-b2b/utilities";
import { Box } from "@mui/material";
import clsx from "clsx";
import { TFunction } from "i18next";
import { MouseEvent, useCallback, useEffect, useState } from "react";

import "./styles.scss";
import FareDetailsItem from "./FareDetailsItem";
import SelectedFareRestrictions from "./SelectedFareRestrictions";
import { VirtualInterlineFareDetailsCard } from "../VirtualInterlineFareDetailsCard";

/**
 * Fare Details Card
 * @function FareDetailsCard
 *
 */
export interface IFareNotice {
  id: string;
  message: string;
  tooltipCopy: string;
}

export interface IFareDetailsCardProps {
  fareDetails: FareDetails[];
  getEmptyRestrictionsText: (
    fareRating: number | undefined,
    translate: TFunction
  ) => string;
  onFareSelect?: (fare: FareDetails) => void;
  inExchange?: boolean;
  className?: string;
  onAlgomerchClick?: (label: string) => void;
  selectedFare?: FareId;
  isOutgoing: boolean;
  isMobile?: boolean;
  fareNotice?: IFareNotice[];
  rewardsKey?: string;
  selectedFareClassFilters: Array<number>;
  selectedOutgoingFareSlice?: FlightsFareSlice;
  setClickedFareId?: (fareId: string) => void;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
  isVirtualInterlineItinerary?: boolean;
  airports?: AirportMap;
  tripDetails?: TripDetails;
}

export const MiniFareDetailsCard = ({
  className,
  selectedFare,
  fareDetails, // the length of this is the amt of fares we have
  inExchange,
  isOutgoing,
  onAlgomerchClick,
  isMobile,
  fareNotice,
  selectedFareClassFilters,
  selectedOutgoingFareSlice,
  getEmptyRestrictionsText = () => "",
  rewardsKey = "fake-account-reference-ID-1",
  setClickedFareId,
  onFareSelect,
  getFlightShopFareSlice,
  isVirtualInterlineItinerary,
  tripDetails,
  airports,
}: IFareDetailsCardProps) => {
  const [selectedFareDetails, setSelectedFareDetails] =
    useState<FareDetails | null>(null);
  const [selectedFareIndex, setSelectedFareIndex] = useState<number | null>(
    null
  );

  const [filteredFares, setFilterFares] = useState<FareDetails[]>([]);

  const getSelectedFareId = useCallback(() => {
    const fareExists = filteredFares.some((f) => f.id === selectedFare);
    const firstFareId = filteredFares[0]?.id;
    if (!firstFareId) return undefined;
    if (isOutgoing) {
      return fareExists ? selectedFare : firstFareId;
    } else {
      // check if outgoing is refundable, try to find matching return fare
      if (selectedOutgoingFareSlice?.fareBrandName?.includes("Refundable")) {
        const matchedFare = filteredFares.find(
          (fare) =>
            fare.slices[1]?.fareShelf?.brandName ===
            selectedOutgoingFareSlice?.fareBrandName
        );
        return matchedFare ? matchedFare.id : firstFareId;
      } else {
        return fareExists ? selectedFare : firstFareId;
      }
    }
  }, [
    filteredFares,
    isOutgoing,
    selectedFare,
    selectedOutgoingFareSlice?.fareBrandName,
  ]);

  useEffect(() => {
    if (
      selectedFare &&
      fareDetails &&
      selectedFareDetails === null &&
      selectedFareIndex === null
    ) {
      const onlyFilteredFares = getFilteredFareDetails({
        fareDetails,
        selectedFareClassFilters,
        isOutgoing,
        selectedFareId: getSelectedFareId(),
      });
      const filteredAndSortedFares = getSortedFareDetails(onlyFilteredFares);

      const selected = fareDetails.find(
        (fareDetail) => fareDetail.id === selectedFare
      );
      const selectedIndex = fareDetails.findIndex(
        (fareDetail) => fareDetail.id === selectedFare
      );

      setFilterFares(filteredAndSortedFares);
      setSelectedFareDetails(selected ? selected : fareDetails[0]);
      setSelectedFareIndex(selectedIndex);
    }
  }, [
    fareDetails,
    getSelectedFareId,
    isOutgoing,
    selectedFare,
    selectedFareClassFilters,
    selectedFareDetails,
    selectedFareIndex,
  ]);

  const onFareClicked = useCallback(
    (e: Event | MouseEvent, fare: FareDetails, index: number) => {
      onFareSelect && e.stopPropagation();
      setClickedFareId && setClickedFareId(fare.id);
      setSelectedFareDetails(fare);
      setSelectedFareIndex(index);
      onFareSelect && onFareSelect(fare);
    },
    [onFareSelect, setClickedFareId]
  );

  return (
    <Box
      className={clsx(
        "fare-details-card",
        "mini",
        { mobile: isMobile },
        className
      )}
    >
      {filteredFares.map((fare, index) => (
        <FareDetailsItem
          key={index + "." + fare.id}
          fare={fare}
          index={index}
          className={className}
          isOutgoing={isOutgoing}
          inExchange={inExchange}
          isMobile={isMobile}
          rewardsKey={rewardsKey}
          selectedFareDetails={selectedFareDetails}
          getFlightShopFareSlice={getFlightShopFareSlice}
          onAlgomerchClick={onAlgomerchClick}
          onFareClicked={onFareClicked}
        />
      ))}
      {selectedFareDetails && selectedFareIndex !== null ? (
        isVirtualInterlineItinerary ? (
          <VirtualInterlineFareDetailsCard
            tripDetails={tripDetails}
            isOutgoing={isOutgoing}
            airports={airports}
            displayRestrictionsOnly
            classes={{
              cardContainer: "virtual-interline-card-container-mobile",
            }}
            disableAirlineBackground
          />
        ) : (
          <SelectedFareRestrictions
            selectedFare={selectedFareDetails}
            allFareDetails={fareDetails}
            fareNotice={fareNotice}
            index={selectedFareIndex}
            isOutgoing={isOutgoing}
            isMobile={isMobile}
            getEmptyRestrictionsText={getEmptyRestrictionsText}
          />
        )
      ) : null}
    </Box>
  );
};
