import { axiosInstance } from "@hopper-b2b/api";
import {
  type CreatePersonalOfferRequest,
  type CreatePersonalOfferResponse,
  CreatePersonalOfferResponseResponseEnum,
  adsApi,
} from "@b2bportal/ads-api";

export const createPersonalOffer = async (
  request: CreatePersonalOfferRequest,
  abortSignal: AbortSignal
): Promise<CreatePersonalOfferResponse> => {
  return new Promise((resolve, reject) => {
    try {
      adsApi(axiosInstance)
        .apiV0AdsOffersCreatePost(request, { signal: abortSignal })
        .then((res) => {
          const response = res.data;

          switch (response.Response) {
            case CreatePersonalOfferResponseResponseEnum.Success:
              resolve(response.value);
              break;
            case CreatePersonalOfferResponseResponseEnum.Failure:
              reject(
                new Error(
                  "Server returned an error: " + response.errors.join(", ")
                )
              );
              break;
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      reject(e);
    }
  });
};
