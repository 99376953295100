import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiFormGroup: Components["MuiFormGroup"] = {
  styleOverrides: {
    root: {
      gap: tokens.spacing[200],
    },
  },
};
