import {
  AirRestrictionStatus,
  AmenitiesAndUtas,
  DullesAmenityCanExist,
  DullesUta,
} from "@hopper-b2b/types";
import { IRestrictionProps } from "@hopper-b2b/ui";

import { TFunction } from "i18next";

export const getFareDetailsRestrictions = ({
  restriction,
  t,
}: {
  restriction: AmenitiesAndUtas;
  t: TFunction;
}) => {
  const utas = restriction?.utas?.utas ?? [];
  const amenities = restriction?.amenities;
  const restrictions: IRestrictionProps[] = [];

  restrictions.push(
    ...utas.map((uta: DullesUta) => {
      const description = getUtaDescription(uta, t);
      const symbol = getUtaSymbol(uta);

      return {
        symbol,
        description,
        key: uta.category,
      };
    })
  );

  if (amenities) {
    restrictions.push(
      ...Object.keys(amenities).map((key) => {
        const amenitity: DullesAmenityCanExist = amenities?.[key];
        const symbol = amenitity.exists
          ? AirRestrictionStatus.INCLUDED
          : AirRestrictionStatus.GENERIC;
        return {
          symbol: symbol,
          description: `${t(`amenitiesCategories.${key}`)} - ${
            amenitity.info.displayText
          }`,
          key,
        };
      })
    );
  }
  return restrictions;
};

const getUtaSymbol = (uta: DullesUta) => {
  // overwrite advance changes to be non changeable
  if (uta.category === "advance-change")
    return AirRestrictionStatus.UNAVAILABLE;
  if (uta.assessment === "benefit") return AirRestrictionStatus.INCLUDED;
  if (uta.assessment === "fee") return AirRestrictionStatus.PURCHASABLE;
  return AirRestrictionStatus.UNAVAILABLE;
};

const getUtaDescription = (uta: DullesUta, t: TFunction) => {
  if (uta.category === "carry-on-allowance")
    return `${uta.headline ? `${uta.headline} -` : ""} ${uta.description}`;
  if (uta.category === "advance-change") return t("nonChangeable");
  return uta.description;
};

export const keysOrder = [
  "carry-on-allowance",
  "checked-bag-allowance",
  "advance-change",
  "cancellation",
];

export const sortFaresByKeys = (restrictions: IRestrictionProps[]) =>
  [...restrictions].sort((a, b) => {
    const indexA = keysOrder.indexOf(a?.key ?? "");
    const indexB = keysOrder.indexOf(b?.key ?? "");

    if (indexA === -1 && indexB === -1) {
      return 0; // If both keys are not in keysOrder, maintain the original order
    }
    if (indexA === -1) {
      return 1; // If key of 'a' is not in keysOrder, move 'a' to the end
    }
    if (indexB === -1) {
      return -1; // If key of 'b' is not in keysOrder, move 'b' to the end
    }
    return indexA - indexB; // Sort based on the indices of the keys in keysOrder
  });

export const MINIMUN_FARE_TO_SHOW = 4;
