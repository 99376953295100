import { Components, toggleButtonClasses } from "@mui/material";
import { tokens } from "../tokens";
import { typography } from "../typography";

export const MuiToggleButton: Components["MuiToggleButton"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      ...typography.labelMedium,
      color: tokens.text.primary,
      padding: tokens.spacing[150],
      borderRadius: "10px",
      border: "none",
      backgroundColor: "transparent",
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: tokens.surface.main,
        boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.15)`,
      },
    },
  },
};
