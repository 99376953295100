import ProximaNovaBoldOtf from "../assets/fonts/Proxima-Nova-Bold.otf";
import ProximaNovaExtraBoldOtf from "../assets/fonts/Proxima-Nova-Extrabold.otf";
import ProximaNovaMediumOtf from "../assets/fonts/Proxima-Nova-Medium.otf";
import ProximaNovaRegularOtf from "../assets/fonts/Proxima-Nova-Regular.otf";
import ProximaNovaSemiBoldOtf from "../assets/fonts/Proxima-Nova-Semibold.otf";
import globalStyles from "../global.module.css";
import { Components } from "@mui/material";

export const MuiScopedCssBaseline: Components["MuiScopedCssBaseline"] = {
  styleOverrides: {
    root: `
    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local("Proxima Nova Regular"), 
        url(https://cdn.hopper.com/fonts/proximanova-regular.otf) format("opentype"),
        url(${ProximaNovaRegularOtf}) format("opentype");
    }
    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: local("Proxima Nova Medium"), 
        url(https://cdn.hopper.com/fonts/proximanova-medium.otf) format("opentype"),
        url(${ProximaNovaMediumOtf}) format("opentype");
    }
    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-display: swap;
      src: local("Proxima Nova Semibold"), 
        url(https://cdn.hopper.com/fonts/proximanova-semibold.otf) format("opentype"),
        url(${ProximaNovaSemiBoldOtf}) format("opentype");
    }
    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local("Proxima Nova Bold"), 
        url(https://cdn.hopper.com/fonts/proximanova-bold.otf) format("opentype"),
        url(${ProximaNovaBoldOtf}) format("opentype");
    }
    @font-face {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 800;
      font-display: swap;
      src: local("Proxima Nova Extrabold"), 
        url(https://cdn.hopper.com/fonts/proximanova-extrabold.otf) format("opentype"),
        url(${ProximaNovaExtraBoldOtf}) format("opentype");
    }
    @property --button-background-1 {
      syntax: "<color>";
      inherits: false;
      initial-value: #FED955;
    }
    @property --button-background-2 {
      syntax: "<color>";
      inherits: false;
      initial-value: #FDBE33;
    }
    background-color: transparent;
    ${globalStyles}
  `,
  },
};
