import { I18nNamespace, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { phoneRegex } from "@hopper-b2b/utilities";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "../../lib/PhoneInput";

export interface LandingFormProps {
  loginContextHeader?: string;
  onPhoneClick: ({ phoneNumber }: { phoneNumber: string }) => void;
  GoogleButton: React.ReactNode;
}

export const LandingForm = ({
  loginContextHeader,
  onPhoneClick,
  GoogleButton,
}: LandingFormProps) => {
  const { t } = useI18nContext();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      phoneNumber: "",
    },
  });
  return (
    <Stack>
      <Typography variant="headlineMedium" px={300}>
        {loginContextHeader ?? t("signInOrRegister")}
      </Typography>
      <Stack p={300} gap={250}>
        {GoogleButton}
        <Stack direction="row" gap={100} alignItems="center">
          <Divider
            sx={({ tokens }) => ({
              color: tokens.surface.borderDivider,
              flex: 1,
            })}
          />
          <Typography
            sx={({ tokens }) => ({
              color: tokens.text.tertiary,
            })}
          >
            {t("or")}
          </Typography>
          <Divider
            sx={({ tokens }) => ({
              color: tokens.surface.borderDivider,
              flex: 1,
            })}
          />
        </Stack>
        <Stack gap={100}>
          <Box>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: t("contactInfoSection.form.phoneNumber.missing"),
                pattern: {
                  value: phoneRegex,
                  message: t("contactInfoSection.form.phoneNumber.invalid"),
                },
              }}
              render={({
                field: { ref, onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <PhoneInput
                  label={t("phoneNumber")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  helperText={t("signInHelperText")}
                  errorHelper={error?.message}
                  inputRef={ref}
                  error={!!error}
                />
              )}
            />
          </Box>
          <Button onClick={handleSubmit(onPhoneClick)}>{t("continue")}</Button>
        </Stack>
        <Typography variant="inputHelper">
          <Trans
            i18nKey="createAccountAgreeToTermsAndPolicy"
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={t(`${[I18nNamespace.brand]}:urls.termsAndConditions`)}
              />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href={t(`${[I18nNamespace.brand]}:urls.privacyPolicy`)} />,
            ]}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};
