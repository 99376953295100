"use client";

import { COLOR_THEMES, installColorConfig } from "@hopper-b2b/types";
import { getDarkModePreferred } from "@hopper-b2b/utilities";
import {
  Box,
  BoxProps,
  CssBaseline,
  ScopedCssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import {
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { hopperDarkVariables, muiDarkTheme } from "./darkTheme";
import { hopperVariables, muiTheme } from "./defaultTheme";
import { hdsTheme, hdsThemeOnPhoto, hdsThemeOnPrimary } from "./hdsTheme";
import { ColorMode } from "./types";

export type HopperThemingProviderProps = {
  enableDarkMode?: boolean;
  enableHDSTheme?: boolean;
  colorMode?: ColorMode;
};

export const HopperThemingProvider = forwardRef(
  (
    {
      children,
      enableDarkMode,
      enableHDSTheme,
      colorMode = "default",
      ...rest
    }: PropsWithChildren<HopperThemingProviderProps> & BoxProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    let defaultTheme = { ...hdsTheme };
    switch (colorMode) {
      case "onPrimary":
        defaultTheme = hdsThemeOnPrimary;
        break;
      case "onPhoto":
        defaultTheme = hdsThemeOnPhoto;
        break;
      default:
        defaultTheme = hdsTheme;
    }
    const [theme, setTheme] = useState(
      enableHDSTheme ? defaultTheme : muiTheme
    );
    const [isDarkMode, setIsDarkMode] = useState(false);

    const isDarkModePreferred = useMemo(() => {
      const colorScheme = getDarkModePreferred()
        ? COLOR_THEMES.DARK
        : COLOR_THEMES.LIGHT;
      return isDarkMode && colorScheme === COLOR_THEMES.DARK;
    }, [isDarkMode]);

    useEffect(() => {
      if (enableDarkMode) {
        setIsDarkMode(true);
      }
    }, [enableDarkMode]);

    useEffect(() => {
      if (isDarkModePreferred) {
        setTheme(muiDarkTheme);
      }
    }, [isDarkMode, isDarkModePreferred]);

    useEffect(() => {
      installColorConfig(
        isDarkModePreferred ? hopperDarkVariables : hopperVariables
      );
    }, [isDarkModePreferred]);

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {enableHDSTheme ? (
            <Box component={ScopedCssBaseline} {...rest} ref={ref}>
              {children}
            </Box>
          ) : (
            <>
              <CssBaseline />
              {children}
            </>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
);
