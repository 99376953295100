import {
  TrackClickRequest,
  TrackClickResponseResponse,
  TrackClickResponseResponseEnum,
  adsApi,
} from "@b2bportal/ads-api";
import { axiosInstance } from "@hopper-b2b/api";

export const trackClick = async (
  request: TrackClickRequest,
  abortSignal: AbortSignal
): Promise<TrackClickResponseResponse> => {
  return new Promise((resolve, reject) => {
    try {
      adsApi(axiosInstance)
        .apiV0AdsTrackClickPost(request, {
          signal: abortSignal,
        })
        .then((res) => {
          const response = res.data;

          switch (response.Response) {
            case TrackClickResponseResponseEnum.Success:
              resolve(response);
              break;
            case TrackClickResponseResponseEnum.Failure:
              reject(
                new Error(
                  "Server returned an error: " + response.errors.join(", ")
                )
              );
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      reject(e);
    }
  });
};
