import { axiosInstance } from "@hopper-b2b/api";
import { AxiosError, AxiosResponse } from "axios";

const RETRY_NAMESPACE = "--HOPPER-RETRY--";
const MAX_RETRIES = 1;

interface RetryResult<T> {
  retryResult: T;
  originalResult: AxiosResponse | AxiosError;
}

export const retryAfter = <T>(
  promise: () => Promise<T>,
  error: AxiosError
): Promise<RetryResult<T>> => {
  const { config } = error;
  config[RETRY_NAMESPACE] ??= { retries: MAX_RETRIES };

  if (config[RETRY_NAMESPACE].retries > 0) {
    config[RETRY_NAMESPACE].retries--;
    return promise()
      .then((retryResult) =>
        axiosInstance(config).then((originalResult) => ({
          retryResult,
          originalResult,
        }))
      )
      .catch(() => Promise.reject(error));
  }

  return Promise.reject(error);
};

export const retry = (
  error: AxiosError
): Promise<AxiosError | AxiosResponse> => {
  const { config } = error;
  config[RETRY_NAMESPACE] ??= { retries: MAX_RETRIES };

  if (config[RETRY_NAMESPACE].retries > 0) {
    config[RETRY_NAMESPACE].retries--;
    return axiosInstance(config);
  }

  return Promise.reject(error);
};
