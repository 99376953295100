import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { LegalPageQuery } from "@marketing-site/api/urbana/contentful/queries/legal.query";
import { Break, SEO } from "@marketing-site/components/_contentful";
import { HeroLegal } from "@marketing-site/components/_contentful/hero/HeroLegal";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { theme } from "@marketing-site/styles";
import { ILegalPage } from "@marketing-site/types/legal.model";
import { RichTextToHtml } from "@marketing-site/utils";
import React from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";
import { Navigate } from "react-router-dom-v5-compat";
import styled from "styled-components";
import { LoadingPage } from "../Page/LoadingPage";
import Footer from "@whopper/footer";

export type ILegalProps = {
  locale?: string;
  "*"?: string;
};
export const Legal = () => {
  const pageData: ILegalProps = useParams();
  const location = useLocation();
  const locale = pageData.locale || "en";
  const path = location.pathname;
  const slug = pageData["*"] || "";
  const redirects = {
    "VI-missed-connection-guarantee": "Missed-Connection-Guarantee",
  };
  const lookupSlug = redirects[slug] || slug;
  const {
    data: contentfulData,
    error: contentfulError,
    isLoading: contentfulLoading,
  }: {
    data?: ILegalPage;
    error?: any;
    isLoading?: boolean;
  } = useFetchContentQuery({
    query: LegalPageQuery(lookupSlug, locale),
    space: "legal",
  });

  if (contentfulError) {
    return <Navigate to="/410" />;
  }

  return contentfulLoading || !contentfulData ? (
    <LoadingPage />
  ) : (
    <>
      <SEO
        sharedImage={contentfulData.metadataSharedImage}
        title={contentfulData.metadataTitle}
        locale={locale}
        canonical={path}
      />
      <NavigationBar contentfulNavProps={contentfulData.navigation} />
      {slug === "" ? (
        <BlankBody />
      ) : (
        <>
          <HeroLegal headline={contentfulData.headline} />
          <Break isLine={false} waveColor="gray" waveType="valley" />
          <Body>
            <Container>
              <Terms>{RichTextToHtml(contentfulData.content1.json)}</Terms>
            </Container>
          </Body>
        </>
      )}
      {contentfulData.navigation.footer && (
        <Footer {...contentfulData.navigation.footer} />
      )}
    </>
  );
};

const BlankBody = styled.div`
  width: 100vw;
  height: 40vh;
  padding: 4rem 0;
`;

const Body = styled.div`
  width: 100vw;
  background-color: ${theme.colors.grey[100]};
  padding: 4rem 0;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 1rem;
  position: relative;
`;

const Terms = styled.div`
  b {
    font-weight: 800;
  }
  p {
    font-size: 16px;
  }

  th {
    background: ${theme.colors.grey[200]};
  }
  td {
    border: 1px solid ${theme.colors.grey[400]};
    padding: 4px;
  }
  table {
    border: 1px solid ${theme.colors.grey[400]};
    width: 90%;
    margin: 1rem auto;
  }
`;
