import {
  GetSaleFunnelInfoResponse,
  hopperFlashSaleApi,
} from "@b2bportal/hopper-flash-sale-api";
import { axiosInstance } from "@hopper-b2b/api";

export interface GetSaleFunnelInfoRequest {
  latitude: number;
  longitude: number;
}

export const fetchUserSaleFunnel = async ({
  latitude,
  longitude,
}: GetSaleFunnelInfoRequest): Promise<GetSaleFunnelInfoResponse> => {
  return new Promise((resolve, reject) => {
    try {
      hopperFlashSaleApi(axiosInstance)
        .apiV0FlashSaleUserSaleFunnelGet({
          params: {
            latitude,
            longitude,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};
