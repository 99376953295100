import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { formatDateTime, removeTimezone } from "@hopper-b2b/utilities";
import { AlgomerchTag, TagType } from "@hopper-b2b/types";
import { Icon, IconName } from "../Icon";
import "./styles.scss";
import { AlgomerchTags } from "../AlgomerchTags";
import { RemoteAirlineIcon } from "../RemoteAirlineIcon";
import { useI18nContext } from "@hopper-b2b/i18n";

type ITags = {
  value: AlgomerchTag;
  type: TagType;
}[];

export interface IFlightShopRowProps {
  airlineCode: string;
  airlineName: string;
  arrivalTime: string;
  className?: string;
  currentPriceText: string;
  rewardsPriceText?: string;
  departureTime: string;
  destinationCode: string;
  duration: string;
  layoverString: string;
  onClickTag?: (label: string) => void;
  originCode: string;
  tags: ITags;
  tagsLimit?: number;
  onClick?: () => void;
  plusDays: number;
  additionalAirlinesCount?: number;
  hasSelfTransferLayover?: boolean;
}

export const FlightShopRow = (props: IFlightShopRowProps) => {
  const {
    airlineCode,
    airlineName,
    arrivalTime,
    className,
    currentPriceText,
    rewardsPriceText,
    departureTime,
    destinationCode,
    duration,
    layoverString,
    onClickTag,
    originCode,
    tags,
    tagsLimit,
    plusDays,
    hasSelfTransferLayover,
    additionalAirlinesCount,
    onClick,
  } = props;
  const { t } = useI18nContext();
  return (
    <Box className={clsx("flight-shop-row", className)} onClick={onClick}>
      <Box className={clsx("flight-shop-row-column", "airline-icon medium")}>
        {hasSelfTransferLayover ? (
          <Icon name={IconName.MultiPlanes} />
        ) : (
          <RemoteAirlineIcon
            size="medium"
            airlineCode={airlineCode}
            altText={airlineName}
          />
        )}
      </Box>
      <Box className={clsx("flight-shop-row-column", "flight-times")}>
        <Box className="flight-time-info">
          <Typography variant="body1" className="departure-time">
            {formatDateTime(removeTimezone(departureTime), "h:mm A")}
          </Typography>
          <Box className="flight-time-icon">
            <Icon name={IconName.B2BAirplaneIcon} ariaLabel="" />
            <Box className="flight-line" />
            <Box className="flight-line-circle" />
          </Box>
          <Typography variant="body1" className="arrival-time">
            {formatDateTime(removeTimezone(arrivalTime), "h:mm A")}
            {plusDays !== 0 && (
              <span className="plus-days">
                {plusDays > 0
                  ? t("plusDays", { plusDays })
                  : t("minusDays", { minusDays: Math.abs(plusDays) })}
              </span>
            )}
          </Typography>
        </Box>
      </Box>
      <Box className={clsx("flight-shop-row-column", "flight-details")}>
        <Box>
          <Typography variant="body1" className="airline-name">
            {airlineName}
            {additionalAirlinesCount && hasSelfTransferLayover ? (
              <span>{` +${additionalAirlinesCount}`}</span>
            ) : null}
          </Typography>
        </Box>
        {destinationCode ? (
          <Box>
            <Typography variant="body2" className="itinerary-code">
              {originCode} - {destinationCode}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body2" className="itinerary-code">
              {originCode}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={clsx("flight-shop-row-column", "duration")}>
        <Typography variant="body1" className="duration-time">
          {duration}
        </Typography>
        <Box>
          <Typography variant="body2" className="layover">
            {layoverString}
          </Typography>
        </Box>
      </Box>
      <Box className={clsx("flight-shop-row-column", "badges")}>
        <AlgomerchTags
          tags={tags}
          tagsLimit={tagsLimit}
          onClickTag={onClickTag}
        />
      </Box>
      <Box className={clsx("flight-shop-row-column", "price")}>
        <Typography variant="body1" className="price-value">
          {currentPriceText}
        </Typography>
        {rewardsPriceText && (
          <Typography variant="body2" className="rewards-value">
            {`/ ${rewardsPriceText}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
