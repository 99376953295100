"use client";

import { createContext, PropsWithChildren, useContext } from "react";

export type AdsContextProps = Record<string, never>;

export interface IAdsContextProvider {
  adsContext: AdsContextProps;
}

export const defaultAdsContext: AdsContextProps = {};

export const AdsContext = createContext<AdsContextProps>(defaultAdsContext);

export const useAdsContext = () => {
  const ctx = useContext(AdsContext);
  if (ctx === undefined)
    throw new Error(`must be used within a AdsContextProvider`);
  return { ...ctx };
};

export const AdsProvider = ({
  children,
  adsContext,
}: PropsWithChildren<IAdsContextProvider>) => {
  return (
    <AdsContext.Provider
      value={{
        ...adsContext,
      }}
    >
      {children}
    </AdsContext.Provider>
  );
};
