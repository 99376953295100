import {
  adsApi,
  GetDealTilesRequest,
  GetDealTilesResponseResponse,
} from "@b2bportal/ads-api";
import { axiosInstance } from "@hopper-b2b/api";

export const getDealTiles = async (
  request: GetDealTilesRequest
): Promise<GetDealTilesResponseResponse> => {
  return new Promise((resolve, reject) => {
    try {
      adsApi(axiosInstance)
        .apiV0AdsDealTilesPost(request)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};
