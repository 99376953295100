import {
  mobileModel,
  browserName,
  osName,
  osVersion,
  mobileVendor,
  browserVersion,
} from "react-device-detect";
import { getDeviceData } from "./getDeviceData";
import { getMobileAppPlatform, isHopperAppWebView } from "./mobileWebApp";

const B2B_USER_LOCATION_KEY = "b2bportal-user-location";
const getPlatform = () => {
  // In the hopper env ios & android is for the app
  // if (isIOS) return "c1";
  // if (isAndroid) return "m1";
  if (isHopperAppWebView()) {
    return getMobileAppPlatform();
  }
  return "w1";
};

export const getUserDeviceData = () => {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const userLocation = localStorage.getItem(B2B_USER_LOCATION_KEY);

  const getCountry = (userLocation: string, regionNames: Intl.DisplayNames) => {
    if (userLocation && regionNames) {
      try {
        return regionNames.of?.(userLocation);
      } catch (e) {
        if (e instanceof RangeError) return "";
      }
    }
    return "";
  };
  return {
    browserName,
    browser_version: browserVersion,
    $os: osName,
    $os_version: osVersion,
    platform: getPlatform(),
    deviceType: getDeviceData().type,
    device: mobileModel,
    $brand: mobileVendor,
    $manufacturer: mobileVendor,
    $model: mobileModel,
    country: getCountry(userLocation, regionNames),
  };
};
