import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiBreadcrumbs: Components["MuiBreadcrumbs"] = {
  styleOverrides: {
    separator: {
      marginLeft: tokens.spacing[150],
      marginRight: tokens.spacing[150],
    },
  },
};
