import { Components } from "@mui/material";
import { typography } from "../typography";
import { tokens } from "../tokens";

export const MuiFormControlLabel: Components["MuiFormControlLabel"] = {
  styleOverrides: {
    root: {
      margin: 0,
      gap: tokens.spacing[100],
      alignItems: "center",
    },
    label: {
      ...typography.labelMedium,
      flexGrow: 1,
    },
  },
};
