"use client";

import {
  AdsContextProps,
  AdsProvider,
  defaultAdsContext,
} from "@hopper-b2b/ads-api";
import { ExperimentsProvider } from "@hopper-b2b/experiments";
import {
  FlashSaleProvider,
  IFlashSaleContextProps,
  defaultFlashSaleContext,
} from "@hopper-b2b/flashsale";
import {
  IAxiosInterceptors,
  UnauthorizationProvider,
  useAuthSessionContext,
} from "@hopper-b2b/hopper-auth";
import {
  HopperThemingProvider,
  branding,
  tenantContext as defaultTenantContext,
} from "@hopper-b2b/hopper-theming";
import {
  apiConfig,
  defaultLanguage,
  getCurrentLanguage,
} from "@hopper-b2b/hopper-utils";
import I18nProvider, {
  Translation,
  TranslationLanguage,
  en_hopperTranslations,
  es_hopperTranslations,
  fr_hopperTranslations,
  getLang,
  setLang,
} from "@hopper-b2b/i18n";
import { ExperimentsType } from "@hopper-b2b/types";
import {
  FeatureFlagsSessionIdRefresher,
  ISSRContextProps,
  ITenantContextProps,
  SSRContextProvider,
  SessionContextProvider,
  TenantContextProvider,
  UserSourceProvider,
  defaultSSRContext,
  AuthProvider,
  useAuthProvider,
  AuthProviderProps,
} from "@hopper-b2b/utilities";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Provider } from "react-redux";
import { ConfigureStoreOptions, createStore } from "./store";
import { UserSettingsProvider } from "./UserSettingsProvider";

export const DEFAULT_LANG = "en-US";

export type HopperProvidersProps = IAxiosInterceptors &
  ConfigureStoreOptions & {
    tenantContext?: ITenantContextProps;
    ssrContext?: ISSRContextProps;
    flashSaleContext?: IFlashSaleContextProps;
    adsContext?: AdsContextProps;
    enableHDSTheme?: boolean;
    translationLanguage?: string;
    initialExperiments?: ExperimentsType;
    country?: string;
    loadingFallback?: React.ReactNode;
  };

export const HopperProviders = ({
  initState,
  ...rest
}: PropsWithChildren<HopperProvidersProps> & AuthProviderProps) => {
  return (
    <AuthProvider initState={initState}>
      <HopperProvidersInternal {...rest} />
    </AuthProvider>
  );
};

const HopperProvidersInternal = ({
  version,
  recaptchaActionKey,
  tenantContext,
  ssrContext = defaultSSRContext,
  flashSaleContext = defaultFlashSaleContext,
  adsContext = defaultAdsContext,
  enableHDSTheme,
  translationLanguage,
  initialExperiments,
  country,
  children,
  reducers,
  middlewares,
  loadingFallback,
}: PropsWithChildren<HopperProvidersProps>) => {
  const locale = getLang();

  const sessionContext = useAuthSessionContext();
  const userInfoContext = useAuthProvider();

  const [defaultLng, setDefaultLng] = useState<string>(getLang(DEFAULT_LANG));

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();

    setDefaultLng(currentLanguage);
    setLang(
      currentLanguage === defaultLanguage ? DEFAULT_LANG : currentLanguage
    );
  }, []);

  const tenantTranslation: Translation = useMemo(() => {
    return {
      [TranslationLanguage.en]: en_hopperTranslations,
      [TranslationLanguage.es]: es_hopperTranslations,
      [TranslationLanguage.fr]: fr_hopperTranslations,
    };
  }, []);

  const store = useMemo(
    () => createStore({ reducers, middlewares }),
    [middlewares, reducers]
  );
  setupListeners(store.dispatch);
  return (
    <Provider store={store}>
      <SSRContextProvider ssrContext={ssrContext} locale={locale}>
        <SessionContextProvider sessionContext={sessionContext}>
          <I18nProvider
            defaultLng={defaultLng}
            branding={branding}
            tenantTranslation={tenantTranslation}
            localeParam={translationLanguage}
          >
            <ExperimentsProvider
              apiConfig={apiConfig}
              isLoggedIn={!!sessionContext}
              initialExperiments={initialExperiments}
              isServerSideRendering={ssrContext.isServerSideRendering}
              loadingFallback={loadingFallback}
            >
              <FlashSaleProvider
                isLoggedIn={!!sessionContext}
                flashSaleContext={flashSaleContext}
                country={country}
              >
                <AdsProvider adsContext={adsContext}>
                  <UserSettingsProvider userInfoContext={userInfoContext}>
                    <HopperThemingProvider enableHDSTheme={enableHDSTheme}>
                      <TenantContextProvider
                        tenantContext={tenantContext || defaultTenantContext}
                      >
                        <UserSourceProvider>
                          <FeatureFlagsSessionIdRefresher>
                            <UnauthorizationProvider
                              version={version}
                              recaptchaActionKey={recaptchaActionKey}
                              loadingFallback={loadingFallback}
                            >
                              {children}
                            </UnauthorizationProvider>
                          </FeatureFlagsSessionIdRefresher>
                        </UserSourceProvider>
                      </TenantContextProvider>
                    </HopperThemingProvider>
                  </UserSettingsProvider>
                </AdsProvider>
              </FlashSaleProvider>
            </ExperimentsProvider>
          </I18nProvider>
        </SessionContextProvider>
      </SSRContextProvider>
    </Provider>
  );
};
