import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Button, Stack, Typography } from "@mui/material";

export interface AuthErrorContentProps {
  onTryAgain: () => void;
  onContinueWithoutLogin: () => void;
}

export const AuthErrorContent = ({
  onTryAgain,
  onContinueWithoutLogin,
}: AuthErrorContentProps) => {
  const { t } = useI18nContext();
  return (
    <Stack p={300} gap={250} alignItems="center">
      <Box
        component="img"
        src="https://cdn.hopper.com/ds/Bunny/Sad@2x.png"
        width="240px"
      />
      <Typography variant="headlineMedium">{t("loginErrorHeader")}</Typography>
      <Typography variant="titleMedium">{t("loginErrorSubheader")}</Typography>
      <Button onClick={onTryAgain}>{t("tryAgain")}</Button>
      <Button variant="outlined" onClick={onContinueWithoutLogin}>
        {t("signInContinue")}
      </Button>
    </Stack>
  );
};
