import { GetSessionInfoResponseEnum } from "@b2bportal/auth-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LoadingContent } from "@hopper-b2b/ui-core";
import { useEffect } from "react";
import { fetchSessionInfo } from "../../../../api";
import {
  AuthEvents,
  AuthMachineEventType,
  getAuthType,
  getPreviousUserTrackingToken,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import {
  AuthKind,
  AuthSuccessEvent,
  AuthTrackingEvents,
} from "../../../../types/tracking";
import { useLogin } from "../../../../utils";
import { AuthPromptProps } from "../../component";
import "./styles.scss";

export const SuccessModal = ({ onClose }: Pick<AuthPromptProps, "onClose">) => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();
  const authType = useAuthSelector(getAuthType);
  const { pathname } = window.location;
  const previousUserTrackingToken = useAuthSelector(
    getPreviousUserTrackingToken
  );

  const onLogin = useLogin();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await fetchSessionInfo();
        if (res.GetSessionInfoResponse === GetSessionInfoResponseEnum.Session) {
          trackEvent({
            eventName: AuthTrackingEvents.Success,
            properties: {
              auth_type: authType,
              kind: AuthKind.Unknown,
              page: pathname,
            },
            userOverride: previousUserTrackingToken,
          } as AuthSuccessEvent);
          onLogin(res.sessionInfo);
          // Adding a timeout to prevent the UI from flickering when the network call completes quickly
          // Adjust timing as needed
          setTimeout(onClose, 1000);
        } else {
          send(AuthMachineEventType.ERROR);
        }
      } catch (error) {
        console.error(error);
        send(AuthMachineEventType.ERROR);
      }
    };

    fetchSession();
  }, []);

  return <LoadingContent message={t("loggingIn")} />;
};
