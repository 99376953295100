import {
  RefreshSessionRequest,
  RefreshSessionResponseEnum,
  authApi,
} from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";
import { deleteRefreshToken, setRefreshToken } from "./localStorage";

export interface RefreshTokens {
  accessToken: string;
  refreshToken: string;
}
// Result type for the refresh token session.  "INVALID" indicates the
// session could not be refreshed, which is terminal.  This is distinct
// from transient errors which are represented by promise errors.
export type RefreshSessionResult = RefreshTokens | "INVALID";

export const refreshSession = async (
  request: RefreshSessionRequest
): Promise<RefreshSessionResult> => {
  try {
    const response = await authApi(axiosInstance).apiV0AuthSessionRefreshPut(
      request
    );
    const refreshToken = response.headers["hopper-refresh"] as string;
    const accessToken = response.headers["hopper-access"] as string;
    if (
      response.data.RefreshSessionResponse ===
        RefreshSessionResponseEnum.RefreshSessionSuccess &&
      refreshToken &&
      accessToken
    ) {
      setRefreshToken(refreshToken);
      return { refreshToken, accessToken };
    } else {
      deleteRefreshToken();
      return "INVALID";
    }
  } catch (error) {
    console.error("Refresh session error:", error);
    throw Error("Refresh session error");
  }
};
