"use client";

import { Components, ThemeOptions, createTheme } from "@mui/material";
import { breakpoints } from "./breakpoints";
import {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAutocomplete,
  MuiBreadcrumbs,
  MuiButton,
  MuiButtonOnPhoto,
  MuiButtonOnPrimary,
  MuiCheckbox,
  MuiCheckboxOnPhoto,
  MuiCheckboxOnPrimary,
  MuiChip,
  MuiContainer,
  MuiDialog,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiFormLabel,
  MuiIconButton,
  MuiIconButtonOnPhoto,
  MuiIconButtonOnPrimary,
  MuiLink,
  MuiMenu,
  MuiMenuItem,
  MuiPopover,
  MuiRadio,
  MuiRadioOnPhoto,
  MuiRadioOnPrimary,
  MuiScopedCssBaseline,
  MuiSelect,
  MuiSkeleton,
  MuiSwitch,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTypography,
} from "./components";
import { palette } from "./palette";
import { spacing } from "./spacing";
import { tokens } from "./tokens";
import { transitions } from "./transitions";
import { typography } from "./typography";

const components: Components = {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAutocomplete,
  MuiBreadcrumbs,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiContainer,
  MuiDialog,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiFormLabel,
  MuiIconButton,
  MuiLink,
  MuiMenu,
  MuiMenuItem,
  MuiPopover,
  MuiRadio,
  MuiScopedCssBaseline,
  MuiSelect,
  MuiSkeleton,
  MuiSwitch,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTypography,
};

const hdsThemeOptions: ThemeOptions = {
  palette,
  breakpoints,
  spacing,
  typography,
  transitions,
  components,
  tokens,
  colorMode: "default",
};

export const hdsTheme = createTheme(hdsThemeOptions);
export const hdsThemeOnPrimary = createTheme({
  ...hdsThemeOptions,
  colorMode: "onPrimary",
  palette: {
    ...palette,
    text: {
      ...palette.text,
      primary: tokens.primary.contrast,
    },
  },
  components: {
    ...components,
    MuiButton: MuiButtonOnPrimary,
    MuiCheckbox: MuiCheckboxOnPrimary,
    MuiIconButton: MuiIconButtonOnPrimary,
    MuiRadio: MuiRadioOnPrimary,
  },
});
export const hdsThemeOnPhoto = createTheme({
  ...hdsThemeOptions,
  colorMode: "onPhoto",
  palette: {
    ...palette,
    text: {
      ...palette.text,
      primary: tokens.primary.contrast,
    },
  },
  components: {
    ...components,
    MuiButton: MuiButtonOnPhoto,
    MuiCheckbox: MuiCheckboxOnPhoto,
    MuiIconButton: MuiIconButtonOnPhoto,
    MuiRadio: MuiRadioOnPhoto,
  },
});
