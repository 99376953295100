import { CreateSessionResponseEnum } from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";
import {
  createAnonymousSession,
  fetchSessionAndSetSession,
  useLogin,
} from "@hopper-b2b/hopper-auth";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useAuthProvider } from "@hopper-b2b/utilities";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { Root, RootProps } from "./Root";

export const RootWithAuth = (props: RootProps) => {
  const { language } = useI18nContext();

  const { state } = useAuthProvider();

  const onLogin = useLogin();

  const userInfo = state?.sessionInfo?.userInfo;

  const [hasSession, setHasSession] = useState(!!userInfo);

  const handleSession = useCallback(() => {
    if (userInfo) return;

    createAnonymousSession(language).then((res) => {
      if (
        res.CreateSessionResponse ===
        CreateSessionResponseEnum.CreateSessionSuccess
      ) {
        fetchSessionAndSetSession(onLogin);
      }
      setHasSession(true);
    });
  }, [hasSession, language, onLogin]);

  // Restart session if it times out
  const handleError = useCallback((error: AxiosError) => {
    if (error.response?.status === 401) {
      setHasSession(false);
    }
    return Promise.reject(error);
  }, []);

  useEffect(() => {
    const responseInterceptor = {
      instance: axiosInstance.interceptors.response.use(
        (req) => req,
        handleError
      ),
      global: axios.interceptors.response.use((req) => req, handleError),
    };

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor.instance);
      axios.interceptors.response.eject(responseInterceptor.global);
    };
  }, [handleError]);

  return <Root {...props} handleSession={handleSession} />;
};
