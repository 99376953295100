import { ChatProvider } from "./ChatProvider";

export enum BottomSheetContentEnum {
  Closed,
  ContactSupport,
  CSATFormResolved,
  CSATFormUnresolved,
}

export interface IChatContext {
  chatProvider: ChatProvider;
  supportId: string;
}
