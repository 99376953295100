import { useCallback, useState } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import { useI18nContext, useI18nCurrency, I18nMarkup } from "@hopper-b2b/i18n";
import {
  getEmptyRestrictionsText,
  layoverDetails,
  useDeviceTypes,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import {
  AirportMap,
  AirRestrictionStatus,
  AlgomerchTag,
  FareDetails,
  FlightTags,
  getTags,
  mapI18nAlgomerchText,
  TripDetails,
} from "@hopper-b2b/types";
import { FareScore, FlightsFareSlice } from "@b2bportal/air-shopping-api";
import styles from "./styles.module.scss";
import { ActionButton } from "@hopper-b2b/ui-core";
import { Tag } from "../Tag";
import { isEmpty } from "lodash-es";

import { RemoteAirlineIcon } from "../RemoteAirlineIcon";
import { LayoverDetails } from "./types";
import {
  getFareDetailsRestrictions,
  MINIMUN_FARE_TO_SHOW,
  sortFaresByKeys,
} from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";

interface Classes {
  cardContainer: string;
}
interface Props {
  isOutgoing: boolean;
  tripDetails?: TripDetails;
  onClick?: (fareDetails: FareDetails) => void;
  getFlightShopFareSlice?: (fareId: string) => FlightsFareSlice;
  onAlgomerchClick?: (label: string) => void;
  airports?: AirportMap;
  displayRestrictionsOnly?: boolean;
  disableAirlineBackground?: boolean;
  classes?: Classes;
}

const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className="icon-available"
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <IconComponent
      className="icon-paid"
      name={IconName.MoneyOutline}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <IconComponent
      className="icon-unavailable"
      name={IconName.XCircleFilled}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.GENERIC]: (
    <IconComponent
      className="icon-generic"
      name={IconName.Generic}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
};

function getLayoverDetailTitle(layover: LayoverDetails): JSX.Element {
  if (!layover) return null;
  return (
    <I18nMarkup
      tKey="virtualInterline.fareRestrictions.modal.layoverDetailsOriginDestination"
      replacements={{
        origin: `${layover.originCity} (${layover.originCode})`,
        destination: `${layover.destinationCity} (${layover.destinationCode})`,
      }}
    />
  );
}

export const VirtualInterlineFareDetailsCard = ({
  tripDetails,
  isOutgoing,
  onClick,
  getFlightShopFareSlice = null,
  onAlgomerchClick,
  airports,
  displayRestrictionsOnly,
  classes,
  disableAirlineBackground = false,
}: Props) => {
  const { t } = useI18nContext();
  const tenantIcons = useTenantIcons();
  const { matchesMediumDesktopOnly } = useDeviceTypes();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { formatFiatCurrency } = useI18nCurrency();
  const fare = tripDetails.fareDetails[0];
  const fareIndex = fare.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );
  const fareSlice = fare.slices[fareIndex];
  const restrictions = fare?.restrictions[isOutgoing ? 0 : 1];
  const shopFareSlice = getFlightShopFareSlice
    ? getFlightShopFareSlice(fare.id)
    : "";
  const getTagText = useCallback(
    (fareScore: FareScore | FlightTags) => {
      const tagLimit = 2;
      const tagSeperator = ", ";
      const tags = getTags(fareScore);
      const filteredTags = tags
        .filter((tag: AlgomerchTag) => tag !== AlgomerchTag.Fastest)
        .map((tag: AlgomerchTag) => t(mapI18nAlgomerchText[tag]))
        .slice(0, tagLimit);
      return filteredTags.join(tagSeperator);
    },
    [t]
  );
  const tagText = shopFareSlice ? getTagText(shopFareSlice.tags) : "";
  const paxPricing = fare.paxPricings[0].pricing;
  const totalPriceString = formatFiatCurrency(
    {
      value: paxPricing.baseAmount.fiat.value + paxPricing.taxAmount.fiat.value,
      currencyCode: paxPricing.baseAmount.fiat.currencyCode,
    },
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );
  const placeholderRestriction = getEmptyRestrictionsText(
    fare?.slices[isOutgoing ? 0 : 1].fareShelf?.rating,
    t
  );
  const segmentsDetails: LayoverDetails[] = layoverDetails({
    tripDetails,
    airports,
    isOutgoing,
  });
  const handlePopoverOpen = (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className={clsx(
        styles.virtualInterlineCard,
        "fare-card",
        classes?.cardContainer,
        {
          [styles.mediumDesktop]:
            matchesMediumDesktopOnly || displayRestrictionsOnly,
        }
      )}
      p={2}
    >
      {!matchesMediumDesktopOnly && !displayRestrictionsOnly && (
        <img
          width={115}
          src={tenantIcons.fareRatings[fareSlice.fareShelf.rating]}
          alt={fareSlice.fareShelf.brandName}
        />
      )}

      <div className={styles.amenitiesAndTitles}>
        {!displayRestrictionsOnly && (
          <div className={styles.titles}>
            <div className={styles.fareName}>
              <Typography variant="h5">
                {fareSlice.fareShelf?.shortBrandName}
              </Typography>
              {tagText && (
                <div>
                  <Button className="algomerch-button-tag" size="small">
                    <Tag
                      className={styles.tags}
                      label={tagText}
                      onClick={() => onAlgomerchClick?.(tagText)}
                    />
                  </Button>
                </div>
              )}
            </div>
            <ActionButton
              onClick={() => onClick(fare)}
              variant="contained"
              size="medium"
              message={t?.("continueFor", { price: totalPriceString })}
            />
          </div>
        )}
        {/* decouple this to its own component */}
        <Box className={styles.faresSliceDetails}>
          <Box className={styles.restrictions}>
            {isEmpty(restrictions.amenitiesAndUtas)
              ? placeholderRestriction
              : restrictions?.amenitiesAndUtas.map(
                  (restriction, index: number) => {
                    const layoverDetailsTitle = getLayoverDetailTitle(
                      segmentsDetails[index]
                    );
                    const sortedRestrctions = sortFaresByKeys(
                      getFareDetailsRestrictions({ restriction, t })
                    );
                    const first = sortedRestrctions.slice(
                      0,
                      MINIMUN_FARE_TO_SHOW
                    );
                    const rest = sortedRestrctions.slice(MINIMUN_FARE_TO_SHOW);
                    if (!layoverDetailsTitle) return null;
                    return (
                      <Box key={index} className={styles.container}>
                        <Box className={styles.fareDetailsSegmentContainer}>
                          <Typography
                            variant="body2"
                            className={styles.layoverTitle}
                          >
                            {layoverDetailsTitle}
                          </Typography>
                          <Box
                            className={styles.fareDetailsSegmentAirlineDetails}
                          >
                            <div
                              className={clsx(styles.container, {
                                [styles.noBackground]: disableAirlineBackground,
                              })}
                            >
                              <RemoteAirlineIcon
                                size="small"
                                className={styles.airlineIcon}
                                airlineCode={
                                  segmentsDetails[index]?.airlineCode
                                }
                              />
                              <Typography
                                className={styles.airlineName}
                                variant="caption"
                              >
                                {segmentsDetails[index]?.airlineName}
                              </Typography>
                            </div>
                          </Box>
                        </Box>
                        {!first.length
                          ? placeholderRestriction
                          : first.map(({ description, symbol }) => (
                              <div
                                key={`${description}-${index}`}
                                className={styles.fareDetailsRestriction}
                              >
                                <span className={styles.icon}>
                                  {restrictionIcon[symbol]}
                                </span>
                                <Typography
                                  variant="body2"
                                  className={styles.restriction}
                                >
                                  {description}
                                </Typography>
                              </div>
                            ))}
                        {rest.length > 0 && (
                          <Typography
                            aria-owns={
                              anchorEl &&
                              anchorEl.id === `trip-fare-details-${index}`
                                ? "mouse-over-popover"
                                : undefined
                            }
                            aria-haspopup="true"
                            id={`trip-fare-details-${index}`}
                            className={styles.showMore}
                            variant="h6"
                            onClick={handlePopoverOpen}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handlePopoverOpen(e);
                              }
                            }}
                            tabIndex={0}
                          >
                            {t(
                              "virtualInterline.fareRestrictions.modal.additionalFareRestrictions",
                              {
                                count: rest.length,
                              }
                            )}
                          </Typography>
                        )}
                        <Popover
                          onMouseLeave={handlePopoverClose}
                          id="mouse-over-popover"
                          className={styles.additionalAmenitiesContainer}
                          onClose={handlePopoverClose}
                          disableEnforceFocus
                          open={
                            !!anchorEl &&
                            anchorEl.id === `trip-fare-details-${index}`
                          }
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box
                            className={styles.additionalAmenities}
                            onMouseLeave={handlePopoverClose}
                          >
                            {rest.map(({ description, symbol }, idx) => (
                              <div
                                key={`${description}-${idx}`}
                                className={styles.fareDetailsRestriction}
                              >
                                <span className={styles.icon}>
                                  {restrictionIcon[symbol]}
                                </span>
                                <Typography
                                  variant="body2"
                                  className={styles.restriction}
                                >
                                  {description}
                                </Typography>
                              </div>
                            ))}
                          </Box>
                        </Popover>
                      </Box>
                    );
                  }
                )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};
