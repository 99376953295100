import { Box, Components, radioClasses } from "@mui/material";
import { tokens } from "../tokens";
import { transitions } from "../transitions";

const Check = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <rect width="22" height="22" rx="11" fill="currentColor" />
    <rect x="7" y="7" width="8" height="8" rx="4" fill="white" />
  </svg>
);
export const MuiRadio: Components["MuiRadio"] = {
  defaultProps: {
    disableRipple: true,
    icon: <Box component="span" />,
    checkedIcon: Check,
  },
  styleOverrides: {
    root: {
      padding: 0,
      backgroundColor: "transparent",
      color: "transparent",
      border: `1px solid ${tokens.component.control.borderResting}`,
      width: "22px",
      height: "22px",
      borderRadius: "50px",
      transition: `background-color ${transitions.duration.short}ms ${transitions.easing.easeInOut}, box-shadow ${transitions.duration.short}ms ${transitions.easing.easeInOut}, border-color ${transitions.duration.short}ms ${transitions.easing.easeInOut}, color ${transitions.duration.short}ms ${transitions.easing.easeInOut}`,
      "&:has(input:hover)": {
        backgroundColor: tokens.component.control.surfaceHover,
      },
      "&:has(input:focus-visible)": {
        boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
      },
      "&:has(input:active)": {
        backgroundColor: tokens.component.control.surfacePressed,
      },
      "&:has(input:disabled)": {
        backgroundColor: tokens.component.control.surfaceDisabled,
        border: `1px solid ${tokens.component.control.borderDisabled}`,
      },
      [`&.${radioClasses.checked}`]: {
        color: tokens.primary.main,
        border: "none",
        "&:has(input:hover)": {
          color: tokens.surface.primaryHover,
        },
        "&:has(input:active)": {
          color: tokens.surface.primaryPressed,
        },
        "&:has(input:disabled)": {
          color: tokens.component.control.surfaceDisabled,
          border: `1px solid ${tokens.component.control.borderDisabled}`,
        },
      },
    },
  },
  variants: [
    {
      props: { color: "onPrimary" },
      style: {
        border: `1px solid ${tokens.primary.contrast}`,
        "&:has(input:hover)": {
          backgroundColor: tokens.surface.primaryHover,
        },
        "&:has(input:active)": {
          backgroundColor: tokens.surface.primaryPressed,
        },
        [`&.${radioClasses.checked}`]: {
          border: `1px solid ${tokens.primary.contrast}`,
          "&:has(input:focus-visible)": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px ${tokens.primary.contrast}`,
          },
        },
      },
    },
    {
      props: { color: "onPhoto" },
      style: {
        border: `1px solid ${tokens.primary.contrast}`,
        "&:has(input:hover)": {
          backgroundColor: tokens.surface.stateOverlay.hover,
        },
        "&:has(input:active)": {
          backgroundColor: tokens.surface.stateOverlay.pressed,
        },
        [`&.${radioClasses.checked}`]: {
          color: "transparent",
          border: `1px solid ${tokens.primary.contrast}`,
          "&:has(input:hover)": {
            color: tokens.surface.stateOverlay.hover,
          },
          "&:has(input:focus-visible)": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px ${tokens.primary.contrast}`,
          },
          "&:has(input:active)": {
            color: tokens.surface.stateOverlay.pressed,
          },
        },
      },
    },
  ],
};

export const MuiRadioOnPrimary: Components["MuiRadio"] = {
  ...MuiRadio,
  defaultProps: {
    ...MuiRadio.defaultProps,
    color: "onPrimary",
  },
};

export const MuiRadioOnPhoto: Components["MuiRadio"] = {
  ...MuiRadio,
  defaultProps: {
    ...MuiRadio.defaultProps,
    color: "onPhoto",
  },
};
