import { autocompleteClasses, Components } from "@mui/material";
import { tokens } from "../tokens";
import { typography } from "../typography";

export const MuiAutocomplete: Components["MuiAutocomplete"] = {
  styleOverrides: {
    listbox: {
      padding: `16px ${tokens.container.paddingFluidAlt}`,
      [`& .${autocompleteClasses.option}`]: {
        "&.Mui-focused": {
          backgroundColor: tokens.component.input.surfaceHover,
        },
        [`&[aria-selected="true"], &.Mui-focused[aria-selected="true"]`]: {
          backgroundColor: tokens.component.input.surfacePressed,
        },
      },
    },
    option: {
      padding: `${tokens.spacing[100]}px 24px`,
    },
    paper: {
      borderRadius: tokens.cornerRadius.radiusXl,
      backgroundColor: tokens.surface.main,
      boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.25)",
    },
    popper: {
      paddingTop: `${tokens.spacing[150]}px`,
    },
    noOptions: {
      ...typography.labelLarge,
    },
  },
};
