import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import clsx, { ClassValue } from "clsx";

import { Icon, IconName } from "../Icon";

import "./styles.scss";
import { noop } from "@hopper-b2b/utilities";

export enum BannerSeverity {
  ERROR = "error",
  IMPROVEMENT = "improvement",
  INFO = "info",
  NOTICE = "notice",
  SUCCESS = "success",
  WARNING = "warning",
}

export interface INotificationBannerProps {
  className?: ClassValue;
  content?: JSX.Element;
  cta?: ReactNode;
  hideIcon?: boolean;
  html?: string;
  label?: string;
  severity?: BannerSeverity;
  strongLabel?: string;
  onClick?: () => void;
}

const defaultProps: Partial<INotificationBannerProps> = {
  hideIcon: false,
  severity: BannerSeverity.INFO,
};

const NotificationBanner = (props: INotificationBannerProps): JSX.Element => {
  const {
    className,
    content,
    cta,
    hideIcon,
    html,
    label,
    severity,
    strongLabel,
    onClick = noop,
  } = props;
  const iconName: IconName = !hideIcon ? getIconName() : null;

  function getIconName() {
    switch (severity) {
      case BannerSeverity.ERROR:
        return IconName.ErrorAlert;
      case BannerSeverity.IMPROVEMENT:
      case BannerSeverity.INFO:
      case BannerSeverity.NOTICE:
        return IconName.InfoCircle;
      case BannerSeverity.WARNING:
        return IconName.WarningAlert;
      default:
    }
  }

  return (
    <Box
      className={clsx("notification-banner", className, severity)}
      role="alert"
      onClick={onClick}
    >
      {iconName && (
        <Box className="notification-icon">
          <Icon name={iconName} />
        </Box>
      )}
      <Box className="notification-content">
        {content ? (
          content
        ) : html ? (
          <Typography
            className="label"
            variant="subtitle1"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : strongLabel ? (
          <Typography className="label" variant="subtitle1">
            <strong>{strongLabel}</strong> {label}
          </Typography>
        ) : (
          <Typography className="label" variant="subtitle1">
            {label}
          </Typography>
        )}
        {cta && <Box className="notification-cta">{cta}</Box>}
      </Box>
    </Box>
  );
};

NotificationBanner.defaultProps = defaultProps;

export default NotificationBanner;
