import { Components, toggleButtonGroupClasses } from "@mui/material";
import { tokens } from "../tokens";

export const MuiToggleButtonGroup: Components["MuiToggleButtonGroup"] = {
  styleOverrides: {
    root: {
      gap: tokens.spacing[50],
      padding: tokens.spacing[25],
      backgroundColor: tokens.background.main,
      borderRadius: tokens.cornerRadius.radiusLg,
      [`& .${toggleButtonGroupClasses.grouped}, & .${toggleButtonGroupClasses.firstButton}, & .${toggleButtonGroupClasses.middleButton}, & .${toggleButtonGroupClasses.lastButton},`]:
        {
          borderRadius: "10px",
          border: "0",
          margin: "0",
        },
    },
  },
};
