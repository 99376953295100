import { BreakpointsOptions, Breakpoints } from "@mui/material";
import { createBreakpoints } from "@mui/system";

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 375,
    md: 744,
    lg: 992,
    xl: 1200,
    mobile: 0,
    tablet: 744,
    desktop: 992,
  },
};

export const bp: Breakpoints = createBreakpoints(breakpoints);
