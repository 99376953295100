import AirplaneArrive from "./client/airplane-arrive.svg";
import AirplaneDepart from "./client/airplane-depart.svg";
import AirplaneDiagonal from "./client/airplane-diagonal.svg";
import AirplaneFintech from "./client/airplane-fintech.svg";
import Airplane from "./client/airplane.svg";
import Bell from "./client/bell.svg";
import BlackLock from "./client/black-lock.svg";
import CalendarStart from "./client/calendar-start.svg";
import CalendarEnd from "./client/calendar-end.svg";
import Card from "./client/card.svg";
import CarrotCoin from "./client/carrot-coin.svg";
import SilverCoin from "./client/silver-coin.svg";
import CheckmarkFilled from "./client/checkmark-filled.svg";
import CheckmarkSimple from "./client/checkmark-simple.svg";
import Chevron from "./client/chevron.svg";
import ChevronLeft from "./client/chevron-left.svg";
import Clock from "./client/clock.svg";
import ErrorIcon from "./client/error.svg";
import Filter from "./client/filter.svg";
import Flights from "./client/flights.svg";
import Guest from "./client/guest.svg";
import Hotels from "./client/hotels.svg";
import Info from "./client/info.svg";
import LocationMarker from "./client/location-marker.svg";
import Lock from "./client/lock.svg";
import LogoSmall from "./client/logo_small.svg";
import LogoWhite from "./client/logo-white.svg";
import MissedConnection from "./client/missed-connection.svg";
import MoneySolid from "./client/money-solid.svg";
import MoneyTransparent from "./client/money-outline-transparent.svg";
import OneWay from "./client/oneway.svg";
import Passenger from "./client/passenger.svg";
import PriceFreeze from "./client/price-freeze.svg";
import PhoneSolid from "./client/phone-solid.svg";
import RoundTrip from "./client/roundtrip.svg";
import Shield from "./client/shield.svg";
import Wallet from "./client/wallet.svg";
import WalletIcon from "./client/wallet-icon.svg";
import Avatar from "./client/avatar.svg";
import Bed from "./client/bed.svg";
import BunnyWithSuitcase from "./client/bunny-suitcase.svg";
import BunnyTicketRefundable from "./client/ticket-refundable.svg";
import BunnyHappy from "./client/hopper-bunny-happy.svg";
import Cfar from "./client/cfar.svg";
import Check from "./client/check.svg";
import Close from "./client/close.svg";
import FlightDelay from "./client/flight-delay.svg";
import GrayGuest from "./client/gray-guest.svg";
import GrayShieldX from "./client/gray-sheild-x.svg";
import InfoQuestion from "./client/info-question.svg";
import InfoWhite from "./client/info-white.svg";
import Radio from "./client/radio.svg";
import RadioSelected from "./client/radio-selected.svg";
import Star from "./client/star.svg";
import Baggage from "./client/baggage.svg";
import Seats from "./client/seats.svg";
import CustomerSupport from "./client/customerSupport.svg";
import Quote from "./client/quote.svg";
import BaggageChecked from "./client/baggage-checked.svg";
import FlightOutline from "./client/flight-outline.svg";
import SegmentIcon from "./client/segment-icon.svg";
import LocationSolidSlate60 from "./client/location-solid-slate60.svg";
import Union from "./client/union.svg";

// illustrations
import MissedConnectionBunny from "./client/missed-connection-bunny.svg";
import SelfTransferLayover from "./client/self-transfer-layover.svg";
import TripMcpProtection from "./client/trip-mcp-protection.svg";
import VirtualInterlineBunny from "./client/virtual-interline-bunny.svg";
import AccordionArrow from "./client/accordion-arrow.svg";

// import LogoIcon from "./b2b/hopper-bunny-logo.svg";
import GreenShieldCheck from "./client/green-shield-check.svg";
import Copy from "./client/copy.svg";
import GreenBolt from "./client/green-bolt.svg";
import Headset from "./client/headset.svg";
import BunniesClapping from "./client/bunnies-clapping.svg";
import AirplaneRight from "./client/airplane-right.svg";
import AirplaneLeft from "./client/airplane-left.svg";
import BunnyFallback from "./client/bunny-fallback.svg";

// Fare Classes
import BasicEconomyBunny from "./client/fareClasses/bunny-basic-economy.png";
import EconomyBunny from "./client/fareClasses/bunny-economy.png";
import PremiumEconomyBunny from "./client/fareClasses/bunny-premium-economy.png";
import BusinessBunny from "./client/fareClasses/bunny-business.png";
import FirstClassBunny from "./client/fareClasses/bunny-first-class.png";

// Dark Mode Icons
import AirplaneArriveWhite from "./client/darkMode/airplane-arrive-white.svg";
import AirplaneDepartWhite from "./client/darkMode/airplane-depart-white.svg";
import AirplaneWhite from "./client/darkMode/airplane-white.svg";
import BellWhite from "./client/darkMode/bell-white.svg";
import CalendarStartWhite from "./client/darkMode/calendar-start-white.svg";
import CalendarEndWhite from "./client/darkMode/calendar-end-white.svg";
import CardWhite from "./client/darkMode/card-white.svg";
import CheckmarkSimpleWhite from "./client/darkMode/checkmark-simple-white.svg";
import FilterWhite from "./client/darkMode/filter-white.svg";
import InfoGrey from "./client/darkMode/info-grey.svg";
import LocationMarkerWhite from "./client/darkMode/location-marker-white.svg";
import LockLargeWhite from "./client/darkMode/lock-large-white.svg";
import LockWhite from "./client/darkMode/lock-white.svg";
import OneWayWhite from "./client/darkMode/oneway-white.svg";
import PassengerWhite from "./client/darkMode/passenger-white.svg";
import PriceFreezeWhite from "./client/darkMode/price-freeze-white.svg";
import RoundTripWhite from "./client/darkMode/roundtrip-white.svg";
import ShieldWhite from "./client/darkMode/shield-white.svg";
import WalletWhite from "./client/darkMode/wallet-white.svg";
import ChevronWhite from "./client/darkMode/chevron-white.svg";
import ChevronLeftWhite from "./client/darkMode/chevron-left-white.svg";
import OneCircle from "./client/circleNumbers/1.svg";
import TwoCircle from "./client/circleNumbers/2.svg";
import ThreeCircle from "./client/circleNumbers/3.svg";
import FourCircle from "./client/circleNumbers/4.svg";
import FiveCircle from "./client/circleNumbers/5.svg";
import AlertGray from "./client/alert-outline.svg";
import InfoGrayOutline from "./client/info-outline.svg";
import SystemCheckGreen from "./client/system-check-green.svg";
import SystemDismissRed from "./client/system-dismiss-red.svg";

import { IIconSet } from "@hopper-b2b/utilities";

export const lightModeIcons: IIconSet = {
  avatar: Avatar,
  airplane: Airplane,
  airplaneArrive: AirplaneArrive,
  airplaneDepart: AirplaneDepart,
  airplaneDiagonal: AirplaneDiagonal,
  airplaneFintech: AirplaneFintech,
  bell: Bell,
  blackLock: BlackLock,
  calendarStartIcon: CalendarStart,
  calendarEndIcon: CalendarEnd,
  card: Card,
  carrotCoin: CarrotCoin,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimple,
  chevron: Chevron,
  chevronLeft: ChevronLeft,
  clock: Clock,
  error: ErrorIcon,
  filter: Filter,
  flights: Flights,
  guest: Guest,
  hotels: Hotels,
  info: Info,
  locationMarker: LocationMarker,
  lock: Lock,
  missedConnection: MissedConnection,
  money: {
    solid: MoneySolid,
    transparent: MoneyTransparent,
  },
  oneway: OneWay,
  passenger: Passenger,
  phone: {
    solid: PhoneSolid,
  },
  priceFreeze: PriceFreeze,
  roundtrip: RoundTrip,
  shield: Shield,
  wallet: Wallet,
  walletIcon: WalletIcon,
  logoSmall: LogoSmall,
  fareRatings: {
    0: BasicEconomyBunny,
    1: EconomyBunny,
    2: PremiumEconomyBunny,
    3: BusinessBunny,
    4: FirstClassBunny,
  },
  star: Star,
  bunnyWithSuitcase: BunnyWithSuitcase,
  bunnyTicketRefundable: BunnyTicketRefundable,
  bunnyHappy: BunnyHappy,
  bed: Bed,
  close: Close,
  grayGuest: GrayGuest,
  grayShieldX: GrayShieldX,
  infoWhite: InfoWhite,
  // logoIcon: LogoIcon,
  greenShieldCheck: GreenShieldCheck,
  copy: Copy,
  cfar: Cfar,
  check: Check,
  flightDelay: FlightDelay,
  infoQuestion: InfoQuestion,
  vipIcon: GreenBolt,
  headset: Headset,
  radio: Radio,
  radioSelected: RadioSelected,
  logoWhite: LogoWhite,
  oneCircle: OneCircle,
  twoCircle: TwoCircle,
  threeCircle: ThreeCircle,
  fourCircle: FourCircle,
  fiveCircle: FiveCircle,
  alertGray: AlertGray,
  infoGrayOutline: InfoGrayOutline,
  systemCheckGreen: SystemCheckGreen,
  systemDismissRed: SystemDismissRed,
  bunniesClapping: BunniesClapping,
  airplaneRight: AirplaneRight,
  airplaneLeft: AirplaneLeft,
  bunnyFallback: BunnyFallback,
  silverCoin: SilverCoin,
  missedConnectionBunny: MissedConnectionBunny,
  selfTransferLayover: SelfTransferLayover,
  tripMcpProtection: TripMcpProtection,
  virtualInterlineBunny: VirtualInterlineBunny,
  accordionArrow: AccordionArrow,
  baggageIcon: Baggage,
  seatIcon: Seats,
  customerSupportIcon: CustomerSupport,
  quote: Quote,
  baggageCheckedIcon: BaggageChecked,
  flightOutlineIcon: FlightOutline,
  segmentIcon: SegmentIcon,
  locationSolidSlate60: LocationSolidSlate60,
  union: Union,
};

export const darkModeIcons: IIconSet = {
  ...lightModeIcons,
  airplane: AirplaneWhite,
  airplaneArrive: AirplaneArriveWhite,
  airplaneDepart: AirplaneDepartWhite,
  airplaneFintech: AirplaneFintech,
  bell: BellWhite,
  blackLock: LockWhite,
  calendarStartIcon: CalendarStartWhite,
  calendarEndIcon: CalendarEndWhite,
  card: CardWhite,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimpleWhite,
  chevron: ChevronWhite,
  clock: Clock,
  error: ErrorIcon,
  filter: FilterWhite,
  flights: Flights,
  guest: Guest,
  hotels: Hotels,
  info: InfoGrey,
  locationMarker: LocationMarkerWhite,
  lock: LockLargeWhite,
  missedConnection: MissedConnection,
  oneway: OneWayWhite,
  passenger: PassengerWhite,
  priceFreeze: PriceFreezeWhite,
  roundtrip: RoundTripWhite,
  shield: ShieldWhite,
  wallet: WalletWhite,
  chevronLeft: ChevronLeftWhite,
  logoWhite: LogoWhite,
};
