"use client";

import { CreateSessionResponseEnum } from "@b2bportal/auth-api";
import {
  AuthPrompt,
  AuthPromptProps,
  UserMenu,
  createAnonymousSession,
  fetchSessionAndSetSession,
  useLogin,
  useLogout,
} from "@hopper-b2b/hopper-auth";
import { PATH_HOME, PATH_PROFILE, PATH_TRIPS } from "@hopper-b2b/hopper-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { HappyBunny } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useAuthProvider,
  useCloseAuthModal,
  useIsExtraSmallScreen,
  useSessionContext,
  useToggleAuthModal,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo, useState } from "react";
import SignInHopperAvatar from "../assets/icons/signin-bunny-avatar.png";
import { Root, RootProps } from "./Root";
import { useSupportChatbot } from "@hopper-b2b/chat";

const googleIdentityClientId = getEnvVariables(
  "googleIdentityClientId"
)?.toString();

export type RootWithAuthProps = RootProps &
  Omit<AuthPromptProps, "open" | "onClose">;

export const RootWithAuth = ({
  googleIdentityClientId: googleIdentityClientIdProp,
  isPhoneAuthEnabled,
  isGoogleSsoAuthEnabled,
  ...rest
}: RootWithAuthProps) => {
  const { t, language } = useI18nContext();

  const { state } = useAuthProvider();

  const { isLoginModalOpen } = useSessionContext();
  const toggleAuthModal = useToggleAuthModal();
  const closeAuthModal = useCloseAuthModal();
  const supportChatbot = useSupportChatbot();

  const onLogin = useLogin();
  const onLogout = useLogout();

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = !!anchorEl;

  const isExtraSmallScreen = useIsExtraSmallScreen();

  const isSignedIn = state?.sessionInfo?.userScope?.isSignedIn;
  const userInfo = state?.sessionInfo?.userInfo;

  const onTogglePrompt = useCallback(() => {
    if (!userInfo) {
      // Need to create Anonymous Session before opening modal
      createAnonymousSession(language).then((res) => {
        if (
          res.CreateSessionResponse ===
          CreateSessionResponseEnum.CreateSessionSuccess
        ) {
          fetchSessionAndSetSession(onLogin);
        }
        toggleAuthModal();
      });
    } else {
      toggleAuthModal();
    }
  }, [language, onLogin, toggleAuthModal, userInfo]);

  const onToggleMenu = useCallback(
    (event) => {
      setAnchorEl(isMenuOpen ? null : event.currentTarget);
    },
    [isMenuOpen]
  );

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const rightActions = useMemo(() => {
    if (isExtraSmallScreen) {
      return isSignedIn
        ? [
            {
              text: t?.("myProfile"),
              link: `${PATH_HOME}${PATH_PROFILE}`,
            },
            {
              text: t?.("myTrips"),
              link: `${PATH_HOME}${PATH_TRIPS}`,
            },
            {
              text: t?.("getHelp"),
              onClick: () => supportChatbot?.toggleSupportChatbot(),
            },
            {
              text: t?.("signOut"),
              onClick: onLogout,
            },
          ]
        : [
            {
              text: t?.("signIn"),
              onClick: onTogglePrompt,
            },
          ];
    }
    return [
      {
        text: isSignedIn
          ? `${userInfo?.firstName} ${userInfo?.lastName}`
          : t?.("signIn"),
        onClick: isSignedIn ? onToggleMenu : onTogglePrompt,
        icon: (
          <img
            src={isSignedIn ? HappyBunny : SignInHopperAvatar}
            alt=""
            height={32}
            width={32}
          />
        ),
      },
    ];
  }, [
    isExtraSmallScreen,
    isSignedIn,
    onLogout,
    onToggleMenu,
    onTogglePrompt,
    t,
    userInfo?.firstName,
    userInfo?.lastName,
  ]);

  return (
    <>
      <Root {...rest} rightActions={rightActions} />
      <AuthPrompt
        open={isLoginModalOpen}
        onClose={closeAuthModal}
        googleIdentityClientId={
          googleIdentityClientIdProp || googleIdentityClientId
        }
        isPhoneAuthEnabled={isPhoneAuthEnabled}
        isGoogleSsoAuthEnabled={isGoogleSsoAuthEnabled}
      />
      {isMenuOpen && (
        <UserMenu
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          onLogout={onLogout}
        />
      )}
    </>
  );
};
