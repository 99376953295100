import { Components, menuItemClasses } from "@mui/material";
import { bp } from "../breakpoints";
import { tokens } from "../tokens";
import { typography } from "../typography";

export const MuiMenuItem: Components["MuiMenuItem"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      ...typography.inputValue,
      display: "flex",
      justifyContent: "space-between",
      color: tokens.text.secondary,
      padding: `${tokens.spacing[100]}px ${tokens.spacing[250]}px ${tokens.spacing[100]}px ${tokens.spacing[150]}px`,
      [bp.up("mobile")]: {
        minHeight: "58px",
      },
      whiteSpace: "normal",

      "&:hover": {
        backgroundColor: tokens.component.input.surfaceHover,
      },

      [`&.${menuItemClasses.selected}, &.${menuItemClasses.selected}:hover`]: {
        backgroundColor: tokens.component.input.surfacePressed,
        "&:after": {
          content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7703 5.84182C16.7011 5.67639 16.5998 5.52609 16.4721 5.3995C16.3444 5.27285 16.1928 5.17238 16.0259 5.10383C15.8591 5.03528 15.6803 5 15.4996 5C15.319 5 15.1402 5.03528 14.9733 5.10383C14.8065 5.17238 14.6549 5.27285 14.5272 5.3995L8.16434 11.7081L5.46911 9.03587C5.21088 8.7817 4.86159 8.63936 4.49772 8.64002C4.13386 8.64067 3.78509 8.78428 3.5278 9.03937C3.27051 9.29447 3.12567 9.64027 3.125 10.001C3.12434 10.3618 3.26791 10.7081 3.52426 10.9641L7.19191 14.6005C7.31959 14.7271 7.47118 14.8276 7.63804 14.8962C7.80489 14.9647 7.98373 15 8.16434 15C8.34495 15 8.52379 14.9647 8.69064 14.8962C8.85749 14.8276 9.00908 14.7271 9.13676 14.6005L16.4721 7.32776C16.5998 7.20117 16.7011 7.05087 16.7703 6.88544C16.8394 6.72001 16.875 6.5427 16.875 6.36363C16.875 6.18456 16.8394 6.00725 16.7703 5.84182Z' fill='${encodeURIComponent(
            tokens.text.secondary
          )}'/%3E%3C/svg%3E")`,
        },
      },
    },
  },
};
