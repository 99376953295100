import { TypographyOptions } from "@mui/material/styles/createTypography";
import { tokens } from "./tokens";
import { bp } from "./breakpoints";

export const typography: TypographyOptions = {
  fontFamily: [tokens.typography.fontFamily.main, "sans-serif"].join(","),
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  caption: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: 13,
    lineHeight: 16 / 13,
    color: tokens.text.secondary,
  },
  captionLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: 17,
    lineHeight: 1.25,
    color: tokens.text.primary,
  },
  button: undefined,
  overline: undefined,
  displayLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.extraBold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.displayLg,
      lineHeight: tokens.responsive.mobile.fontLineHeight.displayLg,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.displayLg,
      lineHeight: tokens.responsive.tablet.fontLineHeight.displayLg,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.displayLg,
      lineHeight: tokens.responsive.desktop.fontLineHeight.displayLg,
    },
  },
  displayMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.extraBold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.displayMd,
      lineHeight: tokens.responsive.mobile.fontLineHeight.displayMd,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.displayMd,
      lineHeight: tokens.responsive.tablet.fontLineHeight.displayMd,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.displayMd,
      lineHeight: tokens.responsive.desktop.fontLineHeight.displayMd,
    },
  },
  displaySmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.extraBold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.displaySm,
      lineHeight: tokens.responsive.mobile.fontLineHeight.displaySm,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.displaySm,
      lineHeight: tokens.responsive.tablet.fontLineHeight.displaySm,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.displaySm,
      lineHeight: tokens.responsive.desktop.fontLineHeight.displaySm,
    },
  },
  headlineLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.headlineLg,
      lineHeight: tokens.responsive.mobile.fontLineHeight.headlineLg,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.headlineLg,
      lineHeight: tokens.responsive.tablet.fontLineHeight.headlineLg,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.headlineLg,
      lineHeight: tokens.responsive.desktop.fontLineHeight.headlineLg,
    },
  },
  headlineMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.headlineMd,
      lineHeight: tokens.responsive.mobile.fontLineHeight.headlineMd,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.headlineMd,
      lineHeight: tokens.responsive.tablet.fontLineHeight.headlineMd,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.headlineMd,
      lineHeight: tokens.responsive.desktop.fontLineHeight.headlineMd,
    },
  },
  headlineSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.headlineSm,
      lineHeight: tokens.responsive.mobile.fontLineHeight.headlineSm,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.headlineSm,
      lineHeight: tokens.responsive.tablet.fontLineHeight.headlineSm,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.headlineSm,
      lineHeight: tokens.responsive.desktop.fontLineHeight.headlineSm,
    },
  },
  titleLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[5],
    lineHeight: tokens.typography.fontLineHeight[5],
  },
  titleMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  titleSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[2],
  },
  bodyLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
  },
  bodyMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  bodySmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
  },
  labelLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
  },
  labelMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  labelSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
    letterSpacing: tokens.typography.fontSize[2] * 0.017,
  },
  labelSmallEmphasis: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
    letterSpacing: tokens.typography.fontSize[2] * 0.017,
  },
  labelExtraSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[1],
    lineHeight: tokens.typography.fontLineHeight[1],
    letterSpacing: tokens.typography.fontSize[1] * 0.017,
  },
  buttonLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[5],
    lineHeight: tokens.typography.fontLineHeight[5],
  },
  buttonMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
  },
  buttonSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  buttonLink: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  inputValue: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
    letterSpacing: tokens.typography.fontSize[4] * -0.01,
  },
  inputHelper: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
    letterSpacing: 0.2,
  },
  inputLabel: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: "9px",
  },
  inputLabelError: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: "9px",
  },
  searchInputValue: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
    letterSpacing: tokens.typography.fontSize[4] * -0.01,
  },
  searchInputSecondaryValue: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
    letterSpacing: tokens.typography.fontSize[4] * -0.01,
  },
  searchInputLabel: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: "9px",
  },
  priceLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[6],
    lineHeight: tokens.typography.fontLineHeight[6],
    letterSpacing: tokens.typography.fontSize[6] * -0.01,
  },
  priceMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  priceStrikethrough: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    textDecoration: "line-through",
    letterSpacing: "-0.56px",
  },
  priceStrikethroughLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[6],
    lineHeight: tokens.typography.fontLineHeight[6],
    textDecoration: "line-through",
    letterSpacing: tokens.typography.fontSize[6] * -0.01,
    color: tokens.text.priceAlert,
  },
};
