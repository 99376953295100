import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiAccordion: Components["MuiAccordion"] = {
  styleOverrides: {
    root: {
      "&:first-of-type": {
        borderTopLeftRadius: tokens.cornerRadius.radiusMd,
        borderTopRightRadius: tokens.cornerRadius.radiusMd,
      },
      "&:not(:first-of-type)": {
        borderTop: "none",
      },
      "&:last-of-type": {
        borderBottomLeftRadius: tokens.cornerRadius.radiusMd,
        borderBottomRightRadius: tokens.cornerRadius.radiusMd,
      },
      "&:before": {
        display: "none",
      },
      display: "flex",
      flexDirection: "column",
      padding: 0,
      border: `1px solid ${tokens.surface.borderDivider}`,
      boxShadow: "none",
      "&.Mui-expanded": {
        margin: 0,
      },
    },
  },
};
