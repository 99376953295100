import {
  Claim,
  ClaimOfferRequest,
  ClaimOfferResponse,
  FlashSale,
  GetPublishedFlashSaleResponse,
  GetSaleFunnelInfoResponse,
  GetUserSaleContextResponse,
  SubscribeToSaleRequest,
  SubscribeUserToSaleResponse,
} from "@b2bportal/hopper-flash-sale-api";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import { fetchPublishedFlashSale } from "../api/fetchPublishedFlashSale";
import {
  fetchUserSaleContext,
  GetUserSaleContextRequest,
} from "../api/fetchUserSaleContext";
import { subscribeToSale } from "../api/subscribeToSale";
import { claimOffer } from "../api/claimOffer";
import {
  fetchUserSaleFunnel,
  GetSaleFunnelInfoRequest,
} from "../api/fetchUserSaleFunnel";
dayjs.extend(isBetween);
dayjs.extend(duration);

export interface IFlashSaleState {
  isForcedPresale?: boolean;
  isForcedSale?: boolean;
  sale?: FlashSale;
  claims: Array<Claim>;
  subscribedToSaleAt?: string;
}

export const initialState: IFlashSaleState = {
  isForcedPresale: undefined,
  isForcedSale: undefined,
  sale: undefined,
  claims: [],
  subscribedToSaleAt: undefined,
};

export const flashSaleApi = createApi({
  reducerPath: "flashSaleApi",
  tagTypes: ["UserContext"],
  baseQuery: undefined,
  endpoints: (builder) => ({
    fetchPublishedFlashSale: builder.query<GetPublishedFlashSaleResponse, void>(
      {
        queryFn: async () => {
          const data = await fetchPublishedFlashSale();
          return { data };
        },
      }
    ),
    fetchUserSaleContext: builder.query<
      GetUserSaleContextResponse,
      GetUserSaleContextRequest
    >({
      queryFn: async (query: GetUserSaleContextRequest) => {
        const data = await fetchUserSaleContext(query);
        return { data };
      },
      providesTags: ["UserContext"],
    }),
    fetchUserSaleFunnel: builder.query<
      GetSaleFunnelInfoResponse,
      GetSaleFunnelInfoRequest
    >({
      queryFn: async (query) => {
        const data = await fetchUserSaleFunnel(query);
        return { data };
      },
      providesTags: ["UserContext"],
    }),
    subscribeToSale: builder.mutation<
      SubscribeUserToSaleResponse,
      SubscribeToSaleRequest
    >({
      queryFn: async (request) => {
        const data = await subscribeToSale(request);
        return { data };
      },
      invalidatesTags: ["UserContext"],
      async onQueryStarted({ saleId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          flashSaleApi.util.updateQueryData(
            "fetchUserSaleContext",
            { saleId: saleId.value },
            (draft) => {
              draft.subscribedToSaleAt = dayjs().toISOString();
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    claimOffer: builder.mutation<ClaimOfferResponse, ClaimOfferRequest>({
      queryFn: async (request) => {
        const data = await claimOffer(request);
        return { data };
      },
      invalidatesTags: ["UserContext"],
      async onQueryStarted({ saleId, offerId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          flashSaleApi.util.updateQueryData(
            "fetchUserSaleContext",
            { saleId: saleId.value },
            (draft) => {
              draft.claims.push({
                offerId,
                voucherExpiresAt: "",
                id: undefined,
                voucherId: undefined,
                saleId: undefined,
                userId: "",
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useFetchPublishedFlashSaleQuery,
  useFetchUserSaleContextQuery,
  useFetchUserSaleFunnelQuery,
  useSubscribeToSaleMutation,
  useClaimOfferMutation,
} = flashSaleApi;
