import { Box, Button, Stack, Typography } from "@mui/material";
import { RunningBunny } from "../../assets";
import { useImageSrc } from "../../lib/Image";
import { Controller, useForm } from "react-hook-form";
import { t } from "i18next";
import { OTPInput } from "../../lib/OTPInput";

export interface VerifyMergeFormProps {
  title: string;
  subtitle: string;
  onContinue: ({ code }: { code: string }) => void;
}

export const VerifyMergeForm = ({
  title,
  subtitle,
  onContinue,
}: VerifyMergeFormProps) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      code: "",
    },
  });
  return (
    <Stack>
      <Typography variant="headlineMedium" px={300}>
        {title}
      </Typography>
      <Stack p={300} gap={250}>
        <Stack direction="row" gap={100} alignItems="center">
          <Typography variant="titleMedium">{subtitle}</Typography>
        </Stack>
        <Stack gap={100}>
          <Box>
            <Controller
              name="code"
              control={control}
              render={({
                field: { ref, onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <OTPInput
                  TextFieldsProps={{
                    inputProps: {
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    },
                  }}
                  length={6}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                />
              )}
            />
          </Box>
        </Stack>
        <Button onClick={handleSubmit(onContinue)}>{t("verify")}</Button>
      </Stack>
    </Stack>
  );
};
