import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiAccordionDetails: Components["MuiAccordionDetails"] = {
  styleOverrides: {
    root: {
      padding: `0 ${tokens.spacing[200]}px ${tokens.spacing[250]}px`,
    },
  },
};
