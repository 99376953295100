import { PaletteOptions } from "@mui/material";
import { tokens } from "./tokens";

export const palette: PaletteOptions = {
  primary: {
    main: tokens.primary.main,
    contrastText: tokens.primary.contrast,
  },
  text: {
    primary: tokens.text.primary,
    secondary: tokens.text.secondary,
    disabled: tokens.text.disabled,
  },
  onPrimary: {
    main: tokens.primary.contrast,
    contrastText: tokens.primary.main,
  },
  onPhoto: {
    main: tokens.primary.main,
    contrastText: tokens.primary.contrast,
  },
};

export const paletteOnPrimary: PaletteOptions = {
  ...palette,
  text: {
    ...palette.text,
    primary: tokens.primary.contrast,
  },
};
