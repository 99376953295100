import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import InfoIcon from "../../assets/icons/common/info-icon.svg";
import { AirlineIconSize } from "../AirlineIcon";
import { FlightAirplaneIcon } from "../FlightAirplaneIcon";
import styles from "./FlightSummaryRow.module.scss";
import { RemoteAirlineIcon } from "../RemoteAirlineIcon";

/**
 * Flight Summary Row
 * @function FlightSummaryRow
 * @param classes
 *
 */
export interface IFlightSummaryRowProps {
  airline: string;
  airlineCode: string;
  airlineIconSize?: AirlineIconSize;
  arrivalCode: string;
  arrivalTime: string;
  className?: string;
  departureCode: string;
  departureTime: string;
  destinationName: string;
  duration: string;
  fareClass: string;
  flightNumber?: string;
  hidePrice?: boolean;
  layoverString?: string;
  numStops?: number;
  originName: string;
  onClick?: () => void;
  price: string;
  tagsText?: string;
  truncateTerminals?: boolean;
  additionalAirlinesCount?: number;
}

export const FlightSummaryRow = ({
  airline,
  airlineCode,
  airlineIconSize = "small",
  arrivalCode,
  arrivalTime,
  className,
  departureCode,
  departureTime,
  destinationName,
  duration,
  fareClass,
  flightNumber,
  hidePrice = false,
  layoverString,
  numStops = 0,
  onClick,
  originName,
  price,
  tagsText,
  truncateTerminals = false,
}: IFlightSummaryRowProps) => {
  const { t } = useI18nContext();

  const truncateGridProps = truncateTerminals
    ? { xs: true, zeroMinWidth: true }
    : {};

  return (
    <Box className={className} onClick={onClick}>
      <Grid
        container
        className="flight-summary-row-container"
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          <Grid container alignItems="center" wrap="nowrap" spacing={4}>
            <Grid item>
              <RemoteAirlineIcon
                airlineCode={airlineCode}
                altText={airline}
                size={airlineIconSize}
              />
            </Grid>
            <Grid item {...truncateGridProps}>
              <Typography variant="h6" gutterBottom noWrap>
                {departureTime}
              </Typography>
              <Tooltip
                title={`${departureCode} (${originName})`}
                open={truncateTerminals ? undefined : false}
              >
                <Typography variant="body1" noWrap>
                  {departureCode} ({originName})
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <FlightAirplaneIcon size={"small"} />
            </Grid>

            <Grid item {...truncateGridProps}>
              <Typography variant="h6" gutterBottom noWrap>
                {arrivalTime}
              </Typography>
              <Tooltip
                title={`${arrivalCode} (${destinationName})`}
                open={truncateTerminals ? undefined : false}
              >
                <Typography variant="body1" noWrap>
                  {arrivalCode} ({destinationName})
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem className={styles.divider} />
        <Grid item>
          <Typography variant="h6" gutterBottom noWrap>
            {airline}
          </Typography>
          <Typography variant="body1" noWrap>
            {fareClass}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem className={styles.divider} />
        <Grid item>
          <Typography variant="h6" gutterBottom noWrap>
            {duration}
          </Typography>
          {numStops > 0 ? (
            <Typography variant="body1" noWrap>
              {t("stopDetails.stop", { count: numStops })}
            </Typography>
          ) : (
            <Typography variant="body1" noWrap>
              {t("stopDetails.nonstop")}
            </Typography>
          )}
        </Grid>
        <Grid item xs />
        {
          // TODO: Add AlgomerchTag component; it should open the AlgomerchInfo popup on click
        }
        {tagsText ? (
          <Grid item>
            <>
              <Typography noWrap>
                <span>{tagsText}</span>
              </Typography>
              <img src={InfoIcon} alt="info icon" />
            </>
          </Grid>
        ) : null}
        {!hidePrice && price ? (
          <Grid item>
            <Typography variant="subtitle1" noWrap>
              {price}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
export default FlightSummaryRow;
