import {
  AuthMethodEnum,
  CreateSessionRequestEnum,
  CreateSessionResponseEnum,
  GetSessionInfoResponseEnum,
  SessionInfo,
} from "@b2bportal/auth-api";
import { createSession, fetchSessionInfo } from "../api";
import { getNativeUserIdFromCookies } from "../api/webView";
import { InitialTokenState, updateRefreshToken } from "./useRefreshToken";

export const createAnonymousSession = async (
  locale: string,
  nativeUserId?: string
) => {
  return createSession({
    authMethod: nativeUserId
      ? {
          AuthMethod: AuthMethodEnum.AnonymousHopperApp,
          locale: locale,
          userId: nativeUserId,
        }
      : {
          AuthMethod: AuthMethodEnum.Anonymous,
          locale: locale,
        },
    CreateSessionRequest: CreateSessionRequestEnum.SignIn,
  });
};
export type LoginCallback = (sessionInfo: SessionInfo) => void;
export type ErrorCallback = () => void;

export const fetchSessionAndSetSession = async (
  callback: LoginCallback,
  errorCallback?: ErrorCallback
) => {
  const res = await fetchSessionInfo();
  if (res.GetSessionInfoResponse === GetSessionInfoResponseEnum.Session) {
    callback(res.sessionInfo);
  } else {
    errorCallback?.();
  }
};

export const fetchOrCreateSession = async (
  onLogin: LoginCallback,
  initialTokenState: InitialTokenState,
  locale: string
) => {
  const createAndFetchSession = async () => {
    const nativeUserId = getNativeUserIdFromCookies();
    const createSessionRes = await createAnonymousSession(locale, nativeUserId);
    if (
      createSessionRes.CreateSessionResponse ===
      CreateSessionResponseEnum.CreateSessionSuccess
    ) {
      return fetchSessionAndSetSession(onLogin);
    } else {
      console.error("Create Session failed");
    }
  };

  try {
    if (initialTokenState === "MISSING") {
      await createAndFetchSession();
    } else {
      const sessionInfoRes = await fetchSessionInfo();
      if (
        sessionInfoRes.GetSessionInfoResponse ===
          GetSessionInfoResponseEnum.NoSession ||
        sessionInfoRes.GetSessionInfoResponse ===
          GetSessionInfoResponseEnum.SessionRejected
      ) {
        // In typical cases, token expiration will result in a NoSession. However,
        // when there's a transient issue with BiFrost, a SessionRejected error will also
        // occur, so we treat these cases the same.
        const result = await updateRefreshToken();
        if (result === "INVALID") {
          // If we're unable to refresh the token create a new anonymous session.
          // This should only occur if the refresh token is invalid.
          await createAndFetchSession();
        } else {
          await fetchSessionAndSetSession(onLogin);
        }
      } else if (
        sessionInfoRes.GetSessionInfoResponse ===
        GetSessionInfoResponseEnum.Session
      ) {
        onLogin(sessionInfoRes.sessionInfo);
      }
    }
  } catch (error) {
    console.error(error);
    // TODO: HANDLE FAILURE
    // TODO: Add error tracking
  }
};
