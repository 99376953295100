import { Box, checkboxClasses, Components } from "@mui/material";
import { tokens } from "../tokens";
import { transitions } from "../transitions";

const CheckRounded = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1243 7.01018C20.0414 6.81167 19.9198 6.63131 19.7665 6.4794C19.6133 6.32742 19.4314 6.20685 19.2311 6.1246C19.0309 6.04234 18.8163 6 18.5996 6C18.3828 6 18.1682 6.04234 17.968 6.1246C17.7678 6.20685 17.5859 6.32742 17.4327 6.4794L9.79721 14.0497L6.56293 10.843C6.25306 10.538 5.8339 10.3672 5.39727 10.368C4.96063 10.3688 4.54211 10.5411 4.23336 10.8472C3.92461 11.1534 3.7508 11.5683 3.75 12.0012C3.74921 12.4341 3.92149 12.8497 4.22911 13.1569L8.6303 17.5206C8.78351 17.6726 8.96542 17.7931 9.16564 17.8754C9.36587 17.9577 9.58047 18 9.79721 18C10.0139 18 10.2285 17.9577 10.4288 17.8754C10.629 17.7931 10.8109 17.6726 10.9641 17.5206L19.7665 8.79331C19.9198 8.6414 20.0414 8.46104 20.1243 8.26253C20.2073 8.06401 20.25 7.85124 20.25 7.63636C20.25 7.42147 20.2073 7.2087 20.1243 7.01018Z"
      fill="currentColor"
    />
  </svg>
);

const CheckIndeterminate = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.60001 12C4.60001 10.9875 5.42082 10.1667 6.43334 10.1667H17.5667C18.5792 10.1667 19.4 10.9875 19.4 12C19.4 13.0125 18.5792 13.8334 17.5667 13.8334H6.43334C5.42082 13.8334 4.60001 13.0125 4.60001 12Z"
      fill="currentColor"
    />
  </svg>
);

export const MuiCheckbox: Components["MuiCheckbox"] = {
  defaultProps: {
    disableRipple: true,
    size: "medium",
    icon: <Box component="span" />,
    checkedIcon: CheckRounded,
    indeterminateIcon: CheckIndeterminate,
  },
  styleOverrides: {
    root: {
      padding: 0,
      backgroundColor: "transparent",
      border: `1px solid ${tokens.component.control.borderResting}`,
      transition: `background-color ${transitions.duration.short}ms ${transitions.easing.easeInOut}, box-shadow ${transitions.duration.short}ms ${transitions.easing.easeInOut}, border-color ${transitions.duration.short}ms ${transitions.easing.easeInOut}`,
      "&:has(input:hover)": {
        backgroundColor: tokens.component.control.surfaceHover,
      },
      "&:has(input:focus-visible)": {
        boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
      },
      "&:has(input:active)": {
        backgroundColor: tokens.component.control.surfacePressed,
      },
      "&:has(input:disabled)": {
        backgroundColor: tokens.component.control.surfaceDisabled,
        border: `1px solid ${tokens.component.control.borderDisabled}`,
      },
      [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
        color: tokens.primary.contrast,
        backgroundColor: tokens.primary.main,
        border: "none",
        "&:has(input:hover)": {
          backgroundColor: tokens.surface.primaryHover,
        },
        "&:has(input:focus-visible)": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
        },
        "&:has(input:active)": {
          backgroundColor: tokens.surface.primaryPressed,
        },
      },
    },
  },
  variants: [
    {
      props: { color: "onPrimary" },
      style: {
        border: `1px solid ${tokens.primary.contrast}`,
        "&:has(input:hover)": {
          backgroundColor: tokens.surface.primaryHover,
        },
        "&:has(input:focus-visible)": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:has(input:active)": {
          backgroundColor: tokens.surface.primaryPressed,
        },
        [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
          border: `1px solid ${tokens.primary.contrast}`,
          "&:has(input:focus-visible)": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
        },
      },
    },
    {
      props: { color: "onPhoto" },
      style: {
        backgroundColor: tokens.surface.stateOverlay.resting,
        border: `1px solid ${tokens.primary.contrast}`,
        "&:has(input:hover)": {
          backgroundColor: tokens.surface.stateOverlay.hover,
        },
        "&:has(input:focus-visible)": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
        },
        "&:has(input:active)": {
          backgroundColor: tokens.surface.stateOverlay.pressed,
        },
        [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
          border: `1px solid ${tokens.primary.contrast}`,
          backgroundColor: tokens.surface.stateOverlay.resting,
          "&:has(input:hover)": {
            backgroundColor: tokens.surface.stateOverlay.hover,
          },
          "&:has(input:focus-visible)": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
          "&:has(input:active)": {
            backgroundColor: tokens.surface.stateOverlay.pressed,
          },
        },
      },
    },
    {
      props: { size: "small" },
      style: {
        width: "16px",
        height: "16px",
        borderRadius: "3.2px",
      },
    },
    {
      props: { size: "medium" },
      style: {
        width: "20px",
        height: "20px",
        borderRadius: tokens.cornerRadius.radiusSm,
      },
    },
    {
      props: { size: "large" },
      style: {
        width: "24px",
        height: "24px",
        borderRadius: "4.8px",
      },
    },
  ],
};

export const MuiCheckboxOnPrimary: Components["MuiCheckbox"] = {
  ...MuiCheckbox,
  defaultProps: {
    ...MuiCheckbox.defaultProps,
    color: "onPrimary",
  },
};

export const MuiCheckboxOnPhoto: Components["MuiCheckbox"] = {
  ...MuiCheckbox,
  defaultProps: {
    ...MuiCheckbox.defaultProps,
    color: "onPhoto",
  },
};
