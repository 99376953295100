import { useI18nContext } from "@hopper-b2b/i18n";
import { Button, Stack, Typography } from "@mui/material";

export interface ConfirmMergeFormProps {
  subtitle: string;
  description?: string;
  primaryButtonLabel: string;
  onContinue: () => void;
}

export const ConfirmMergeForm = ({
  subtitle,
  description,
  primaryButtonLabel,
  onContinue,
}: ConfirmMergeFormProps) => {
  const { t } = useI18nContext();
  return (
    <Stack>
      <Typography variant="headlineMedium" px={300}>
        {t("mergeAccountTitle")}
      </Typography>
      <Stack p={300} gap={250}>
        <Stack direction="row" gap={100} alignItems="center">
          <Typography variant="titleMedium">{subtitle}</Typography>
        </Stack>
        <Button onClick={onContinue}>{primaryButtonLabel}</Button>
        {description ? (
          <Typography variant="bodyMedium">{description}</Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};
