import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiMenu: Components["MuiMenu"] = {
  styleOverrides: {
    paper: {
      borderRadius: tokens.cornerRadius.radiusMd,
      border: `1px solid ${tokens.component.input.borderResting}`,
      boxShadow: "none",
      marginTop: tokens.spacing[50],
    },
    list: {
      padding: 0,
    },
  },
};
