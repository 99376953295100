"use client";

import { useExperiment } from "@hopper-b2b/experiments";
import { BrandNames, Currency, FiatPrice } from "@hopper-b2b/types";
import {
  formatCurrencyWithOptions as hopperFormatCurrency,
  formatCurrencyForShopping as hopperFormatCurrencyForShopping,
} from "@hopper-i18n/formatter";
import { useUserSettingsContext } from "@hopper-b2b/hopper-common";
import { useCallback, useMemo } from "react";
import { useI18nextContext } from "../I18nContext";

import { getLang } from "../utils/getLang";
import { getCurrency } from "@hopper-b2b/utilities";

// const B2B_USER_LOCATION_KEY = "b2bportal-user-location";

export function useI18nCurrency() {
  const ctx = useI18nextContext();
  const { preferredCurrency } = useUserSettingsContext();
  const enableCurrencySelect = useExperiment("WebHotelPreferredCurrency");
  if (ctx === undefined) throw new Error(`must be used within a I18nProvider`);
  // const [location, setLocation] = useState<string | null>(null);
  const { language, options } = ctx;
  const { resources } = options;

  /* useEffect(() => {
    setLocation(localStorage?.getItem(B2B_USER_LOCATION_KEY));
  }, []); */

  const selectedCurrency =
    (resources?.[language]?.["brand"] as BrandNames)?.currency ??
    (resources?.["en"]?.["brand"] as BrandNames)?.currency;

  // TODO HDC-2325: Use the user preferred currency instead of the default currency
  const currencyCode = enableCurrencySelect
    ? getCurrency(preferredCurrency as Currency) ||
      selectedCurrency?.code ||
      Currency.USD
    : Currency.USD;
  const currencySymbol = enableCurrencySelect
    ? selectedCurrency?.symbol || "$"
    : "$";

  const locale = getLang(selectedCurrency?.locale || "en-US", true);

  /**
   * Format a zero amount in the given currency or the user preferred currency if not provided.
   */
  const formatZeroAmount = (currency?: string) => {
    const fiat = {
      value: 0,
      currencyCode: currency ?? currencyCode,
    };
    return formatFiatCurrencyForShopping(fiat);
  };

  /**
   * Used for presenting amounts shown for advertisements. It returns amounts rounded with no decimal places
   */
  const formatFiatCurrencyForShopping = (
    fiatPrice?: Omit<FiatPrice, "currencySymbol">
  ) => {
    if (!fiatPrice) return;

    return hopperFormatCurrencyForShopping(
      fiatPrice.currencyCode,
      locale,
      fiatPrice.value
    );
  };

  const formatFiatCurrency = (
    fiatPrice?: Omit<FiatPrice, "currencySymbol">,
    options?: Intl.NumberFormatOptions
  ) => {
    if (!fiatPrice) return;
    // https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
    // `brandingSymbol` may become undefined in most cases (see the LegacySymbolOverrides table below)
    // and it is fine to pass undefined to `hopperFormatCurrency` function below.
    const brandingSymbol: string | undefined =
      selectedCurrency?.symbolOverride?.[fiatPrice.currencyCode];
    return hopperFormatCurrency({
      currencyCode: fiatPrice.currencyCode,
      locale,
      amount: fiatPrice.value,
      symbol: brandingSymbol,
      options,
    });
  };

  const currencyNames = useMemo(() => {
    return new Intl.DisplayNames([locale], { type: "currency" });
  }, [locale]);

  const currencyNameOf = useCallback(
    (currencyCode: Currency) => {
      return currencyNames.of(currencyCode);
    },
    [currencyNames]
  );

  return {
    currencyCode,
    currencyNameOf,
    currencySymbol,
    formatZeroAmount,
    formatFiatCurrencyForShopping,
    formatFiatCurrency,
  };
}
