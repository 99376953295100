import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";

import styles from "./styles.module.scss";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
} from "@mui/material";
import clsx from "clsx";
import { Transition } from "../Transition";

const TERMS_MAP = {
  es: "https://hopper.com/es/legal/VI-missed-connection-guarantee",
  fr: "https://hopper.com/fr/legal/VI-missed-connection-guarantee",
  en: "https://hopper.com/legal/VI-missed-connection-guarantee",
};

export interface IMissedConnectionGuaranteeModal {
  onClose: () => void;
  open: boolean;
}

export const MissedConnectionGuaranteeModal = ({
  onClose,
  open,
}: IMissedConnectionGuaranteeModal) => {
  const { t, language } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const icons = useTenantIcons();
  return (
    <Dialog
      scroll="paper"
      fullScreen={matchesMobile}
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: clsx({ [styles.mobileContainer]: matchesMobile }) }}
      {...(matchesMobile ? { TransitionComponent: Transition } : null)}
    >
      <div className={styles.missedConnectionGuarantee}>
        <DialogTitle>
          <section className={styles.upperSection}>
            <ButtonWrap onClick={onClose} className={styles.closeBtn}>
              <IconComponent
                ariaLabel={t("closeFiltersModal") as string}
                name={IconName.Close}
              />
            </ButtonWrap>
            <Box className={styles.headerContainer}>
              <div className={styles.imgContainer}>
                <img src={icons.missedConnectionBunny} alt="bunny-connection" />
              </div>
              <Typography className={styles.headerTitle} variant="h1">
                {t("virtualInterline.missedConnectionGuarantee.modal.title")}
              </Typography>
            </Box>
          </section>
        </DialogTitle>
        <DialogContent>
          <Box className={styles.contentSection}>
            <Typography variant="body2">
              {t("virtualInterline.missedConnectionGuarantee.modal.content")}
            </Typography>
            <Typography className={styles.terms} variant="body2">
              <Trans
                i18nKey="virtualInterline.missedConnectionGuarantee.modal.termsInstructions"
                components={[
                  <span />,
                  <Link
                    className={styles.termsAndConditions}
                    href={TERMS_MAP[language] ?? TERMS_MAP.en}
                    target="_blank"
                    rel="noopener"
                  />,
                ]}
              />
            </Typography>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
};
