import React, { createContext, useContext } from "react";
import { AdaChatConfig } from "./ada";

export interface SupportChatbot {
  /**
   * Initializes the chatbot with the provided configuration
   */
  initialize: (config?: AdaChatConfig, onLoad?: () => void) => void;

  /**
   * Toggles the chatbot window open or closed
   */
  toggleSupportChatbot: () => Promise<void>;

  /**
   * Opens the chatbot window if it's closed
   */
  openSupportChatbot: () => Promise<void>;

  /**
   * Minimize the chatbot window if it's open
   */
  minimizeSupportChatbot: () => Promise<void>;

  /**
   * Checks if the chatbot window is currently open
   */
  isChatbotOpen: () => Promise<boolean>;

  /**
   * Shows or hides the chatbot button (FAB)
   */
  toggleSupportChatbotButton: (showButton: boolean) => void;

  /**
   * Whether the chatbot has been initialized
   */
  isInitialized: boolean;
}

const SupportChatbotContext = createContext<SupportChatbot | undefined>(
  undefined
);

export const useSupportChatbot = (): SupportChatbot | undefined => {
  const context = useContext(SupportChatbotContext);
  return context;
};

export type SupportChatbotProviderProps = {
  children: React.ReactNode;
  chatbot: SupportChatbot | undefined;
};

export const SupportChatbotProvider: React.FC<SupportChatbotProviderProps> = ({
  children,
  chatbot,
}) => {
  return (
    <SupportChatbotContext.Provider value={chatbot}>
      {children}
    </SupportChatbotContext.Provider>
  );
};
