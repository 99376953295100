import { AirRestrictionStatus } from "@hopper-b2b/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconComponent } from "../IconComponent";
import { IconContentView } from "../IconContentView";
import { IconName } from "../Icon";
import { Typography } from "@mui/material";

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
}

const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className="icon-available"
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <IconComponent
      className="icon-paid"
      name={IconName.MoneyOutline}
      height={16}
      width={16}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <IconComponent
      className="icon-unavailable"
      name={IconName.XCircleFilled}
      height={16}
      width={16}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.GENERIC]: (
    <IconComponent
      className="icon-generic"
      name={IconName.Generic}
      height={16}
      width={16}
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
};
const Restriction = (props: IRestrictionProps) => {
  const { symbol, description } = props;

  return (
    <IconContentView
      className="fare-details-restriction"
      icon={restrictionIcon[symbol]}
      content={<Typography variant="body2">{description}</Typography>}
    />
  );
};

export default Restriction;
