import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { CloseIcon } from "../../assets";

export interface AuthModalProps extends DialogProps {
  onClose: () => void;
}

export const AuthModal = ({
  open,
  onClose,
  children,
  ...rest
}: AuthModalProps) => {
  const isMobile = useMediaQuery(({ breakpoints }) =>
    breakpoints.down("tablet")
  );
  return (
    <Dialog
      PaperProps={{
        sx: {
          bgcolor: ({ tokens }) => tokens.surface.main,
          ...(!isMobile && {
            width: "100%",
            maxWidth: "375px",
            borderRadius: ({ tokens }) => tokens.cornerRadius.radiusXl,
          }),
        },
      }}
      onClose={onClose}
      fullScreen={isMobile}
      open={open}
      {...rest}
    >
      <Stack>
        <Box
          py={200}
          px={150}
          width="100%"
          display="flex"
          justifyContent="flex-end"
        >
          <IconButton onClick={onClose}>
            <Box component={CloseIcon} width="16px" height="16px" />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </Dialog>
  );
};
