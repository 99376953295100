import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { Transition } from "../Transition";
import styles from "./styles.module.scss";

export interface ISelfTransferModal {
  onClose: () => void;
  open: boolean;
}

export function SelfTransferModal({ open, onClose }: ISelfTransferModal) {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const icons = useTenantIcons();

  return (
    <Dialog
      scroll="paper"
      fullScreen={matchesMobile}
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: clsx({ [styles.mobileContainer]: matchesMobile }) }}
      {...(matchesMobile ? { TransitionComponent: Transition } : null)}
    >
      <div className={styles.selfTransferModal}>
        <DialogTitle>
          <section className={styles.upperSection}>
            <ButtonWrap onClick={onClose} className={styles.closeBtn}>
              <IconComponent
                ariaLabel={t("closeFiltersModal") as string}
                name={IconName.Close}
              />
            </ButtonWrap>
            <Box className={styles.headerContainer}>
              <div className={styles.imgContainer}>
                <img
                  src={icons.selfTransferLayover}
                  alt="self-transfer-layover"
                />
              </div>
              <Typography className={styles.headerTitle} variant="h1">
                {t("virtualInterline.selfCheck.modal.title")}
              </Typography>
            </Box>
          </section>
        </DialogTitle>
        <DialogContent>
          <Box className={styles.contentSection}>
            <Typography variant="body2">
              {t("virtualInterline.selfCheck.modal.transferBagsInfoStep1")}
            </Typography>
            <Typography variant="body2">
              {t("virtualInterline.selfCheck.modal.transferBagsInfoStep2")}
            </Typography>

            <Box className={styles.stepsSection}>
              <Typography variant="body2">
                <strong>
                  {t("virtualInterline.selfCheck.modal.noteCheckedBags")}
                </strong>
              </Typography>
              {[
                t("virtualInterline.selfCheck.modal.pickupBaggage"),
                t("virtualInterline.selfCheck.modal.airportSecurity"),
              ].map((steps, index) => (
                <Box key={index} className={styles.step}>
                  <Typography variant="body2">
                    <strong>
                      {t("virtualInterline.selfCheck.modal.step", {
                        count: index + 1,
                      })}
                    </strong>
                  </Typography>
                  <Typography variant="body2">{steps}</Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <Box className={clsx(styles.flex, styles.internationalFlights)}>
                <Typography variant="body2">
                  <strong>
                    {t("virtualInterline.selfCheck.modal.internationalFlights")}
                  </strong>
                </Typography>
              </Box>
              <Typography variant="body2">
                {t("virtualInterline.selfCheck.modal.internationalFlightsNote")}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
}
