"use client";

import { TrackingPropertiesType } from "@hopper-b2b/types";
import {
  currentBrand,
  getBrandAttribution,
  getBrowserSessionId,
  getDeviceData,
  getFeatureFlagsSessionId,
  getUserDeviceData,
  getUtmParameters,
  getMobileAppVersion,
  getMobileAppBuildNumber,
  getMobileAppOs,
  getMobileAppPlatform,
  isHopperAppWebView,
  internalGetLang,
} from "@hopper-b2b/utilities";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import https from "https";
import http from "http";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import {
  DEVICE_ID_HEADER,
  getDeviceId,
  validateAndSetDeviceId,
} from "./deviceId";
import { setUserLocation } from "./userLocation";

const USER_SOURCE_KEY = "user_source";
const PORTAL_UNAUTHORIZED_PATH = "/auth/invalidsession/";

const apiVersionPrefix = "/api/v0";
const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
const analyticsEventApi = `${analyticsApiPrefix}/event`;

export interface ClientHintHeaders {
  // https://wicg.github.io/responsive-image-client-hints/#sec-ch-width
  // Remove when sec-ch-width is released and supported
  "X-Sec-CH-Viewport-Width"?: number;
}

export interface Headers extends ClientHintHeaders {
  "Content-Type"?: string;
  "Accept-Language"?: string;
  "Hopper-Session"?: string;
  "Tenant-Session"?: string;
  "Hopper-Brand"?: string;
}

export interface AxiosInterceptorsProps {
  children?: ReactNode;
  experimentTrackingProperties?: TrackingPropertiesType;
  userSource?: string;
  isAgentPortal?: boolean;
  isSignedIn?: boolean;
  isGuestUser?: boolean;
  delegatedTo?: string;
  tenant: string;
  currency: string;
  requestConfig?: Pick<AxiosRequestConfig, "baseURL" | "withCredentials">;
  requestHeaders: Headers;
  version: string;
  logRequest?: (res: AxiosRequestConfig) => void;
  logResponse?: (res: AxiosResponse) => void;
  logError?: (error: AxiosError) => void;
  errorAction?: (error: AxiosError) => Promise<AxiosError | AxiosResponse>;
}

export const axiosInstance: AxiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  config.headers["Riskified-Session-Id"] = getBrowserSessionId();
  config.headers["Feature-Flags-Session-Id"] = getFeatureFlagsSessionId();
  return config;
});

const mobileWebAppTrackingProperties: () => TrackingPropertiesType = () => {
  return isHopperAppWebView
    ? {
        $app_version: getMobileAppVersion(),
        $app_version_string: getMobileAppVersion(),
        $app_build_number: getMobileAppBuildNumber(),
        deviceID: getDeviceId(),
        $os: getMobileAppOs(),
        platform: getMobileAppPlatform(),
      }
    : {};
};

export const AxiosInterceptors = ({
  children,
  isAgentPortal,
  delegatedTo,
  isSignedIn,
  isGuestUser,
  tenant,
  currency,
  experimentTrackingProperties,
  userSource,
  requestConfig,
  requestHeaders,
  version,
  logRequest,
  logResponse,
  logError,
  errorAction,
}: AxiosInterceptorsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const deviceProperties = useMemo(
    () => ({
      ...getUserDeviceData(),
      device_type: getDeviceData().type,
    }),
    []
  );

  const pageProperties = useMemo(
    () => ({
      referrer_url: document.referrer,
      referrer_url_host: document.referrer
        ? new URL(document.referrer).hostname
        : "",
      page_referrer: document.referrer,
      page_url: location.pathname,
    }),
    [location.pathname]
  );

  const userSourceProperties = useMemo(
    () =>
      userSource
        ? { [USER_SOURCE_KEY]: userSource, utm_source: userSource }
        : {},
    [userSource]
  );

  // depending on @hopper-b2b/i18n getLang.ts is preferred but this causes a circular dependency
  const locale = internalGetLang();

  const handleReq = useCallback(
    (req: InternalAxiosRequestConfig) => {
      const controller = new AbortController();
      const deviceId = getDeviceId();
      const headers: Headers = {
        "Content-Type": "application/json",
        "Hopper-Brand": currentBrand(),
        ...(deviceId ? { [DEVICE_ID_HEADER]: deviceId } : {}),
        ...requestHeaders,
      };

      if (req.headers["X-Keep-Alive"]) {
        const beaconSuccess = navigator.sendBeacon(
          req.url,
          new Blob([JSON.stringify(req.data)], {
            type: "application/json",
            ...headers,
          })
        );
        if (beaconSuccess) {
          controller.abort();
          throw new axios.Cancel(
            "Beacon success, cancelling original request."
          );
        }
      }

      if (req.url === analyticsEventApi) {
        req.data.properties = {
          ...req.data.properties,
          portal_brand: currentBrand(),
          portal_brand_attribution: getBrandAttribution(),
          is_agent_session: isAgentPortal,
          delegated_to: delegatedTo,
          guest_user: isGuestUser,
          signed_in: isSignedIn,
          currency,
          tenant,
          locale,
          preferred_languages: (navigator?.languages ?? []).join(","),
          app_version: version,
          $app_release: version,
          ...deviceProperties,
          ...pageProperties,
          ...userSourceProperties,
          ...utmProperties(),
          ...experimentTrackingProperties,
          ...mobileWebAppTrackingProperties(),
        };
      }

      req.headers = { ...req.headers, ...headers } as AxiosRequestHeaders;

      if (req.url !== analyticsEventApi && logRequest) {
        logRequest(req);
      }
      return { ...req, ...requestConfig };
    },
    [
      requestConfig,
      requestHeaders,
      isAgentPortal,
      delegatedTo,
      tenant,
      currency,
      experimentTrackingProperties,
      userSource,
    ]
  );

  const handleRes = useCallback((res: AxiosResponse) => {
    if (res.config.url !== analyticsEventApi && logResponse) {
      logResponse(res);
    }
    validateAndSetDeviceId(res.headers);
    setUserLocation(res.headers);
    return res;
  }, []);

  const handleError = useCallback(
    (error: AxiosError) => {
      // Don't handle tracking events errors to prevent ad block issues
      logError?.(error);
      if (
        !error.request?.responseURL.includes("tracking/event") &&
        error.response?.status === 401
      ) {
        if (errorAction) {
          return errorAction(error);
        }
        navigate(PORTAL_UNAUTHORIZED_PATH);
      } else if (error.response?.status === 403) {
        return errorAction?.(error);
      }
      return Promise.reject(error);
    },
    [PORTAL_UNAUTHORIZED_PATH, errorAction]
  );

  useEffect(() => {
    const requestInterceptor = {
      instance: axiosInstance.interceptors.request.use(handleReq, handleError),
      global: axios.interceptors.request.use(handleReq, handleError),
    };

    const responseInterceptor = {
      instance: axiosInstance.interceptors.response.use(handleRes, handleError),
      global: axios.interceptors.response.use(handleRes, handleError),
    };

    //runs when component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor.instance);
      axiosInstance.interceptors.response.eject(responseInterceptor.instance);
      axios.interceptors.request.eject(requestInterceptor.global);
      axios.interceptors.response.eject(responseInterceptor.global);
    };
  }, [handleReq, handleError]);

  return <>{children}</>;
};
export const AxiosInterceptorsNext = ({
  children,
  isAgentPortal,
  delegatedTo,
  isSignedIn,
  isGuestUser,
  tenant,
  currency,
  experimentTrackingProperties,
  userSource,
  requestHeaders,
  version,
  logRequest,
  logResponse,
  logError,
  errorAction,
}: AxiosInterceptorsProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const [userDeviceData, setUserDeviceData] = useState(null);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    setUserDeviceData(getUserDeviceData());
    setReferrer(document.referrer);
  }, []);

  const deviceProperties = useMemo(
    () => ({
      ...userDeviceData,
      device_type: getDeviceData().type,
    }),
    [userDeviceData]
  );

  const pageProperties = useMemo(
    () => ({
      referrer_url: referrer,
      referrer_url_host: referrer ? new URL(referrer).hostname : "",
      page_referrer: referrer,
      page_url: pathname,
    }),
    [pathname, referrer]
  );

  const userSourceProperties = useMemo(
    () =>
      userSource
        ? { [USER_SOURCE_KEY]: userSource, utm_source: userSource }
        : {},
    [userSource]
  );

  const handleReq = useCallback(
    (req: InternalAxiosRequestConfig) => {
      const controller = new AbortController();
      const deviceId = getDeviceId();
      const headers: Headers = {
        "Content-Type": "application/json",
        "Hopper-Brand": currentBrand(),
        ...(deviceId ? { [DEVICE_ID_HEADER]: deviceId } : {}),
        ...requestHeaders,
      };

      if (req.headers["X-Keep-Alive"]) {
        const beaconSuccess = navigator.sendBeacon(
          req.url,
          new Blob([JSON.stringify(req.data)], {
            type: "application/json",
            ...headers,
          })
        );
        if (beaconSuccess) {
          controller.abort();
          throw new axios.Cancel(
            "Beacon success, cancelling original request."
          );
        }
      }

      if (req.url === analyticsEventApi) {
        req.data.properties = {
          ...req.data.properties,
          portal_brand: currentBrand(),
          portal_brand_attribution: getBrandAttribution(),
          is_agent_session: isAgentPortal,
          delegated_to: delegatedTo,
          guest_user: isGuestUser,
          signed_in: isSignedIn,
          currency,
          tenant,
          app_version: version,
          $app_release: version,
          ...deviceProperties,
          ...pageProperties,
          ...userSourceProperties,
          ...utmProperties(),
          ...experimentTrackingProperties,
          ...mobileWebAppTrackingProperties(),
        };
      }

      req.headers = { ...req.headers, ...headers } as AxiosRequestHeaders;

      if (req.url !== analyticsEventApi && logRequest) {
        logRequest(req);
      }
      return req;
    },
    [
      requestHeaders,
      isAgentPortal,
      delegatedTo,
      tenant,
      currency,
      experimentTrackingProperties,
      userSource,
    ]
  );

  const handleRes = useCallback((res: AxiosResponse) => {
    if (res.config.url !== analyticsEventApi && logResponse) {
      logResponse(res);
    }
    validateAndSetDeviceId(res.headers);
    setUserLocation(res.headers);
    return res;
  }, []);

  const handleError = useCallback(
    (error: AxiosError) => {
      // Don't handle tracking events errors to prevent ad block issues
      logError?.(error);
      if (
        !error.request?.responseURL.includes("tracking/event") &&
        error.response?.status === 401
      ) {
        if (errorAction) {
          return errorAction(error);
        }
        router.push(PORTAL_UNAUTHORIZED_PATH);
      }
      return Promise.reject(error);
    },
    [PORTAL_UNAUTHORIZED_PATH, errorAction]
  );

  useEffect(() => {
    const requestInterceptor = {
      instance: axiosInstance.interceptors.request.use(handleReq, handleError),
      global: axios.interceptors.request.use(handleReq, handleError),
    };

    const responseInterceptor = {
      instance: axiosInstance.interceptors.response.use(handleRes, handleError),
      global: axios.interceptors.response.use(handleRes, handleError),
    };

    //runs when component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor.instance);
      axiosInstance.interceptors.response.eject(responseInterceptor.instance);
      axios.interceptors.request.eject(requestInterceptor.global);
      axios.interceptors.response.eject(responseInterceptor.global);
    };
  }, [handleReq, handleError]);

  return <>{children}</>;
};

const utmProperties = () => {
  const utmParameters = getUtmParameters();
  return {
    utm_source: utmParameters.utmSource,
    utm_medium: utmParameters.utmMedium,
    utm_campaign: utmParameters.utmCampaign,
  };
};
