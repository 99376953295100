import { SessionInfo } from "@b2bportal/auth-api";
import { useCallback } from "react";
import { UPDATE_SESSION, useAuthProvider } from "@hopper-b2b/utilities";

export const useLogin = () => {
  const { dispatch } = useAuthProvider();

  return useCallback(
    (sessionInfo: SessionInfo) => {
      dispatch({
        type: UPDATE_SESSION,
        sessionInfo,
      });
    },
    [dispatch]
  );
};
