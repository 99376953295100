"use client";

import { SessionInfo } from "@b2bportal/auth-api";
import { PropsWithChildren } from "react";

interface IProtectedRouteProps extends PropsWithChildren {
  sessionInfo?: SessionInfo;
}

const ProtectedRoute = ({ children, sessionInfo }: IProtectedRouteProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>{sessionInfo && !sessionInfo.userInfo ? "Unauthorized" : children}</>
  );
};

export default ProtectedRoute;
