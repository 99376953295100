import { Components } from "@mui/material";
import { tokens } from "../tokens";

export const MuiPopover: Components["MuiPopover"] = {
  styleOverrides: {
    paper: {
      borderRadius: tokens.cornerRadius.radiusMd,
      boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.25)",
    },
  },
};
