import { Components } from "@mui/material";

export const MuiDialog: Components["MuiDialog"] = {
  styleOverrides: {
    root: {
      paddingTop: "env(safe-area-inset-top)",
      paddingBottom: "env(safe-area-inset-bottom)",
    },
  },
};
